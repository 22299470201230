<template>
	<div class="list">
		<div class="item" @click="unbind">
			<div class="spaceArr">
				<div class="item-content">
					<span class="unbind">解除绑定</span>
				</div>
				<a-icon type="right" class="rightClass"></a-icon>
			</div>
		</div>
	</div>
</template>
<script>
	import {postAction} from '@/api/manage';
	import {setUserInfo, getUserInfo, getCurrentCompany, setCurrentCompany, clearUserInfo} from '@/utils/storage';
	export default {
		data() {
			return {
				userInfo: {},
				stylesheetName: ""
			}
		},
		methods: {
			unbind() {
				this.$confirm({
					title: '提示',
					content: '解绑后将无法收到工资条消息，确认解绑？',
					okText: '确定',
					cancelText: '取消',
					dialogClass: this.stylesheetName,
					onOk: () => {
						this.unbindAction();
					}
				});
			},
			unbindAction() {
				postAction('/employee/unbind', {
					unionid: this.userInfo.unionid,
					mpOpenid: this.userInfo.mpOpenid,
					companyId: [getCurrentCompany().companyId]
				}).then((res) => {
					if (res.success) {
						res.data.setting = res.data.setting || []
						if (res.data.setting.length) {
							let companyInfo = res.data.setting[0];
							this.userInfo.employeeId = companyInfo.employeeId;
							
							this.queryCompanyList(this.userInfo,companyInfo);	
						} else {
							this.$message.success('解绑成功', 5, () => {
								clearUserInfo();
								this.$router.push({path: '/personal'});
							});
						}
					} else {
						this.$message.warning(res.message + ', 将要退出当前页面', 5, () => {
							clearUserInfo();
							//关闭安卓系统手机
							document.addEventListener(
								'WeixinJSBridgeReady',
								function(){
									WeixinJSBridge.call('closeWindow')
								},
								false
							)
							//关闭ios系统手机
							WeixinJSBridge.call('closeWindow')
						});
					}
				})
			},
			queryCompanyList(userInfo,companyInfo){
				//查询用户仍绑定公司信息，作用于用户有多个公司解绑其中一个后，更新绑定公司信息用，防止跳转到个人信息页面后报用户不存在的问题
				postAction('/employee/query/companyInfo', {
						employeeId: this.userInfo.employeeId,
						unionId: this.userInfo.unionid,
						phone: this.userInfo.phone
				}).then((res) => {
						if (res.success) {
							let companyList = res.data.companyInfoList || [];
							// console.log('解绑后返回的信息--------companyList',companyList)
							if (companyList.length) {
								userInfo.infoList = companyList  
								setUserInfo(userInfo);
								setCurrentCompany(companyInfo);
								this.$router.push({path: '/personal'});
							}
						} else {
							this.$message.warning(res.message);
						}
				})
			}
		},
		mounted() {
			this.userInfo = getUserInfo();
			this.stylesheetName = this.userInfo.stylesheetName || 'default';
		}
	}
</script>
<style lang="less">
    .ant-btn-primary {
        background: #1990FF!important;
		}
		.ant-btn-primary:active {
        background: #1990FF!important;
		}
		.ant-btn-primary:hover {
        background: #1990FF!important;

		}
</style>
<style lang="less" scoped>
	.list {
		margin: 10px 0;
		height: calc(100% - 160px);
		overflow-y: auto;

		a {
			color: #354D63;
		}

		.item {
			background: white;
			margin-bottom: 10px;
			font-size: 13.6px;
			vertical-align: middle;
			padding: 8px;

			.iconfont {
				margin-right: 5px;
			}

			.remark {
				font-size: 12px;
				margin: 0;
			}

			.item-content {
				display: inline-block;
				line-height: 32px;
			}

			.spaceArr {
				display: flex;
				justify-content: space-between;
			}
		}

		.item-content {
			width: 100%;
		}

		.value {
			font-size: 12px;
			color: #808F9C;
		}
	}

    .rightClass {
        color: #808F9C;
        font-size: 12px;
        display: inline-block;
        line-height: 38.4px;
    }
</style>