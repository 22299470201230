<template>
    <div>
        <div class="pwdContainer">
            <div class="item" @click="changePwd('modify')">
                <div class="title">修改支付密码</div>
                <a-icon type="right" class="icon"></a-icon>
            </div>

            <div class="item" @click="changePwd('forgot')">
                <div class="title">忘记支付密码</div>
                <a-icon type="right" class="icon"></a-icon>
            </div>

            <router-link to="/changePwd">
                <div class="title">修改工资条查询密码</div>
                <a-icon type="right" class="icon"></a-icon>
            </router-link>
        </div>
        <password ref="password" :title="title" :text="text" :body-style="pwdBodyStyle" path-name="PwdManage"></password>
    </div>
</template>

<script>
    import password from "./components/password";

    export default {
        name: 'PwdManage',
        description: '这是密码管理页面',
        components: {
            password,
        },
        data() {
            return {
                pwdBodyStyle: {
                    padding: '0',
                    textAlign: 'center',
                    background: '#f0f2f5',
                },
                title: '',
                text: ''
            }
        },

        methods: {
            // 修改/忘记密码
            changePwd(type) {
                if(type == 'modify') {
                    this.title = '修改支付密码'
                    this.text = '请输入支付密码，以验证身份'
                } else if(type == 'forgot') {
                    this.title = '重新设置密码'
                }
                this.$refs.password.show(type)
            }
        },
    }
</script>

<style lang="less" scoped>
    .pwdContainer {
        padding: 12px;
        .item, a {
            width: 100%;
            padding: 12px;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFFFFF;
            border-radius: 4px;
            .title {
                color: #000000;
            }
            .icon {
                color: #A5A5A5;
            }
        }
    }
</style>