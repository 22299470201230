<template>
    <div>
        <div class="container">
            <wrapper title="购买金额">
                <div slot="content">
                    <a-form-item label="¥">
                        <a-input
                            placeholder="50.00元起"
                            v-model="money"
                        />
                    </a-form-item>
                </div>
            </wrapper>

            <div class="text">请确保付款银行卡余额充足</div>

            <wrapper title="付款方式">
                <div slot="content">
                    <div class="bankCardList">
                        <div
                            v-for="item in eBankCardList"
                            :key="item.bankCardID"
                            :value="item.bankCardID"
                            class="cardWrapper"
                        >
                            <div class="logo" :style="{backgroundImage: item.logo}"></div>
                            <div class="info">
                                <div class="card">
                                    <div class="name">{{item.bankCardName}}</div>
                                </div>
                                <div class="cardID">最高支付10000.00元</div>
                            </div>
                        </div>
                    </div>
                </div>
            </wrapper>

            <div class="agree">
                <a-radio :value="isChecked" @change="changeChecked">
                    <span style="font-size: 12px; color: #A5A5A5">阅读并同意</span>
                    <span style="font-size: 12px; color: #000000">《相关协议》</span>
                </a-radio>
            </div>
        </div>

        <div class="btnWrapper">
            <a-button :disabled="!isAble" @click="confirmBuy">确定</a-button>
        </div>

        <password ref="password" title="请输入支付密码" :body-style="pwdBodyStyle" path-name="ProductBuy"></password>
    </div>
</template>

<script>
    import sjLogo from "../../assets/product/sjLogo.png";
    import wrapper from "./components/wrapper";
    import password from "./components/password";

    export default {
        name: 'BuyProduct',
        description: '这是购买产品页面',
        components: {
            wrapper,
            password,
        },
        data() {
            return {
                money: undefined,
                eBankCardList: [
                    { bankCardID: '6216610200016587010', bankCardName: '盛京银行储蓄卡', logo: 'url(' + sjLogo + ')' },
                ],
                isChecked: false,
                pwdBodyStyle: {
                    padding: '0',
                    textAlign: 'center',
                    background: '#f0f2f5',
                },
            }
        },

        computed: {
            // 计算 isAble 控制按钮状态
            isAble() {
                return (this.money ? true : false) && (this.isChecked ? true : false)
            },
        },

        methods: {
            // 选中协议
            changeChecked() {
                this.isChecked = true
            },

            // 确认购买
            confirmBuy() {
                this.$refs.password.showPwd = true
            },

            // 输入6位密码完成后的方法调用,成功后跳转至交易记录页面
            sendAction() {
                this.$router.push({
                    path: '/holdingassets/TransHistory'
                })
            },

        },
    }
</script>

<style lang="less" scoped>

    @import "less/product.less";

    .bankCardList {
        .cardWrapper {
            align-items: center;
        }

        .cardWrapper:first-child {
            padding-top: 0;
        }
    }
</style>