<template>
  <div class="container">
    <h3 class="title">累计发薪额</h3>
    <div class="cell-box">
      <cell title="本期收入" :val="this.detailData.employeeIncome" suffix="元" />
      <cell title="本期预扣预缴税额度" :label-span="10" :val="this.detailData.totalActualPay" suffix="元" :border="false">
        <a-row
          class="caculation"
          type="flex"
          align="middle"
          justify="end"
          @click.native="$router.push({
            name: 'TaxCaculation',
            params: { recordId },
            query: { time: time, employeeId: employeeId }
          })"
        >
          <span>查看税款计算</span>
          <a-icon type="right" style="font-size: 12px" />
        </a-row>
      </cell>
      <!-- <cell title="申报日期税款所属期" :label-span="10" val="2021-06-01" /> -->
      <cell title="本期专项扣除" :val="this.detailData.totalSpecialMoney" suffix="元" :border="false" />
    </div>
    <h3 class="title">本期收入与扣除</h3>
    <div class="cell-box">
      <cell title="本期收入" :val="this.detailData.employeeIncome" suffix="元" />
      <cell title="本期免税收入" :val="this.detailData.employeeFreeIncome" suffix="元" />
      <cell title="本期减除费用" :val="this.detailData.taxFreeIncome" suffix="元" />
      <cell title="本期专项扣除" :val="this.detailData.totalSpecialMoney" suffix="元" :border="false">
        <div class="cell-node">
          <cell title="基本养老保险" :val="this.detailData.pensionMoney" suffix="元" />
          <cell title="基本医疗保险" :val="this.detailData.medicalMoney" suffix="元" />
          <cell title="失业保险" :val="this.detailData.unemploymentMoney" suffix="元" />
          <cell title="住房公积金" :val="this.detailData.commonFundMoney" suffix="元" />
        </div>
      </cell>
      <cell title="本期专项附加扣款项" :label-span="10" :val="this.detailData.totalTaxAdd" suffix="元" />
      <cell title="企业(职业)年金" :val="this.detailData.companyYearMoney" suffix="元" />
      <cell title="商业健康保险" :val="this.detailData.healthInsuranceMoney" suffix="元" />
      <cell title="税延养老保险" :val="this.detailData.taxPensionMoney" suffix="元" />
      <cell title="其他" :val="this.detailData.other" suffix="元" />
      <cell title="准予扣除的捐赠额" :val="this.detailData.allowDeductMoney" suffix="元" />
      <cell title="减免税额" :val="this.detailData.taxFree" suffix="元" />
      <!-- <cell title="本期依法确定的" :val="this.detailData.commonFundMoney" suffix="元" />
      <cell title="其他扣除" :val="this.detailData.other" suffix="元" />
      <div class="tip">温馨提醒：专项附加扣除以体现在税款计算内，您可在“税款计算-累计收入与扣除详情-累计专项附加扣除”中查看</div> -->
    </div>
    <!-- <h3 class="title">纳税明细-基本明细</h3>
    <div class="cell-box">
      <cell title="所得项目小类" val="正常工资薪金" />
      <cell title="扣缴义务人名称" val="广东省农村信用社联合社出品" />
      <cell title="扣缴义务人纳税人识别号" val="9111111111111MMIDS2222" />
      <cell title="主管税务机关" val="国家税务总局石家庄市桥戏曲税务局" />
      <cell title="申报渠道" val="其他" :border="false" />
    </div>
    -->
  </div>
</template>

<script>
import Cell from './components/Cell'
import {getUserInfo, setUserInfo, getCurrentCompany} from '@/utils/storage';
import {postAction} from '@/api/manage';
export default {
  components: {
    Cell
  },
  props: {
    recordId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      time: '',
      employeeId: '',
      detailData: {},
    }
  },
  created() {
    document.title= "纳税明细查询";
  },
  mounted() {
    this.time = this.$route.query.time;
    this.employeeId = this.$route.query.employeeId;
    this.getData();
  },
  methods: {
    getData() {
      let params = {
        time: this.time,
        companyId: this.employeeId
      };
      postAction('/personal/detail', params).then((res) => {
        if (res.success) {
            this.detailData = res.data;
        }
        if(res.code===510){
            this.$message.warning(res.message)
        }
      })
    },
  },
}
</script>

<style lang="less" scope>
@import '../../assets/less/theme.less';

.container {
  margin: 0 16px 16px;
  .title {
    margin: 16px 0;
    color: #40576c;
    font-size: 20px;
    font-weight: 600;
  }
  .caculation {
    font-size: 16px;
    color: @theme-color;
    border-bottom: 1px solid #d8dde5;
    padding: 12px 16px 20px 16px;
  }
  .cell-box {
    border-radius: 4px;
    overflow: hidden;
    .cell-node {
      border-top: 1px solid #d8dde5;
      margin-left: 32px;
    }
  }
  .tip {
    background: #fff;
    padding: 16px;
    color: #fd7000;
    font-size: 12px;
    line-height: 20px;
  }
}
</style>