<template>
    <a-layout class="pwdContent">
        <div class="checkPwdPanel">
            <div>{{title}}</div>
            <div class="pwdPanel">
                <div class="pwdList">
                    <span>{{queryPwd[0]}}</span><span>{{queryPwd[1]}}</span><span>{{queryPwd[2]}}</span>
                    <span>{{queryPwd[3]}}</span><span>{{queryPwd[4]}}</span><span>{{queryPwd[5]}}</span>
                </div>
                <a-input :value="pwd" class="pwdInput" type="password" @click="keybdshow"></a-input>
            </div>
        </div>
        <pass-keyboard ref='passKeyboard' :passKey.sync="pwd" @ok='sendAction'></pass-keyboard>
    </a-layout>
</template>
<script>
    import PassKeyboard from '@/components/tools/PassKeyboard'
    import {getUserInfo, setUserInfo, getCurrentCompany} from '@/utils/storage';
    import {postAction} from '@/api/manage';
    import crypto from 'crypto'
    export default {
        components: {PassKeyboard},
        data() {
            return {
                pwd: '',
                method: '',
                userInfo: {},
                keyShow: false,
                title: '请输入工资条查询密码',
                currentCompany: {}
            }
        },
        computed: {
            queryPwd() {
                let string = '';
                if (this.pwd) {
                    this.pwd.split('').forEach((item) => {
                        string += '•';
                    });
                }
                return string;
            }
        },
        methods: {
            keybdshow(e) {
                document.activeElement && document.activeElement.blur(e);
                this.$refs.passKeyboard.keybdshow('')
            },
            mixinPwd(pwd) {
                let md5 = crypto.createHash("md5");
                md5.update(pwd); //需要加密的密码
                return md5.digest('hex');  //password 加密完的密码
            },
            updatePwdAction(value, callback) {
                let info = getUserInfo();
                postAction('/wechatuser/switchPwd', {
                    employeeId: info.employeeId,
                    unionid: info.unionid,
                    isusePassword: value
                }).then((res) => {
                    if (res.success) {
                        info.isusePassword = value;
                        this.$message.success('操作成功');
                        if (value === '01') {
                            info.hasChecked = false;
                        }
                        
                        setUserInfo(info);

                        let queryObj = {}
                        let queryString = decodeURI(window.location.href.split('?')[1].replace(/%3F/g, '&').replace(/%3D/g, '='));
                        queryString.split('&').forEach((str) => {
                            queryObj[str.split('=')[0]] = this.mixinPwd(str.split('=')[1]);
                        });
                        let backPath = queryObj.backPath || '/index';
                        this.$router.push({
                            path: backPath.replace(/%2F/g, '/'),
                            query: queryObj
                        });
                    } else {
                        this.$message.warning(res.message);
                        info.isusePassword = value !== '01';
                        setUserInfo(info);
                    }
                });
            },
            sendAction() {
                let url = '';
                let backPath = '';
                let checked = false;
                let queryObj = {}
                let queryString = decodeURI(window.location.href.split('?')[1].replace(/%3F/g, '&').replace(/%3D/g, '='));
                queryString.split('&').forEach((str) => {
                    queryObj[str.split('=')[0]] = str.split('=')[1];
                });
                switch (this.method) {
                    case 'set':
                        // 设置查询密码
                        url = '/wechatuser/setPwd';
                        backPath = queryObj.backPath || '/index';
                        break;
                    case 'check':
                        // 校验密码
                        url = '/wechatuser/checkPwd';
                        backPath = queryObj.backPath || '/index';
                        checked = true;
                        break;
                }
                let info = getUserInfo() || {};
                let uploadData = {
                    unionid: info.unionid,
                    openId: info.mpOpenid,
                    employeeId: info.employeeId || queryObj.employeeId,
                    password: this.mixinPwd(this.pwd),
                    companyId: this.currentCompany.companyId || queryObj.companyId
                };

                postAction(url, uploadData).then((res) => {
                    if (res.success) {
                        this.userInfo.hasChecked = checked;
                        setUserInfo(this.userInfo);

                        if (this.method === 'set') {
                            // 用户设置了查询密码
                            this.userInfo.hasSetPwd = true;
                        } else {
                            queryObj.checked = 'checked';
                        }

                        this.userInfo.hasChecked = checked;
                        setUserInfo(this.userInfo);

                        if (queryObj.isusePassword) {
                            this.updatePwdAction(queryObj.isusePassword);
                        } else {
                            this.$router.push({
                                path: backPath.replace(/%2F/g, '/'),
                                query: queryObj
                            });
                        }
                    } else {
                        this.pwd = '';
                        this.$message.error(res.message);
                    }
                });
            }
        },
        mounted() {
            this.userInfo = getUserInfo();
            this.currentCompany = getCurrentCompany() || {};
            this.method = this.$route.query.method;
            if (this.method === 'set') {
                this.title = '请设置工资条查询密码';
            }
            if (!this.$route.query.backPath) {
                if (this.userInfo.hasChecked && this.method === 'check') {
                    this.$router.push('/salaryList');
                }
            }
        },
        created() {
            this.$nextTick(()=> {
                this.keybdshow();
            })
        }
    }
</script>
<style lang="less" scoped>
    .pwdContent {
        height: 100%;
        text-align: center;
    }

    .checkPwdPanel {
        margin-top: 80px;
    }

    .pwdPanel {
        position: relative;
    }

    .pwdList {
        display: flex;
        justify-content: center;
        z-index: 1;
    }

    .pwdList span {
        display: inline-block;
        width: 48px;
        height: 48px;
        line-height: 48px;
        background: white;
        margin: 15px 5px 0;
        /*z-index: 2;*/
    }

    .pwdInput {
        width: calc(288px + 50px);
        height: 48px;
        line-height: 48px;
        border: none;
        background: red;
        position: relative;
        top: 48px;
        opacity: 0;
        font-size: 12px;
    }
</style>
