<template>
    <div class="keyboard" v-if="keyShow">
        <a-row class="keyword">
            <a-col :span="8" @click="inputNum('1')">1</a-col>
            <a-col :span="8" @click="inputNum('2')">2</a-col>
            <a-col :span="8" @click="inputNum('3')">3</a-col>
        </a-row>
        <a-row>
            <a-col :span="8" @click="inputNum('4')">4</a-col>
            <a-col :span="8" @click="inputNum('5')">5</a-col>
            <a-col :span="8" @click="inputNum('6')">6</a-col>
        </a-row>
        <a-row>
            <a-col :span="8" @click="inputNum('7')">7</a-col>
            <a-col :span="8" @click="inputNum('8')">8</a-col>
            <a-col :span="8" @click="inputNum('9')">9</a-col>
        </a-row>
        <a-row>
            <a-col :span="8" style="background:none;border:none"></a-col>
            <a-col :span="8" @click="inputNum('0')">0</a-col>
            <a-col :span="8" style="background:none;border:none;color:#1990FF" @click="delNum">x</a-col>
        </a-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pwd: '',
            keyShow: false
        }
    },
    methods: {
        //点击调用键盘
        inputNum(num) {
            if (this.pwd.length < 6) {
                this.pwd = this.pwd + num;
            }
        },
        delNum: function () {
            this.pwd = this.pwd.substring(0, this.pwd.length - 1);
        },
        keybdshow(pass) {
            this.pwd = pass || ''
            this.keyShow = true;
        }
    },
    watch: {
        pwd(value) {
            this.$emit("update:passKey", value)
            if (value.length >= 6) {
                this.keyShow = false;
                this.$emit('ok')
            }
        }
    }
}
</script>
<style lang="less">
    .keyboard {
        transition: all 0.5s;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        z-index: 100;
        .ant-col-8 {
            height: 80px;
            line-height: 80px;
            background: #fff;
            font-size: 20px;
            color: #4C677F;
            border-bottom: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;
        }
        .ant-col-8:focus, .ant-col-8:active {
            background: #1990FF;
            color: #fff;
            transition: all 0.2s;
        }

    }
</style>
