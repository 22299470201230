<template>
    <div>
        {{userInfo}}
    </div>
</template>
<script>
    import {getUserInfo} from "@/utils/storage";

    export default {
        data() {
            return {
                userInfo: getUserInfo()
            }
        }
    }
</script>