<template>
    <div style="position: relative">
        <div class="container">
            <div class="accountIcon">
                <div class="intro">应银行要求，如将资金转入存款，需先开通银行存款账户。通过存款账户与银行直接进行交易。</div>
            </div>
            <div v-if="bindCardList.length > 0" class="bankCardList bindCardList" @click="showBankCardList">
                <div
                    v-for="item in bindCardList"
                    :key="item.bankCardID"
                    :value="item.bankCardID"
                    class="cardWrapper"
                >
                    <div class="logo" :style="{backgroundImage: item.logo}"></div>
                    <div class="info">
                        <div class="card">
                            <div class="name">{{item.bankCardName}}</div>
                            <div class="cardID">{{formatCard(item.bankCardID)}}</div>
                        </div>
                        <div class="dec">单笔限额5万，单日限额50万</div>
                    </div>
                </div>
            </div>

            <div v-else class="bankCardWrapper" @click="showBankCardList">
                <div class="title">银行卡</div>
                <div style="display: flex;align-items: center">
                    <div class="tip">请选择一张银行卡</div>
                    <a-icon type="right" />
                </div>
            </div>

            <div class="tips">绑定一张储蓄卡，请确保卡内余额充足，作为转入本产品的付款方式</div>

            <div class="bankCardWrapper">
                <div class="title">手机号码</div>
                <div class="phone">13728378374</div>
            </div>

            <div class="tips">请确保手机号正在使用</div>

            <div class="agree">
                <a-radio :value="isChecked" @change="changeChecked">
                    <span style="font-size: 12px; color: #A5A5A5">阅读并同意</span>
                    <span style="font-size: 12px; color: #000000">《相关协议》</span>
                </a-radio>
            </div>
        </div>

<!--        <div class="btnWrapper" :class="isChecked && bindCardList.length > 0 ? 'ableBtn' : 'disabledBtn'" @click="toVerifySMS">一键开通</div>-->

        <div class="btnWrapper">
            <a-button :disabled="!isAble" @click="toVerifySMS">一键开通</a-button>
        </div>

        <a-modal
            v-model="showBankCard"
            :footer="null"
            :closable="false"
            :bodyStyle="bodyStyle"
        >
            <div class="bankCardList" :style="bankCardStyle">
                <div
                    v-for="item in bankCardList"
                    :key="item.bankCardID"
                    :value="item.bankCardID"
                    @click="changeBankCard(item)"
                    class="cardWrapper"
                >
                    <div class="logo" :style="{backgroundImage: item.logo}"></div>
                    <div class="info">
                        <div class="card">
                            <div class="name">{{item.bankCardName}}</div>
                            <div class="cardID">{{formatCard(item.bankCardID)}}</div>
                        </div>
                        <div class="dec">单笔限额5万，单日限额50万</div>
                    </div>
                </div>
            </div>
            <div class="addWrapper" @click="toAddBankCard('OpenAccount-AddBankCard')">
                <div class="icon">+</div>
                <div class="title">新增银行卡</div>
            </div>
        </a-modal>
    </div>
</template>

<script>

    import sjLogo from '../../assets/product/sjLogo.png'
    import gsLogo from '../../assets/product/gsLogo.png'

    /*删除数组中的某一个对象
        _arr:数组
        _obj:需删除的对象
    */
    function removeArray(_arr, _obj) {
        let length = _arr.length;
        for (let i = 0; i < length; i++) {
            if (_arr[i] == _obj) {
                if (i == 0) {
                    _arr.shift(); //删除并返回数组的第一个元素
                    return _arr;
                }
                else if (i == length - 1) {
                    _arr.pop();  //删除并返回数组的最后一个元素
                    return _arr;
                }
                else {
                    _arr.splice(i, 1); //删除下标为i的元素
                    return _arr;
                }
            }
        }
    }

    export default {
        name: 'OpenAccount',
        description: '这是开户页面',
        data() {
            return {
                isChecked: false,
                bindCardList: [],
                bankCardList: [
                    { bankCardID: '6216610200016587010', bankCardName: '盛京银行储蓄卡', logo: 'url(' + sjLogo + ')' },
                    { bankCardID: '6623286200016585325', bankCardName: '中国工商银行储蓄卡', logo: 'url(' + gsLogo + ')' },
                ],
                showBankCard: false,
                bankCardStyle: {},
                bodyStyle: {
                    padding: '8px 0'
                }
            }
        },

        created() {
            if(Object.keys(this.$route.params).length > 0) {
                let obj = this.$route.params
                delete obj.name
                this.bankCardList.push(obj)
            }
        },

        computed: {
            isAble() {
                return this.bindCardList.length > 0 && this.isChecked
            }
        },

        methods: {
            // 显示银行卡列表
            showBankCardList() {
                this.bankCardStyle.display = 'block';
                this.showBankCard = true;
            },

            // 选中协议
            changeChecked() {
                this.isChecked = true
            },

            // 对银行卡号进行处理显示前四位和后四位中间用 *，不包含空格
            formatCard(value) {
                if(value&&value.length>8) {
                    let reg = /^(\d{4})(\d*)(\d{4})$/;
                    let str = value.replace(reg, (a, b, c, d)=> {
                        return b + c.replace(/\d/g, "*") + d;
                    });
                    return str
                } else {
                    return value
                }
            },

            // 选中银行卡
            changeBankCard(item) {
                this.bindCardList = []
                this.showBankCard = false
                this.bindCardList.push(item)
                // removeArray(this.bankCardList, item)
            },

            // 一键开通
            toVerifySMS() {
                if(!this.isChecked || this.bindCardList.length === 0) {
                    return
                } else {
                    this.$router.push({
                        name: 'VerifySMS',
                        params: {
                            name: 'OpenAccount-VerifyPwd'
                        }
                    })
                }
            },

            // 跳转至新增银行卡页面
            toAddBankCard(name) {
                this.$router.push({
                    name: 'AddBankCard',
                    params: {
                        name: name
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>

    @import "less/product.less";

    .container {
        .accountIcon {
            position: relative;
            width: 100%;
            height: 112px;
            background-image: url(../../assets/product/account.png);
            background-size: contain;
            background-repeat: no-repeat;
            .intro {
                position: absolute;
                width: 240px;
                top: 16px;
                right: 8px;
                font-size: 12.8px;
                color: #F2DFA3;
            }
        }

        .tips {
            margin-top: 12px;
            margin-left: 8px;
            font-size: 10.4px;
            color: #CAA224;
        }

        .agree {
            margin-top: 32px;
            .ant-radio-wrapper {
                .ant-radio {
                    .ant-radio-inner {
                        width: 12.8px;
                        height: 12.8px;
                    }
                }
            }
        }

        .bindCardList {
            margin-top: 12px;
            background: #FFFFFF;
        }
    }

    .cardWrapper {
        padding: 8px;
    }

    .addWrapper {
        width: 100%;
        padding: 12px 0 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        border-top: 1px solid #e8e8e8;
        .icon {
            width: 24px;
            height: 24px;
            line-height: 19.2px;
            padding-left: 2.4px;
            color: #FFFFFF;
            background: #FFBA00;
            font-size: 32px;
            border-radius: 50%;
        }
        .title {
            margin-left: 4px;
            color: #FFBA00;
        }
    }

</style>

<style>
    @import "less/step.less";
</style>