<template>
    <div>
        <div class="detailContainer">
            <div class="dataWrapper">
                <div class="data-content">
                    <div class="rate">
                        <div class="value">1000</div>
                        <div class="title">交易金额(元)</div>
                    </div>
                </div>

            </div>

            <div class="detailWrapper">
                <div class="trade-item trade">
                    <div class="title">产品名称</div>
                    <div class="rule">固定存款产品1号</div>
                </div>
                <div class="trade-item trade">
                    <div class="title">交易类型</div>
                    <div class="rule">购买</div>
                </div>
                <div class="trade-item trade">
                    <div class="title">交易结果</div>
                    <div class="rule">成功</div>
                </div>
                <div class="trade-item trade">
                    <div class="title">交易时间</div>
                    <div class="rule">2020-06-18 19:30</div>
                </div>
                <div class="trade-item trade">
                    <div class="title">交易流水号</div>
                    <div class="rule">2020061800023</div>
                </div>
                <div class="trade-item">
                    <div class="title">交易账户</div>
                    <div class="rule">8769 **** **** 8724</div>
                </div>
            </div>
            <div class="introduceWrapper">
                <div class="introduce-item">
                    <router-link to="/product/introduce">
                        <div class="title">产品说明书</div>
                        <a-icon type="right" />
                    </router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'HoldDetail',
        description: '持有资产z',
        data() {
            return {
                detail: {},
            }
        },

        created() {
            this.detail = this.$route.params
        },

        methods: {
            transHistory(){
                this.$router.push({
                    path: '/holdingassets/TransHistory',
                });
            }
        },
    }
</script>

<style lang="less" scoped>
    .decIcon {
        width: 24px;
        height: 24px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .detailContainer {
        padding: 12px 8px;
        .dataWrapper {
            padding: 24px 32px 24px 32px;
            background: linear-gradient(0deg, #F16101 0%, #FC991A 100%);
            border-radius: 4px;

            .data-content, .dec-content {
                // display: flex;
                // justify-content: space-between;
            }

            .data-content {
                .rate, .date {
                    color: #FFFFFF;
                    text-align: center;
                }
                .rate .value, .date .value {
                    font-size: 24px;
                    font-weight: 700;
                }
                .rate .title, .date .title {
                    font-size: 12px;
                }
            }

            .dec-content {
                margin-top: 16px;
                .dec-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .title {
                        margin-top: 4px;
                        font-size: 12px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .detailWrapper {
            margin-top: 12px;
            padding: 14.4px 12px;
            background: #FFFFFF;
            border-radius: 4px;
                .trade-item {
                    display: flex;
                    justify-content: space-between;
                    .title {
                        font-size: 13.6px;
                        color: #A5A5A5;
                    }
                    .rule {
                        font-size: 13.6px;
                        color: #000000;
                    }
                }
                .trade {
                    margin-bottom: 12px;
                }
        }

        .introduceWrapper {
            margin-top: 12px;
            margin-bottom: 48px;
            padding: 16px 12px;
            background: #FFFFFF;
            border-radius: 4px;
            .introduce-item {
                a {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    .title {
                        font-size: 14.4px;
                        color: #000000;
                    }
                    i {
                        font-size: 12.8px;
                        color: #A5A5A5;
                    }
                }
            }
            .ant-divider {
                margin: 12px 0;
            }
        }
    }

    .btnContainer {
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        .btn {
            width: 100%;
            padding: 12px 0;
            text-align: center;
            color: #FFFFFF;
        }
        .redeemBtn {
            background: #FF7200;
        }
        .buyBtn {
            background: #FFBA00;
        }
    }
</style>