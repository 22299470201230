<template>
    <div>
        <div class="container">
            <div class="dataWrapper">
                <div class="data-content">
                    <div class="rate">
                        <div class="value">1.02</div>
                        <div class="title">昨日收益(元)</div>
                    </div>
                    <div class="date">
                        <div class="value">1,700.02</div>
                        <div class="title">持有总资产(元)</div>
                    </div>
                    <div class="date">
                        <div class="value">10.02</div>
                        <div class="title">累计收益(元)</div>
                    </div>
                </div>

            </div>

            <wrapper title="当前进度">
                <div slot="content">
                    <a-steps :current="1">
                        <a-step title="转入日期" description="02-01">
                            <i slot="icon" class="aStep" />
                        </a-step>
                        <a-step title="开始计息" description="02-10">
                            <i slot="icon" class="aStep" />
                        </a-step>
                        <a-step title="产品到期" description="02-15">
                            <i slot="icon" class="aStep" />
                        </a-step>
                        <a-step title="本息到账" description="02-20">
                            <i slot="icon" class="aStep" />
                        </a-step>
                    </a-steps>
                </div>
            </wrapper>

            <wrapper title="赎回金额">
                <div slot="content">
                    <a-form-item label="¥">
                        <a-input
                            placeholder="50.00元起"
                            v-model="money"
                        />
                    </a-form-item>
                </div>
            </wrapper>

            <wrapper title="计息规则">
                <div slot="content">
                    <div class="interest-item interest">1.产品未到期，提前支取将按照活期存款利率计息，实际到账利息请以银行发放利息为准</div>
                    <div class="interest-item">2.请确保中国工商银行(1309)状态正常，否则可能导致提取资金停留在盛京银行电子账户</div>
                </div>
            </wrapper>

            <wrapper title="电子账户">
                <div slot="content">
                    <div class="bankCardList">
                        <div
                            v-for="item in eBankCardList"
                            :key="item.bankCardID"
                            :value="item.bankCardID"
                            class="cardWrapper"
                        >
                            <div class="logo" :style="{backgroundImage: item.logo}"></div>
                            <div class="info">
                                <div class="card">
                                    <div class="name">{{item.bankCardName}}</div>
                                </div>
                                <div class="cardID">{{formatCard(item.bankCardID)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </wrapper>

        </div>

        <div class="btnWrapper">
            <a-button :disabled="!isAble" @click="redeem">确定</a-button>
        </div>

        <password ref="password" title="请输入支付密码" :body-style="pwdBodyStyle" path-name="ProductRedeem"></password>
    </div>
</template>

<script>
    import sjLogo from "../../assets/product/sjLogo.png";
    import wrapper from "./components/wrapper";
    import password from "./components/password";

    export default {
        name: 'ProductRedeem',
        description: '这是产品赎回页面',
        components: {
            wrapper,
            password,
        },
        data() {
            return {
                money: undefined,
                eBankCardList: [
                    { bankCardID: '6216610200016587010', bankCardName: '盛京银行储蓄卡', logo: 'url(' + sjLogo + ')' },
                ],
                pwdBodyStyle: {
                    padding: '0',
                    textAlign: 'center',
                    background: '#f0f2f5',
                },
            }
        },

        computed: {
            isAble() {
                return (this.money ? true : false)
            },
        },

        methods: {
            // 对银行卡号进行处理显示前四位和后四位中间用 *，不包含空格
            formatCard(value) {
                if(value&&value.length>8) {
                    let reg = /^(\d{4})(\d*)(\d{4})$/;
                    let str = value.replace(reg, (a, b, c, d)=> {
                        return b + c.replace(/\d/g, "*") + d;
                    });
                    return str
                } else {
                    return value
                }
            },

            // 确定赎回
            redeem() {
                this.$refs.password.show()
            },
        },
    }
</script>

<style lang="less" scoped>

    @import "less/product.less";

    .container {
        margin-bottom: 64px;
        .dataWrapper {
            padding: 24px 32px 24px 32px;
            background: linear-gradient(0deg, #F16101 0%, #FC991A 100%);
            border-radius: 4px;

            .data-content, .dec-content {
                display: flex;
                justify-content: space-between;
            }

            .data-content {
                .rate, .date {
                    color: #FFFFFF;
                    text-align: center;
                }

                .rate .value, .date .value {
                    font-size: 24px;
                    font-weight: 700;
                }

                .rate .title, .date .title {
                    font-size: 12px;
                }
            }

            .dec-content {
                margin-top: 16px;

                .dec-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .title {
                        margin-top: 4px;
                        font-size: 12px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .detailWrapper {
            .contentWrapper {
                .ant-steps {
                    display: flex;
                    .ant-steps-item-icon {
                        float: none;
                    }
                }
                .aStep {
                    width: 24px;
                    height: 24px;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
            .interest-item {
                font-size: 12px;
                color: #A5A5A5;
            }
            .interest {
                margin-bottom: 8px;
            }
        }
    }

</style>
<!-- 重定义进度条默认样式 -->
<style>
    @import "less/step.less";
</style>