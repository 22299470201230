<template>
  <div class="wrap">
    <h3 style="text-align:center;padding-top:20pxs" class="topTitle">
      最新政策
    </h3>
    <div class="list" @click="toDetail('01')">
      <h4 class="title">国家税务总局关于进一步简便优化部分...</h4>
      <p>为进一步支持稳就业、保就业、促消费，助力构建新发展格局，按照《中华人民共和国个人所得税法》及其实施条例有关规定...</p>
      <a-row type="flex" justify="space-between">
        <a-col>国家税务总局</a-col>
        <a-col>2020-12-4</a-col>
      </a-row>
    </div>
    <div class="list" @click="toDetail('02')">
       <h4 class="title">国家税务总局关于完善调整部分纳税人...</h4>
      <p>为进一步支持稳就业、保就业，减轻当年新入职人员个人所得税预扣预缴阶段的税收负担，现就完善调整年度中间首次取得工资...</p>
      <a-row type="flex" justify="space-between">
        <a-col>国家税务总局</a-col>
        <a-col>2020-7-28</a-col>
      </a-row>
    </div>
   
  </div>
</template>

<script>
  import { getUserInfo, setUserInfo, getCurrentCompany } from "@/utils/storage";
  import { postAction } from "@/api/manage";

  export default {
    data() {
      return {
        isRefresh: false,
        loading: false,
        finished: true,
        list: [], // 数据列表
        total: 0, // 数据总数
        employeeId: "",
        taxList: [],
        yearList: [],
        selectYear: new Date().getFullYear(),
        isShow: false,
        listQuery: {
          page: 1,
          num: 10
        } // 请求分页参数
      };
    },
    created() {
      document.title = "缴费记录";
      let info = getUserInfo() || {};
      this.employeeId = info.employeeId;
    },
    mounted() {
      this.initYearList();
      this.getList();
    },
    methods: {
      toDetail(num){
        this.$router.push({name:'TaxInnerDetail',query:{num}})
      },
      changeYear(year) {
        this.selectYear = year;
        this.isShow = false;
        this.getList();
      },
      initYearList() {
        this.yearList = [];
        let current = new Date().getFullYear();
        for (var year = current; year > current - 10; year--) {
          this.yearList.push(year);
        }
      },
      onRefresh() {
        this.isRefresh = true;
        this.getList();
      },
      getList() {
        let info = getUserInfo() || {};
        let employeeId = info.employeeId;
        let params = {
          year: this.selectYear,
          companyId: this.employeeId
        };
        postAction("/personal/query", params).then(res => {
          this.isRefresh = false;
          if (res.success) {
            this.taxList = res.data;
          }
          if (res.code === 510) {
            this.$message.warning(res.message);
          }
        });
      }
    }
  };
</script>
<style lang="less" scoped>
  .wrap{
    padding: 17px;
  }
  .topTitle {
    padding-top: 10px;
    font-weight: bold;
    color: #f57a00;
    font-size: 20px;
  }
  .list {
    margin-top:20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    .title {
      font-weight: bold;
      font-size: 16px;
    }
    p{
      text-indent: 2em;
      font-size: 14px;
    }
  }
  .chooseYear {
    top: 15px;
  }
  .chooseYear {
    //  position: absolute;
    //  top: 0;
    margin-right: 9.6px;
    display: flex;
    align-items: center;
    right: 18px;
    //  font-size: 24px;
    color: #f57a00;
    span {
      margin-right: 5px;
    }

    img {
      width: 12.8px;
      vertical-align: text-top;
    }
  }

  .chooseYearPanel {
    position: absolute;
    //bottom: -320px; */
    top: 64px;
    right: 10px;
    width: 80px;
    text-align: center;
    background: white;
    transition: all 0.5s;
    opacity: 0;
    border: 1px solid #dfdfdf;
    height: 0;
    overflow: hidden;

    &.show {
      opacity: 1;
      height: 160px;
      overflow: auto;
    }
  }

  .yearItem {
    line-height: 38.4px;

    &.current {
      color: white;
    }
  }
  .yearItem.current {
    background: #ffba00;
  }
  .yearPanel {
    overflow-y: auto;
    padding: 10px 0;
    -webkit-overflow-scrolling: touch;
  }
</style>
<style lang="less" scope>
  @import "../../assets/less/theme.less";

  .refresh-text {
    font-size: 9.6px;
    text-align: center;
    line-height: 25.6px;
  }
  .query-btn {
    color: @theme-color;
    padding: 12.8px 9.6px;
  }
  .tax-item {
    position: relative;
    background: #fff;
    border-radius: 4px;
    padding: 9.6px 20px;
    margin: 0 9.6px 9.6px;

    &__col {
      flex-direction: column;
      font-size: 16px;
      color: #354b59;

      &:nth-child(2) {
        position: absolute;
        top: 9.6px;
        left: 50%;
        transform: translateX(-50%);
      }

      &-text {
        font-size: 9.6px;
        color: #9ba5aa;
      }
    }
  }
</style>