<template>
    <div :class="{'previewCont': true, [stylesheetName]: true}">
        <div class="contentPanel">
            <div class='header'>
                <div class="headerBg"></div>
                <div class="water" style="margin-left: -219%">
                    <div v-for="index in 10" class="waterItem" :key='index'>
                        <span v-for="index in spanRepeat" :key='index'>{{waterWord}}</span>
                    </div>
                </div>
                <div class="headerData">
                    <div class='userInfo'>
                        <div>{{salaryInfo.employeeName}}</div>
                        <div>
                            <span>{{salaryInfo.salarySlipDate}}</span>
                            <span class="calendarIcon"></span>
                        </div>
                    </div>
                    <div class='amountPanel' v-show="salaryInfo.amount">
                        <div class='amount rightDivider'>
                            <div class='number'>{{transAmount(salaryInfo.amount)}}</div>
                            <div class="checkPanel">
                                <span>对比上次</span>
                                <span>
                                    <a-icon type='arrow-up' v-if='salaryInfo.actualChange > 0'></a-icon>
                                    <a-icon type='arrow-down' v-if='salaryInfo.actualChange < 0'></a-icon>
                                    {{Math.abs(salaryInfo.actualChange)}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class='tips' v-if="salaryInfo.tipsSwitch === '00'">
                        <span><img src="../../assets/salaryInfo/star.png" alt="星星"></span>
                        <a-tooltip trigger='click' :title="salaryInfo.tips">
                            <span class="tipsContent">{{salaryInfo.tips}}</span>
                        </a-tooltip>
                        <span><img src="../../assets/salaryInfo/star.png" alt="星星"></span>
                    </div>
                </div>
            </div>

            <div class="settingsPanel">
                <div v-for="site in allData.orderItems" :key='site' :class="'part'+site">
                    <div v-if="site == 1"><!--site为1时展示工资条详情 -->
                        <div id="hideout">
                            <div class="water" style="margin-top: -40%; margin-left: -100%;">
                                <div v-for="index in repeatLength" class="waterItem" :key='index'>
                                    <span v-for="index in spanRepeat" :key='index'>{{waterWord}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="dataPanel" >
                            <div v-for='item in salaryInfo.list' :key='item.title'  style="background:#fff;border-radius: 6.8px;">
                                <div class='group' v-if='item.parentTitle'>
                                    <div class='group-name'>
                                        <div>{{item.parentTitle}}</div>
                                    </div>
                                    <div class='group-content'>
                                        <div class='group-item' v-for='child in item.children' :key='child.title'>
                                            <div class='item-name'>
                                                {{child.title}}
                                                <a-tooltip placement="right" :title="child.remark" v-if="child.remark"
                                                            trigger="click">
                                                    <a-icon type="question-circle" style="color:#8FD96B;"></a-icon>
                                                </a-tooltip>
                                            </div>
                                            <div class='item-number'>{{child.value}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class='group-item' v-else v-for='child in item.children' :key='child.title'>
                                    <div class='item-name'>{{child.title}}
                                        <a-tooltip placement="right" :title="child.remark" v-if="child.remark"
                                                    trigger="click">
                                            <a-icon type="question-circle" style="color:#8FD96B;"></a-icon>
                                        </a-tooltip>
                                    </div>
                                    <div class='item-number'>{{child.value}}</div>
                                </div>
                            </div>

                            <div class="signatureWrap" v-if="salaryInfo.signature">
                                <div class="signaturePanel" :style="{backgroundImage: signatureImg}"></div>
                            </div>
                        </div>
                        <div class="ant-empty ant-empty-normal" v-show="errorMsg">
                            <div class="ant-empty-image">
                                <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fillRule="evenodd"><ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fillRule="nonzero" stroke="#D9D9D9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path></g></g></svg>
                            </div>
                            <p class="ant-empty-description">{{errorMsg}}</p>
                        </div>
                    </div>
                    <div v-if="site == 2"><!--site为2时展示广告位一 -->
                        <a :href="allData.salaryPictureDto.linkOne ? allData.salaryPictureDto.linkOne : '#'">
                            <!-- 当等于2时展示默认图片 0时展示上传图片 -->
                            <img v-if="allData.salaryPictureDto.stateOne === 2" src="../../assets/salaryInfo/banner1.png" alt="">
                            <img v-if="allData.salaryPictureDto.stateOne === 0" :src="'http://10.11.0.95:6063/ngimg/'+allData.salaryPictureDto.splitUrlOne" :alt="allData.salaryPictureDto.nameOne">
                        </a>
                    </div>
                    <div v-if="site === 3"><!--site为3时展示广告位二 -->
                        <a :href="allData.salaryPictureDto.linkTwo ? allData.salaryPictureDto.linkTwo : '#'">
                            <!-- 当等于2时展示默认图片 0时展示上传图片 -->
                            <img v-if="allData.salaryPictureDto.stateTwo === 2" src="../../assets/salaryInfo/banner2.png" alt="">
                            <img v-if="allData.salaryPictureDto.stateTwo === 0" :src="'http://10.11.0.95:6063/ngimg/'+allData.salaryPictureDto.splitUrlTwo" :alt="allData.salaryPictureDto.nameTwo">
                        </a>
                    </div>
                    <div v-if="site === 4"><!--site为1时展示客户经理公告 -->
                    <!-- 当等于2时展示默认图片 0时展示上传图片 -->
                        <div v-if="allData.managerDto.state === 2" class="managerDto">
                            <img src="../../assets/salaryInfo/banner3.png" alt="">
                            <p>{{allData.managerDto.notice}}</p>
                        </div>
                        
                        <div v-if="allData.managerDto.state === 0" class="managerDto">
                            <img  style="width:100%" :src="'http://10.11.0.95:6063/ngimg/'+allData.managerDto.splitUrl" :alt="allData.managerDto.notice">
                            <p>{{allData.managerDto.notice}}</p>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            <div class='footerPanel' v-if="!errorMsg">
                <!-- salaryInfo.isFeedbackd 01 已反馈过 -->
                <!-- feedback：true 可以反馈 -->
                <!-- salaryInfo.confirmStatus 01 已确认-->
                <!-- 满足反馈的条件是：没有反馈过 或者 可以反馈时，工资条没有确认过  -->
                <div class="disabled" v-if="!(feedback && buttonStatus.isFeedbackd === '00') || buttonStatus.confirmStatus==='01'">
                    <a-icon type="question-circle"></a-icon>
                    我有疑问
                </div>
                <div @click='gotoQuestion' v-else>
                    <a-icon type="question-circle"></a-icon>
                    我有疑问
                </div>

                <div v-if="buttonStatus.confirmStatus==='01'" class="divider disabled">
                    <a-icon type="check-circle"></a-icon>
                    确认无误
                </div>

                <!-- confirm：true 手写签名确认 -->
                <div class='divider' @click='signAction' :loading='loading' :diabled='loading' v-else>
                    <a-icon type="check-circle"></a-icon>
                    <span v-if='confirm'>{{queryObj.hasSigned === '1' ? '确认无误' : '电子签名'}}</span>
                    <span v-else>确认无误</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {postAction} from '@/api/manage';
    import {getLocalStore, setLocalStore, setUserInfo, getUserInfo, clearStore, getCurrentCompany} from '@/utils/storage';
    import {trans_money1} from '@/utils/util'

    export default {
        data() {
            return {
                stylesheetName: '',
                salaryInfo: {},
                detailNo: '',
                form: null,
                userInfo: {},
                signatureImg: '',
                confirm: true,
                feedback: true,
                waterWord: '',
                repeatLength: 5,
                spanRepeat: 10,
                queryObj: {},
                lastMonth: '6800',
                errorMsg: '',
                buttonStatus: {
                    isFeedbackd: '00',
                    confirmStatus: '00'
                },
                employeeId: '',
                loading: false,
                currentCompany: {},
                adData:{
                    month:'10',
                    amt:'100',
                    mmArr:[],
                    bankCard:'中国建设银行储蓄卡(尾号3219)'
                },
                companySetting: {},
                hasSetPwd: false,
                hasChecked: false,
                isusePassword: false,
                channelLogin: false,
                allData:{}
            }
        },
        
        methods: {
            querySalaryInfo() {
                if (!this.detailNo) {
                    return false;
                }
                let hostname = getLocalStore('hostname');
                let info = getLocalStore(hostname + 'salaryInfo' + this.detailNo);
                if (info) {
                    this.salaryInfo = info;
                    this.signatureImg = 'url(' + this.salaryInfo.signature + ')';
                    this.stylesheetName = info.skinType;
                    this.salaryInfo.list = this.salaryInfo.list || []
                    this.computedWater();
                    return false;
                }

                postAction(this.channelLogin ? 'salarydetail/querySalary' : '/salarydetail/query', {
                    detailNo: this.detailNo,
                    employeeId: this.userInfo.employeeId || this.queryObj.employeeId,
                    companyId: this.currentCompany.companyId || this.queryObj.companyId,
                }).then((res) => {
                    if (res.success) {
                        this.allData = res.data
                        this.salaryInfo = res.data.salarySlipDetailsResp;
                        this.stylesheetName = this.salaryInfo.skinType;
                        this.salaryInfo.list = this.salaryInfo.list || []
                        this.computedWater();
                        this.updateCurSalaryStore();
                    } else {
                        this.errorMsg = res.message;
                        clearStore(hostname + 'salaryInfo' + this.detailNo);
                        if (res.code === '104009') {
                            if (this.hasSetPwd) {
                                // 查询密码超时
                                this.hasChecked = false;
                                this.userInfo.hasChecked = false;
                                setUserInfo(this.userInfo);

                                let query = Object.assign({method: 'check', backPath: '/salaryList'}, this.queryObj, this.queryObj || {});
                                this.$router.push({
                                    path: '/checkPwd',
                                    query: query
                                });
                            }
                        }else{
                            this.$message.warning(res.message)
                        }
                    }
                });
            },
            computedWater() {
                let settings = this.companySetting
                if (this.companySetting.showWatermark === '01') {
                    this.waterWord = this.companySetting.watermarkContent === '00' ? this.companySetting.companyName : this.salaryInfo.employeeName;
                    if (this.waterWord) {
                        this.spanRepeat = parseInt(window.screen.width / (this.waterWord.length * 12));
                        this.spanRepeat = this.spanRepeat < 6 ? 6 : this.spanRepeat;
                    }
                }
                if (this.salaryInfo.list) {
                    this.repeatLength = this.salaryInfo.list.length
                }
                console.log(document.querySelector('#hideout'));
                document.querySelector('#hideout').style.height = (this.repeatLength * 3.5) + 'rem';
            },
            confirmAction() {
                let postData = {
                    companyId: this.currentCompany.companyId || this.queryObj.companyId,
                    slipDetailNo: this.detailNo,
                    image: this.salaryInfo.signature || null
                };

                if (this.loading) {
                    return false;
                }

                this.loading = true;
                postAction('/staffconfirm/update', postData, {
                    contentType: 'application/x-www-form-urlencoded'
                }).then((res) => {
                    this.loading = false;
                    if (res.success) {
                        this.$message.success('工资条确认成功');
                        this.salaryInfo.confirmStatus = '01';
                        this.buttonStatus.confirmStatus = '01';
                        this.updateCurSalaryStore();
                    } else {
                        this.$message.warning(res.message);
                    }
                });
            },
            updateCurSalaryStore() {
                let hostname = getLocalStore('hostname');
                if (this.detailNo) {
                    setLocalStore(hostname + 'salaryInfo' + this.detailNo, this.salaryInfo);
                }
            },
            gotoQuestion() {
                if (this.feedback && this.buttonStatus.isFeedbackd === '00') {
                    this.$router.push({
                        path: '/hasQuestion',
                        query: {
                            slipDetailNo: this.detailNo,
                            employeeName: this.salaryInfo.employeeName,
                            slipBatchNo: this.salaryInfo.slipBatchNo,
                            slipBatchTitle: this.salaryInfo.slipBatchTitle
                        }
                    });
                }
            },
            goToSign() {
                if (this.salaryInfo.signature) {
                    this.confirmAction();
                } else {
                    let query = Object.assign({title: this.queryObj.title || this.queryObj.title}, this.queryObj, this.queryObj || {});
                    this.$router.push({
                        path: '/signature',
                        query: query
                    });
                }
            },
            checkPwdSetting() {
                switch (this.isusePassword) {
                    case '01':
                        if (this.hasChecked) {
                            this.querySalaryInfo();
                        } else {
                            this.method = this.queryObj.method || this.queryObj.method;

                            let query = Object.assign({method: 'check', backPath: '/salary'}, this.queryObj, this.queryObj);
                            this.$router.push({
                                path: '/checkPwd',
                                query: query
                            });
                        }
                        break;
                    case '00':
                        this.querySalaryInfo();
                        break;
                }
            },
            queryPwdSetting() {
                return new Promise((resolve) => {
                    postAction('/wechatuser/queryPwdSetting', {
                        employeeId: this.userInfo.employeeId || this.queryObj.employeeId,
                        companyId: this.currentCompany.companyId || this.queryObj.companyId,
                        unionid: this.userInfo.unionid
                    }).then((res) => {
                        if (res.success) {
                            this.hasSetPwd = res.data.code === "notNull";
                            this.userInfo.hasSetPwd = res.data.code === "notNull";
                            setUserInfo(this.userInfo);
                            
                            console.log(this.hasSetPwd)

                            if (this.hasSetPwd) {
                                console.log('已经设置了密码')
                                if (this.hasChecked) {
                                    // 验证过查询密码，直接显示信息
                                    this.querySalaryInfo();
                                } else {
                                    this.method = this.queryObj.method;

                                    if (this.hasChecked && this.method === 'check') {
                                        this.$router.push('/salaryList');
                                        return;
                                    }

                                    let query = Object.assign(this.queryObj, {method: 'check', backPath: '/salary'});
                                    this.$router.push({
                                        path: '/checkPwd',
                                        query: query
                                    });
                                }
                            } else {
                                // 引导去设置密码
                                this.$router.push({
                                    path: '/checkPwd',
                                    query: Object.assign({
                                        method: 'set',
                                        backPath: '/salary'
                                    }, this.queryObj)
                                });
                            }
                        } else {
                            this.$message.warning(res.message);
                        }
                    });
                })
            },
            signAction() {
                if (this.confirm) {
                    this.goToSign();
                } else {
                    this.confirmAction();
                }
            },
            transAmount(num) {
                return trans_money1(num);
            },
            queryBtnStatus() {
                postAction('/salarydetail/queryButtonStatus', {
                    detailNo: this.detailNo,
                    employeeId: this.userInfo.employeeId || this.queryObj.employeeId,
                    companyId: this.currentCompany.companyId || this.queryObj.companyId,
                }).then((res) => {
                    if (res.success) {
                        this.buttonStatus.isFeedbackd = res.data.isFeedbackd;
                        this.buttonStatus.confirmStatus = res.data.confirmStatus;
                        this.salaryInfo.isFeedbackd = res.data.isFeedbackd;
                        this.salaryInfo.confirmStatus = res.data.confirmStatus;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            queryCompanySetting() {
                postAction('/wechatuser/querySlipSetting', {companyId: this.currentCompany.companyId || this.queryObj.companyId}).then((res) => {
                    if (res.success) {
                        this.companySetting = res.data.setting;
                        this.confirm = this.companySetting.confirmSwitch === '01';
                        this.feedback = this.companySetting.feedbackSwitch === '01';
                        this.isusePassword = this.companySetting.passwordSwitch;
                        this.userInfo.isusePassword = this.companySetting.passwordSwitch;
                        this.computedWater()
                    }
                });
            }
        },
        mounted() {
            this.channelLogin = getLocalStore(getLocalStore('hostname') + '_channelLogin') == '01'
            let queryString = decodeURI(window.location.href.split('?')[1].replace(/%3F/g, '&').replace(/%3D/g, '='));
            queryString.split('&').forEach((str) => {
                this.queryObj[str.split('=')[0]] = str.split('=')[1];
            });

            this.userInfo = getUserInfo() || {};
            this.hasChecked = this.userInfo.hasChecked || (this.$route.query.checked === 'checked');

            this.detailNo = this.queryObj.detailNo || this.queryObj.slipDetailNo;

            this.currentCompany = getCurrentCompany() || {};
            this.employeeId = this.userInfo.employeeId;

            this.queryCompanySetting()
            this.queryBtnStatus();
            if (!this.channelLogin) {
                this.queryPwdSetting();
            } else {
                this.querySalaryInfo();
            }
        },
        destroyed() {
            if (document.querySelector('.waterWrap')) {
                document.querySelector('.waterWrap').remove();
            }
        }
        
    }

</script>
<style lang="less">
    @import "salary";
    .water {
        position: absolute;
        top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 500%;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-transform: rotate(-35deg);
        transform: rotate(-35deg);
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        line-height: 67.2px;
        opacity: 0.8;
        font-size: 12px;
        overflow: hidden;
    }

    .waterItem {
        display: flex;
        justify-content: space-between;
        color: #dfdfdf;
        span {
            white-space: nowrap;
            display: inline-block;
            overflow: hidden;
            padding: 0 32px
        }
    }
    .ad .ant-modal-body{
        padding:0
    }
</style>
<style lang="less" scoped>
    .headerData {
        height: 184px;
    }
    .ugly-size{
        width:100%;
        height:350px;
    }
    .close-size{
        width: 37px;
        height: 37px;
        position: absolute;
        bottom: -50px;
        right: 45%;
    }
    .everyMonth{
        font-size: 12px;
        color: #000;
        height: 36px;
        line-height: 36px;
    }
    .borderB{border-bottom: 1px solid #9CADBD;}
    .choosePost{
        font-size:18px;
        color:#445FF8;
    }
    .cardNo{
        text-align: right;
        color:#000;
    }
    .drege{
        width: 60%;
        height: 35px;
        line-height: 35px;
        margin: 20px 20% 30px;
        border:none;
        background:linear-gradient(90deg,rgba(24,225,255,1),rgba(24,144,255,1));
    }
    .inform{
        font-size:12px;
        margin:8px;
        color:#282728;
    }
    .chooseCont{
        position: absolute;
        border-radius: 0px 13px;
        background: #fff;
        opacity: 0.5;
        width: 86%;
        height: 75px;
        margin: -100px 7%;
        padding: 0 5%;
        .month{
            padding-left:10px;
            text-align:left;
        }
        .amt{
            text-align:right;
            padding-right:10px;
        }
        img{
            width:14px;
            height:14px;
        } 
    }
    .part1{
        position: relative;
        margin: 16px 8px;
    }
    .part2{
        width: 100%;
    }
    .part2 img{
        width: 100%;
    }
    .part3{
        width: 100%;
        position: relative;
    }
    .part3 img{
        width: 100%;
    }
    .part4{
        width: 100%;
        .managerDto{
            position: relative;
            
            p{
                width:56%;
                position:absolute;
                top:41%;
                left: 8%;
                font-size: 86%;
            }
        }
    }
    .part4 img{
        width: 100%;
    }
</style>