<template>
  <div>
    <el-header>
      <div :class="{ loginPanel: true, [stylesheetName]: true }">
        <div style="margin: 0 0.3rem">
          <el-row :gutter="24">
            <el-col :span="6" style="text-align: center">
              <div style="padding-top: 0.1rem">
                <img src="../../assets/website/logo.png" alt="" style=" ;" />
              </div>
            </el-col>
            <el-col :span="16">
              <el-menu
                :default-active="activeIndex2"
                mode="horizontal"
                @select="handleSelect"
                text-color="#212529"
                active-text-color="#ffd04b"
              >
                <el-menu-item
                  index="1"
                  style="height: 60px; line-height: 60px; font-size: 15px"
                  >首页</el-menu-item
                >
                <el-menu-item
                  index="2"
                  style="height: 60px; line-height: 60px; font-size: 15px"
                  >公司简介</el-menu-item
                >
                <el-menu-item
                  index="3"
                  style="height: 60px; line-height: 60px; font-size: 15px"
                  >新闻中心</el-menu-item
                >
                <el-menu-item
                  index="4"
                  style="height: 60px; line-height: 60px; font-size: 15px"
                  >产品中心</el-menu-item
                >
                <el-menu-item
                  index="5"
                  style="height: 60px; line-height: 60px; font-size: 15px"
                  >服务案例</el-menu-item
                >
                <el-menu-item
                  index="6"
                  style="height: 60px; line-height: 60px; font-size: 15px"
                  >招贤纳士</el-menu-item
                >
                <el-menu-item
                  index="7"
                  style="height: 60px; line-height: 60px; font-size: 15px"
                  >公司荣誉</el-menu-item
                >
                <el-menu-item
                  index="8"
                  style="height: 60px; line-height: 60px; font-size: 15px"
                  >联系我们</el-menu-item
                >
              </el-menu>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-header>
  </div>
</template>
<script>
import {
  setLocalStore,
  getLocalStore,
  setUserInfo,
  getUserInfo,
  setCurrentCompany,
  setAccessToken,
} from "@/utils/storage";
import { postAction } from "@/api/manage";

export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
    };
  },
  activated() {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.el-menu {
  background-color: transparent;
}
.menu-item {
  height: 60px;
  line-height: 60px;
  font-size: 14px;
}
</style>