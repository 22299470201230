<template>
    <div>
        <div class="container">

            <a-form :form="form" class="verifyForm">

                <a-form-item label="发卡行">
                    <div style="display: flex;align-items: center;justify-content: flex-end" @click="showBankCardList">
                        <div v-if="Object.keys(selectedBank).length > 0" class="name">{{selectedBank.bankCardName}}</div>
                        <div v-else class="tip">请选择发卡行</div>
                        <a-icon type="right" />
                    </div>
                </a-form-item>

                <a-form-item label="银行卡号">
                    <a-input
                        placeholder="仅支持部分银行储蓄卡"
                        v-model="bankCard"
                        @blur="checkBankCard($event)"
                    />
                </a-form-item>

                <a-form-item label="持卡人">
                    <a-input
                        placeholder="请输入持卡人姓名"
                        v-model="name"
                    />
                </a-form-item>
            </a-form>
        </div>

        <div class="btnWrapper">
            <a-button :disabled="!isAble" @click="addBankCard">下一步</a-button>
        </div>

        <a-modal
            v-model="showBankCard"
            :footer="null"
            :closable="false"
            :bodyStyle="bodyStyle"
            wrapClassName="wrapClassName"
            style="width: 100%; max-width: 100%; margin: 0;position: fixed;bottom: 0;top: initial;padding-bottom: 0"
        >
            <div class="bankCardList" :style="bankCardStyle">
                <div
                    v-for="item in bankCardList"
                    :key="item.bankCode"
                    :value="item.bankCode"
                    @click="changeBankCard(item)"
                    class="cardWrapper"
                >
                    <div class="logo" :style="{backgroundImage: item.logo}"></div>
                    <div class="info">
                        <div class="card">
                            <div class="name">{{item.bankCardName}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>

    import { bankCardAttribution }from "../../utils/bankCard/bankCardAttribution";
    import sjLogo from "../../assets/product/sjLogo.png";
    import gsLogo from "../../assets/product/gsLogo.png";
    import nyLogo from "../../assets/product/ny.png";
    import zgLogo from "../../assets/product/zg.png";
    import jsLogo from "../../assets/product/js.png";

    export default {
        name: 'AddBankCard',
        description: '这是添加银行卡页面',
        data() {
            return {
                form: this.$form.createForm(this),
                showBankCard: false,
                bankCardStyle: {},
                bodyStyle: {
                    padding: '0'
                },
                bankCardList: [
                    { bankCode: "ICBC", bankCardName: '中国工商银行', logo: 'url(' + gsLogo + ')' },
                    { bankCode: "ABC", bankCardName: '中国农业银行', logo: 'url(' + nyLogo + ')' },
                    { bankCode: "BOC", bankCardName: '中国银行', logo: 'url(' + zgLogo + ')' },
                    { bankCode: "CCB", bankCardName: '中国建设银行', logo: 'url(' + jsLogo + ')' },
                    { bankCode: "SJBANK", bankCardName: '盛京银行', logo: 'url(' + sjLogo + ')' },
                ],
                selectedBank: {},
                name: undefined,
                bankCard: undefined,
            }
        },

        created() {
            // console.log(bankCardAttribution('6228482328030076676'));
        },

        // 计算 isAble 的值来控制按钮的状态
        computed: {
            isAble() {
                return Object.keys(this.selectedBank).length > 0 && (this.name ? true : false) && (this.validateBankCard() ? true : false);
            }
        },

        methods: {
            // 显示银行卡列表
            showBankCardList() {
                this.bankCardStyle.display = 'block';
                this.showBankCard = true;
            },

            // 选择开户行
            changeBankCard(item) {
                this.selectedBank = item;
                this.showBankCard = false;
            },

            // 校验银行卡号
            validateBankCard() {
                if(/^([1-9]{1})(\d{14}|\d{18})$/.test(this.bankCard)) {
                    return true
                } else {
                    return false
                }
            },

            // 判断银行卡号是否有对应的开户行
            checkBankCard(event) {
                let str = event.target.value;
                if(this.validateBankCard()) {
                    let val = bankCardAttribution(str)
                    if(val == 'error') {
                        this.$message.warning('该银行卡号无对应的开户行，请检查')
                    } else if(val.bankName != this.selectedBank.bankCardName) {
                        this.$message.warning('该银行卡号与所选开户行不对应，请检查')
                    }
                }
            },

            // 添加新卡
            addBankCard() {
                let obj = {}
                obj.bankCardID = this.bankCard
                obj.bankCardName = this.selectedBank.bankCardName
                obj.logo = this.selectedBank.logo
                obj.name = this.$route.params.name
                this.$router.push({
                    name: 'VerifySMS',
                    params: obj
                })
            },
        },
    }
</script>

<style lang="less" scoped>

    @import "less/product.less";

    .container {
        .verifyForm {
            .ant-form-item {
                margin-top: 12px;
                margin-bottom: 0;
                padding: 4px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #FFFFFF;
                border-radius: 4px;
                .tip, .name {
                    margin-right: 8px;
                    font-size: 14.4px;
                }
                .tip {
                    color: #A5A5A5;
                }
                .name {
                    color: #000000;
                }
                i {
                    font-size: 12.8px;
                    color: #A5A5A5;
                }
                /deep/ .ant-form-item-label {
                    width: 50%;
                    padding-bottom: 0;
                }
                .ant-input {
                    padding: 0;
                    text-align: right;
                    border: none;
                }
                .ant-input:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .bankCardList {
        .cardWrapper {
            align-items: center;
            padding: 4px;
        }
    }
</style>