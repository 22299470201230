<template>
    <a-layout-content style="padding: 0 50px">
        <div :style="{ background: '#fff', minHeight: '500px',margin: '30px 0 0' }" >
            <router-view></router-view>     
        </div>
    </a-layout-content>
</template>

<script>
export default {
    name:'PageContent'
}
</script>

<style>

</style>