<template>
    <div class="container">
        <wrapper title="银行卡">
            <div slot="content">
                <div class="icon" @click="toAddBankCard('BankCardManage')">+</div>
                <div class="bankCardList">
                    <div
                        v-for="item in bankCardList"
                        :key="item.bankCardID"
                        :value="item.bankCardID"
                        class="cardWrapper"
                    >
                        <div class="logo" :style="{backgroundImage: item.logo}"></div>
                        <div class="info">
                            <div class="card">
                                <div class="name">{{item.bankCardName}}</div>
                            </div>
                            <div class="cardID">{{formatCard(item.bankCardID)}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </wrapper>

        <wrapper title="电子账户">
            <div slot="content">
                <div class="bankCardList">
                    <div
                        v-for="item in eBankCardList"
                        :key="item.bankCardID"
                        :value="item.bankCardID"
                        class="cardWrapper"
                    >
                        <div class="logo" :style="{backgroundImage: item.logo}"></div>
                        <div class="info">
                            <div class="card">
                                <div class="name">{{item.bankCardName}}</div>
                            </div>
                            <div class="cardID">{{formatCard(item.bankCardID)}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </wrapper>
    </div>
</template>

<script>
    import sjLogo from "../../assets/product/sjLogo.png";
    import gsLogo from "../../assets/product/gsLogo.png";
    import wrapper from "./components/wrapper";

    export default {
        name: 'BankCardManage',
        description: '这是银行卡管理页面',
        components: {
            wrapper,
        },
        data() {
            return {
                bankCardList: [
                    { bankCardID: '6623286200016585325', bankCardName: '中国工商银行储蓄卡', logo: 'url(' + gsLogo + ')' },
                ],
                eBankCardList: [
                    { bankCardID: '6216610200016587010', bankCardName: '盛京银行储蓄卡', logo: 'url(' + sjLogo + ')' },
                ],
            }
        },

        created() {
            if(Object.keys(this.$route.params).length > 0) {
                let obj = this.$route.params
                delete obj.name
                this.bankCardList.push(obj)
            }
        },

        methods: {
            // 对银行卡号进行处理显示前四位和后四位中间用 *，不包含空格
            formatCard(value) {
                if(value&&value.length>8) {
                    let reg = /^(\d{4})(\d*)(\d{4})$/;
                    let str = value.replace(reg, (a, b, c, d)=> {
                        return b + c.replace(/\d/g, "*") + d;
                    });
                    return str
                } else {
                    return value
                }
            },

            // 跳转至新增银行卡页面
            toAddBankCard(name) {
                this.$router.push({
                    name: 'AddBankCard',
                    params: {
                        name: name
                    }
                })
            },
        },
    }
</script>

<style lang="less" scoped>

    @import "less/product.less";

    .container {
        .detailWrapper {
            .icon {
                width: 20px;
                height: 20px;
                position: absolute;
                right: 24px;
                top: 40px;
                line-height: 19.2px;
                color: #FFFFFF;
                background: #FFBA00;
                font-size: 32px;
                border-radius: 50%;
            }
        }
        .bankCardList {
            .cardWrapper {
                align-items: center;
                padding: 4px;
            }
        }
    }
</style>