<template>
    <div>
        <div class="container">
            <div class="dataWrapper">
                <div class="data-content">
                    <div class="rate">
                        <div class="value">1.02</div>
                        <div class="title">昨日收益(元)</div>
                    </div>
                    <div class="date">
                        <div class="value">1,700.02</div>
                        <div class="title">持有总资产(元)</div>
                    </div>
                    <div class="date">
                        <div class="value">10.02</div>
                        <div class="title">累计收益(元)</div>
                    </div>
                </div>

            </div>

            <wrapper title="当前进度">
                <div slot="content">
                    <a-steps :current="1">
                        <a-step title="转入日期" description="02-01">
                            <i slot="icon" class="aStep" />
                        </a-step>
                        <a-step title="开始计息" description="02-10">
                            <i slot="icon" class="aStep" />
                        </a-step>
                        <a-step title="产品到期" description="02-15">
                            <i slot="icon" class="aStep" />
                        </a-step>
                        <a-step title="本息到账" description="02-20">
                            <i slot="icon" class="aStep" />
                        </a-step>
                    </a-steps>
                </div>
            </wrapper>

            <wrapper title="交易规则">
                <div slot="content">
                    <div class="trade-item trade">
                        <div class="title">购买时间</div>
                        <div class="rule">每日0:00-24:00</div>
                    </div>
                    <div class="trade-item trade">
                        <div class="title">购买费率</div>
                        <div class="rule">购买费率为0</div>
                    </div>
                    <div class="trade-item trade">
                        <div class="title">赎回时间</div>
                        <div class="rule">每日0:00-24:00</div>
                    </div>
                    <div class="trade-item trade">
                        <div class="title">赎回费率</div>
                        <div class="rule">赎回费率为0</div>
                    </div>
                    <div class="trade-item">
                        <div class="title">到账时间</div>
                        <div class="rule">赎回后1-3日到账</div>
                    </div>
                </div>
            </wrapper>

            <div class="introduceWrapper">
                <div class="introduce-item">
                    <router-link to="/product/introduce">
                        <div class="title">产品说明书</div>
                        <a-icon type="right" />
                    </router-link>
                </div>
            </div>

        </div>

        <div class="btnContainer">
            <div class="redeemBtn btn" @click="toTransHistory">交易记录</div>
            <div class="buyBtn btn" @click="toMy">转入</div>
        </div>

    </div>
</template>

<script>
    import wrapper from "./components/wrapper";

    export default {
        name: 'ProductRedeem',
        description: '这是产品赎回页面',
        components: {
            wrapper,
        },
        data() {
            return {

            }
        },

        methods: {
            // 跳转交易记录页面
            toTransHistory() {
                this.$router.push({
                    path: '/holdingassets/TransHistory',
                })
            },

            // 跳转到我的资产页面
            toMy() {
                this.$router.push({
                    path: '/holdingassets/HoldDetail',
                })
            },
        },
    }
</script>

<style lang="less" scoped>

    @import "less/product.less";

    .container {
        margin-bottom: 64px;
        .dataWrapper {
            padding: 24px 32px 24px 32px;
            background: linear-gradient(0deg, #F16101 0%, #FC991A 100%);
            border-radius: 4px;

            .data-content, .dec-content {
                display: flex;
                justify-content: space-between;
            }

            .data-content {
                .rate, .date {
                    color: #FFFFFF;
                    text-align: center;
                }

                .rate .value, .date .value {
                    font-size: 24px;
                    font-weight: 700;
                }

                .rate .title, .date .title {
                    font-size: 12px;
                }
            }

            .dec-content {
                margin-top: 16px;

                .dec-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .title {
                        margin-top: 4px;
                        font-size: 12px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .detailWrapper {
            .contentWrapper {
                .ant-steps {
                    display: flex;
                    .ant-steps-item-icon {
                        float: none;
                    }
                }
                .aStep {
                    width: 24px;
                    height: 24px;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

            .trade-item {
                display: flex;
                justify-content: space-between;
                .title {
                    font-size: 13.6px;
                    color: #A5A5A5;
                }
                .rule {
                    font-size: 13.6px;
                    color: #000000;
                }
            }
            .trade {
                margin-bottom: 12px;
            }
        }

        .introduceWrapper {
            margin-top: 12px;
            margin-bottom: 48px;
            padding: 16px 12px;
            background: #FFFFFF;
            border-radius: 4px;
            .introduce-item {
                a {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    .title {
                        font-size: 14.4px;
                        color: #000000;
                    }
                    i {
                        font-size: 12.8px;
                        color: #A5A5A5;
                    }
                }
            }
            .ant-divider {
                margin: 12px 0;
            }
        }
    }

    .btnContainer {
        .btn {
            width: 50%;
            padding: 12px 0;
            text-align: center;
            color: #FFFFFF;
        }
        .redeemBtn {
            background: #FF7200;
        }
        .buyBtn {
            background: #FFBA00;
        }
    }

</style>
<!-- 重定义进度条默认样式 -->
<style>
    @import "less/step.less";
</style>