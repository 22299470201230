<template>
  <div>
    <div id="pc">
      <div class="pc-top">
        <div style="line-height: 1.5rem">
          <img src="../../assets/pc服务案例/服务案例.png" alt="" />
        </div>
      </div>
      <div class="breadmenu" style="background-color: rgb(17, 20, 33);">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="background-color: rgb(17, 20, 33);">
          <el-breadcrumb-item style="font-size: 0.12rem;" :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 0.1rem;" :to="{ path: '/case' }">服务案例</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="bussBox">
        <div style="width: 60%; margin: auto; display: flex;border-bottom: 1px solid rgb(211, 188, 147);">
          <div style="width: 55%;">
            <div style="font-size: 40px; color: #fff">{{ contain.title }}</div>
            <div style="margin: 10px 0 40px 0">
              <span class="date">发布时间：{{ contain.created }}</span>
            </div>
            <div style="font-size: 18px; color: #fff" v-html="contain.content">
            </div>
          </div>
          <div style="width: 479px;
        margin-left: 0.1rem;
        height: 300px;"><img :src="contain.showImage" alt="" style="width: 100%;
        height: 100%;"></div>
        </div>
        <div >
          <div style="    width: 60%;
      margin: 20px auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;">
         
            <!-- <div style="display: inline-block; margin: 150px 55px">
              <i class="el-icon-full-screen"></i>
            </div> -->
            <div>
              <button style="border: none; color: rgb(211, 188, 147);background: none;" :disabled="next" @click="gonext">
                <div v-if="next == true">已经是第一篇了</div>
                <div v-else>
                  <span> <a style="    color: rgb(211, 188, 147);">上一篇：</a> </span>
                  <span> <a style="    color: rgb(211, 188, 147);">{{ nextone.articleTitle }}</a></span>
                </div>
              </button>
            </div>
            <div>
              <button style="border: none;color: rgb(211, 188, 147);background: none;" :disabled="last" @click="golast">
                <div v-if="last == true">已经是最后一篇了</div>
                <div v-else>
                  <span> <a style="    color: rgb(211, 188, 147);">下一篇：</a> </span>
                  <span> <a style="    color: rgb(211, 188, 147);">{{ lastone.articleTitle }}</a></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="mobiel" style="background-color:#111421">
      <div class="mobielhead">
        <div style="line-height: 2rem">
          <img src="../../assets/pc服务案例/服务案例.png" alt="" style="
                  width:3rem;
                  " />
        </div>
      </div>
      <div class="mobiel-contain">
        <div class="mobiel-case">
          <div style="border: 1px soild #d3bc93; margin: 0.4rem 0">
            <div style="text-align: center">
              <img :src="contain.showImage" alt="" style=" width: auto;
                          height: auto;
                          max-width: 100%;
                          max-height: 100%;
                        " />
            </div>
            <div style="color: #d3bc93; font-size: 30px; margin-top: 10px">
              {{ contain.highlightTitle }}
            </div>
            <div style="font-size: 14px; color: #d3bc93; margin: 15px 0">
              <span>{{ contain.created }}</span>
              <!-- <span>浏览次数:9866次</span> -->
            </div>
            <div style="font-size: 14px; color: #d3bc93" v-html="contain.content">

            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 100px"></div>
    </div>
  </div>
</template>
<script>
import { getUserInfo, setUserInfo, getCurrentCompany } from "@/utils/storage";
import { postAction } from "@/api/manage";
// import img0 from "../../assets/pc服务案例/图层 14.png";
export default {
  data() {
    return {
      query: [],
      contain: {},
      nextone: {},
      lastone: {},
      last: false,
      next: false,
      userInfo: {
        setting: [],
      },
      bindData: "",
      bannerList: [
        {
          url: require("../../assets/website/shou1.jpg"),
          // url:"../../assets/website/shou1.jpg"
        },
        {
          url: require("../../assets/website/shou2.jpg"),
          // url:"../../assets/website/shou2.jpg"
        },
      ],
    };
  },
  created() { },
  activated() { },
  methods: {
    golast() {
      postAction(
        "/api/article/detail",
        {
          id: this.lastone.articleId,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        // console.log(res, "后台详情");
        this.contain = res.detail;
        if (res.detail.nextOne == undefined) {
          this.nextone = {};
          // this.lastone = res.detail.lastOne;
          // this.last = false;
          this.next = true;
        } else {
          this.nextone = res.detail.nextOne;
          this.next = false;
        }
        if (res.detail.lastOne == undefined) {
          this.lastone = {};
          // this.nextone = res.detail.nextOne;
          this.last = true;
          // this.next = false;
        } else {
          this.lastone = res.detail.lastOne;
          this.last = false;
        }
        // console.log(res.detail.lastOne, "4545");
      });
    },
    gonext() {
      postAction(
        "/api/article/detail",
        {
          id: this.nextone.articleId,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        // console.log(res, "后台详情");
        this.contain = res.detail;
        if (res.detail.nextOne == undefined) {
          this.nextone = {};
          // this.lastone = res.detail.lastOne;
          // this.last = false;
          this.next = true;
        } else {
          this.nextone = res.detail.nextOne;
          this.next = false;
        }
        if (res.detail.lastOne == undefined) {
          this.lastone = {};
          // this.nextone = res.detail.lastOne;
          this.last = true;
          // this.next = false;
        } else {
          this.lastone = res.detail.lastOne;
          this.last = false;
        }
      });
    },
    getmessage() {
      postAction(
        "/api/article/detail",
        {
          id: this.query.id,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        // console.log(res, "后台详6456465465情");
        this.contain = res.detail;
        // this.lastone = res.detail.lastOne;
        // this.nextone = res.detail.nextOne;
        if (res.detail.nextOne == undefined) {
          this.nextone = {}
          // this.lastone = res.detail.lastOne;
          // this.last = false;
          this.next = true
        } else {
          this.nextone = res.detail.nextOne
          this.next = false
        }
        if (res.detail.lastOne == undefined) {
          this.lastone = {}
          // this.nextone = res.detail.nextOne;
          this.last = true;
          // this.next = false
        } else {
          this.lastone = res.detail.lastOne
          this.last = false
        }
      });
    },
  },
  mounted() {
    this.query = this.$route.query
    // console.log(this.query,'传参')
    this.getmessage()
    // 判断pc和移动
    // let str = window.navigator.userAgent;
    // if (str.toLowerCase().indexOf("mobile") == -1) {
    //   console.log(document.getElementById("pc"), "pc777777777777");
    //   document.getElementById("pc1").style.display = "block";
    //   document.getElementById("mobiel1").style.display = "none";
    // } else {
    //   document.getElementById("mobiel1").style.display = "block";
    //   document.getElementById("pc1").style.display = "none";
    // }
  },
  destroyed() { },
};
</script>
<style lang="less" scoped>
#pc {
  display: none;
}

#mobiel {
  display: none;
}

@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: block;
  }

  #mobiel {
    display: none;
  }
}

.mobiel-case {
  width: 80%;
  margin: auto;
}

.mobielhead {
  background-image: url(../../assets/招贤纳士/图层\ 10.png);
  height: 2rem;
  text-align: center;
}

.bussBox {
  background-image: url('~@/assets/images/back.png');
  background-position: 50% center;
  border-top: 0.01333rem solid rgb(211, 188, 147);
  padding: 0.26667rem 0px;
}

.date {
  font-size: 8px;
  color: #fff;

}

/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #d9bb95;
}

/deep/.el-breadcrumb__inner.is-link {
  color: #d9bb95;
}

.pc-top {
  background-image: url(../../assets/招贤纳士/图层\ 14.png);
  width: 100%;
  height: 1.5rem;
  text-align: center;
}

.el-breadcrumb {
  font-size: 0.05rem;
  line-height: 0.35rem;
  background-color: #fff;
  height: 0.35rem;
  padding-left: 2.8rem;
}
</style>
  
<style></style>