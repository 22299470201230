<template>
  <div>
    <div id="pc">
      <div class="pc-top">
        <div style="line-height:1.5rem">
            <img src="../../assets/招贤纳士/组 1.png" alt="" />
        </div>
      </div>
      <menutable></menutable>
      <div class="contain">
        <div
          class="conmessage"
          v-for="(item, index) in pcprogrem"
          :key="index"
          @click="godetail(item)"
        >
          <div
            style="font-size: 20px"
          >
          <!-- <button style="background-color:#ffffff;border:none">
            {{ item.name }}
          </button>  -->
          <a>
            {{ item.title }}
          </a>
          </div>
          <div style="margin-top: 10px; font-size: 16px">{{ item.date }}</div>
          <!--<div style="position: absolute; right: 0.4rem;top: 0.4rem;">-->
          <!--  <div>-->
          <!--      <img src="../../assets/招贤纳士/图层 15.png" alt="" style="width:20px"/>-->
          <!--  </div>-->
          <!--  <div style="margin-top:5px;font-size: 10px;">-->
          <!--      分享-->
          <!--  </div>-->
          <!--</div>-->
          <div style="width: 90%; margin-top: 15px; font-size: 14px" class="messagedetail">
            {{ item.highlightContent }}
          </div>
        </div>
        <div style="margin: 20px auto;">
          <div
            style="margin: auto; width: 55%;display: flex;justify-content: space-around;"
            class="lastPage"
          >
            <div>
              <!-- <el-button
                :disabled="currentPage === 1"
                class="first-pager"
                @click="toFirstPage"
                >首页</el-button
              > -->
            </div>
            <el-pagination
              ref="pagination"
              background
              layout="prev, pager, next"
              :current-page="currentPage"
              :page-count="totalpage"
              @current-change="changecurrent"
              next-text="下一页"
              prev-text="上一页"
              :small="true"
            >
            </el-pagination>
            <div style="height: 51px">
              <!-- <el-button class="last-pager" @click="toLastPage">末页</el-button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="mobiel" >
      <div class="mobielhead">
        <div style="line-height: 2rem">
          <img src="../../assets/招贤纳士/组 5.png" alt="" style="width:3.5rem" />
        </div>
      </div>
      <div class="mobiel-contain"   style="overflow:auto" >
        <div
          class="mobiel-conmessage"
          v-for="(item, index) in moprogrem"
          :key="index"
          @click="godetail(item)"
        >
          <div
            :style="{ color: item.istrue == true ? '#E6B150' : '#000000' }"
            style="font-size: 20px"
          >
            {{ item.title }}
          </div>
          <div style="margin-top: 10px; font-size: 16px">{{ item.date }}</div>
          <div style="width: 99%; margin-top: 15px; font-size: 14px" class="messagedetail">
            {{ item.highlightContent }}
          </div>
        </div>
        <div style="text-align:center" @click="getmore" v-if="mototal <mototals ">更多</div>
          <div style="width: 100%; height: 100px"></div>
      </div>
      
    </div>
    <recruitdetail :pcprogrem="pcprogrem"></recruitdetail>
  </div>
</template>
<script>
import { getAction,postAction } from "@/api/manage";
import menutable from "@/components/menu/menutable";
import recruitdetail from "./recruitdetail.vue"
export default {
  components: { menutable },
  data() {
    return {
      mocurrentPage:1,
      mototal:0,
      mototals:null,
      currentPage: 1,
      pageSize: 3,
      pcprogrem:[],
      moprogrem:[],
      totalpage:null,
      loading:false,
    };
  },
  methods: {
    godetail(val) {
      val.istrue = !val.istrue;
      // query=val
      this.$router.push({
          path: "/recruit/detail",
          query:{
            id:val.id,
            slug:val.siteId
          }
        });
    },
    jumpFirstPage() {},
    jumpLastPage() {
      let font = this.$refs.pagination;
      let cpage = Math.ceil(font.total / font.pageSize);
      font.handleCurrentChange(cpage);
    },
    getlist(){
      postAction('/api/article/paginate',{
        categoryId:5,
        pageNumber:this.currentPage,
        pageSize:this.pageSize
      },{'Content-Type':'application/x-www-form-urlencoded'}).then((res)=>{
        // console.log(res,'后台5656返回')
        this.pcprogrem = res.page.list
        this.totalpage =res.page.totalPage
      })
    },
    getmore () {
          this.mocurrentPage ++
          this.getmobiellist()
      },
    getmobiellist(){
      postAction('/api/article/paginate',{
        categoryId:5,
        pageNumber:this.mocurrentPage,
        pageSize:this.pageSize
      },{'Content-Type':'application/x-www-form-urlencoded'}).then((res)=>{
        // console.log(res.page.list.length,'changdu')
        this.mototal +=res.page.list.length
        this.mototals=res.page.totalRow
        // console.log(res,'返回')
        res.page.list.forEach(item => {
          this.moprogrem.push(item)
        });
       
        //this.totalpage =res.page.totalPage
      })
    },
    changecurrent(val){
      this.currentPage =val
      this.getlist()
    }
  },
  filters: {
    formatNull: function (val) {
      if (val == null) {
        return 0;
      } else {
        return val;
      }
    },
  },
  mounted() {
    // this.listData();
    // this.initYearList();
    this.getlist()
    this.getmobiellist()
  },
};
</script>
<style  lang="less" scoped>
 #pc {
  display: none;
}
#mobiel {
  display: none;
}
@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: block;
  }

  #mobiel {
    display: none;
  }
}
.pc-top{
    background-image: url(../../assets/招贤纳士/图层\ 14.png);
    width:100%;
    height: 1.5rem;
    text-align: center;
}
.messagedetail{
    display: -webkit-box !important;
        overflow: hidden;

        word-break:break-all ;
        text-overflow: ellipsis;

        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
}
.mobielhead {
  background-image: url(../../assets/招贤纳士/图层\ 10.png);
  height: 2rem;
  text-align: center;
}
.first-pager {
  cursor: pointer;
  padding: 0;
  width: 0.25rem;
  font-size: 5px;
  height: 0.2rem;
  margin-right: -0.5rem;
  border-radius: 0;
}
.last-pager {
  cursor: pointer;
  padding: 0;
  width: 0.25rem;
  font-size: 5px;
  height: 0.2rem;
  margin-left: -0.5rem;
  border-radius: 0;
}
/deep/ .el-pagination--small span:not([class*="suffix"]) {
  font-size: 5px;
  font-weight: 300;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #d3bc93;
}
/deep/ .el-pagination.is-background.el-pagination--small .el-pager li {
  margin: 0 0.04rem;
  min-width: 0.29333rem;
  font-size: 5px;
}

.el-pagination {
  text-align: center;
  font-weight: 300;
  display: inline-block;
}

.mobiel-contain {
  background-color: #f5f5f5;
  padding: 10px 0;
  overflow: auto;
  .mobiel-conmessage {
    width: 95%;
    margin: 10px auto;
    height: auto;
    background-color: #fff;
    padding: 0.2rem;
  }
}
.contain {
  height: 4rem;
  background: url("~@/assets/联系我们-切图/img01.png") no-repeat 0/100%;;
  border-top: 1px solid #e0e0e0;
  padding-bottom: 3px;
  .conmessage {
    color: rgb(253, 244, 230);
    width: 60%;
    margin: 10px auto;
    height: auto;
    background-color: #181d2f;
    padding: 0.2rem;
    position: relative;
    a{
      color: rgb(253, 244, 230);
    }
  }
  .lastPage{
    ::v-deep .el-pagination--small span{
      background: #181d2f;
      color: #d3bc93;
    }
  }
}
</style>