<template>
    <section class="signature">
        <div class="signatureBox">
            <div class="canvasBox" ref="canvasHW">
                <canvas ref="canvasF" @touchstart='touchStart' @touchmove='touchMove' @touchend='touchEnd'
                        @mousedown="mouseDown" @mousemove="mouseMove" @mouseup="mouseUp"></canvas>
            </div>
            <div class="btnBox">
                <div class="action" @click="overwrite">重写</div>
                <div class="action" @click="commit">提交签名</div>
            </div>
        </div>
    </section>
</template>
<script>
    import {getLocalStore, setLocalStore} from "@/utils/storage";

    export default {
        data() {
            return {
                height: 0,
                width: 0,
                stageInfo: '',
                imgUrl: '',
                client: {},
                points: [],
                canvasTxt: null,
                startX: 0,
                startY: 0,
                moveY: 0,
                moveX: 0,
                endY: 0,
                endX: 0,
                w: null,
                h: null,
                isDown: false,
                queryObj: {}
            }
        },
        mounted() {
            let canvas = this.$refs.canvasF;
            canvas.height = this.$refs.canvasHW.offsetHeight - 60;
            canvas.width = this.$refs.canvasHW.offsetWidth - 2;
            this.canvasTxt = canvas.getContext('2d');
            this.stageInfo = canvas.getBoundingClientRect();
        },
        methods: {
            //mobile
            touchStart(ev) {
                ev = ev || event;
                ev.preventDefault();
                if (ev.touches.length == 1) {
                    let obj = {
                        x: ev.targetTouches[0].clienX,
                        y: ev.targetTouches[0].clientY,
                    };
                    this.startX = obj.x;
                    this.startY = obj.y;
                    this.canvasTxt.beginPath();
                    this.canvasTxt.lineWidth = 2;
                    this.canvasTxt.moveTo(this.startX, this.startY);
                    this.canvasTxt.lineTo(obj.x, obj.y);
                    this.canvasTxt.stroke();
                    this.canvasTxt.closePath();
                    this.points.push(obj);
                }
            },
            touchMove(ev) {
                ev = ev || event;
                ev.preventDefault();
                if (ev.touches.length == 1) {
                    let obj = {
                        x: ev.targetTouches[0].clientX - this.stageInfo.left,
                        y: ev.targetTouches[0].clientY - this.stageInfo.top
                    };
                    this.moveY = obj.y;
                    this.moveX = obj.x;
                    this.canvasTxt.beginPath();
                    this.canvasTxt.lineWidth = 2;
                    this.canvasTxt.moveTo(this.startX, this.startY);
                    this.canvasTxt.lineTo(obj.x, obj.y);
                    this.canvasTxt.stroke();
                    this.canvasTxt.closePath();
                    this.startY = obj.y;
                    this.startX = obj.x;
                    this.points.push(obj);
                }
            },
            touchEnd(ev) {
                ev = ev || event;
                ev.preventDefault();
                if (ev.touches.length == 1) {
                    let obj = {
                        x: ev.targetTouches[0].clientX - this.stageInfo.left,
                        y: ev.targetTouches[0].clientY - this.stageInfo.top
                    };
                    this.canvasTxt.beginPath();
                    this.canvasTxt.lineWidth = 2;
                    this.canvasTxt.moveTo(this.startX, this.startY);
                    this.canvasTxt.lineTo(obj.x, obj.y);
                    this.canvasTxt.stroke();
                    this.canvasTxt.closePath();
                    this.points.push(obj);
                }
            },
            //pc
            mouseDown(ev) {
                ev = ev || event;
                ev.preventDefault();
                if (1) {
                    let obj = {
                        x: ev.offsetX,
                        y: ev.offsetY
                    };
                    this.startX = obj.x;
                    this.startY = obj.y;
                    this.canvasTxt.beginPath();
                    this.canvasTxt.lineWidth = 2;
                    this.canvasTxt.moveTo(this.startX, this.startY);
                    this.canvasTxt.lineTo(obj.x, obj.y);
                    this.canvasTxt.stroke();
                    this.canvasTxt.closePath();
                    this.points.push(obj);
                    this.isDown = true;
                }
            },
            mouseMove(ev) {
                ev = ev || event;
                ev.preventDefault();
                if (this.isDown) {
                    let obj = {
                        x: ev.offsetX,
                        y: ev.offsetY
                    };
                    this.moveY = obj.y;
                    this.moveX = obj.x;
                    this.canvasTxt.beginPath();
                    this.canvasTxt.lineWidth = 2;
                    this.canvasTxt.moveTo(this.startX, this.startY);
                    this.canvasTxt.lineTo(obj.x, obj.y);
                    this.canvasTxt.stroke();
                    this.canvasTxt.closePath();
                    this.startY = obj.y;
                    this.startX = obj.x;
                    this.points.push(obj);
                }
            },
            mouseUp(ev) {
                ev = ev || event;
                ev.preventDefault();
                if (1) {
                    let obj = {
                        x: ev.offsetX,
                        y: ev.offsetY
                    };
                    this.canvasTxt.beginPath();
                    this.canvasTxt.lineWidth = 2;
                    this.canvasTxt.moveTo(this.startX, this.startY);
                    this.canvasTxt.lineTo(obj.x, obj.y);
                    this.canvasTxt.stroke();
                    this.canvasTxt.closePath();
                    this.points.push(obj);
                    this.points.push({x: -1, y: -1});
                    this.isDown = false;
                }
            },
            //重写
            overwrite() {
                this.canvasTxt.clearRect(0, 0, this.$refs.canvasF.width, this.$refs.canvasF.height);
                this.points = []
            },
            //提交签名
            commit() {
                let hostname = getLocalStore('hostname');
                let queryString = decodeURI(window.location.href.split('?')[1].replace(/%3F/g, '&').replace(/%3D/g, '='));
                queryString.split('&').forEach((str) => {
                    this.queryObj[str.split('=')[0]] = str.split('=')[1];
                });

                this.imgUrl = this.$refs.canvasF.toDataURL();
                this.queryObj.hasSigned = '1'

                this.$router.push({
                    path: '/salary',
                    query: this.queryObj
                });

                let detailNo = this.queryObj.detailNo || this.queryObj.slipDetailNo;
                let info = getLocalStore(hostname + 'salaryInfo' + detailNo);
                info.signature = this.imgUrl;
                setLocalStore(hostname + 'salaryInfo' + detailNo, info);
            }
        }
    }
</script>
<style scoped>
    .signature {
        width: 100%;
        height: 100%;
    }

    .signatureBox {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        background: #fff;
        z-index: 100;
        display: flex;
        flex-direction: column;
    }

    .canvasBox {
        margin: 20px;
        box-sizing: border-box;
        flex: 1;
    }

    canvas {
        border: 1px solid #7d7d7d;
    }

    .btnBox {
        text-align: center;
        display: flex;
        justify-content: space-around;
        background: #E4E7ED;
        padding: 10px 0;
        position: relative;
        bottom: 0;
    }

    .btnBox .action:first-child {
        border-right: 1px solid #C1CFE0;
    }

    .btnBox .action {
        width: 50%;
        font-size: 15px;
        color: #4A6781;
        line-height: 30px;
        height: 30px;
    }
</style>