<template>
    <div :class="{'previewCont': true, [stylesheetName]: true}">
        <div class="headerPanel" id="salaryManage">
            <div class="chooseYear" @click="isShow=true">
                <span>{{selectYear}}年</span>
                <img src='../../assets/calendar.png' alt="" class="calendarIcon">
            </div>
            <div class='amountPanel'>
                <div class='amount rightDivider'>
                    <a-tooltip trigger='click' :title="salaryList.titleEmployeeCount">
                        <div class='numbert'>
                            <span>{{salaryList.titleEmployeeCount}}</span> 人
                        </div>
                    </a-tooltip>
                    <a-tooltip trigger='click' title="企业在职员工数" placement="bottom">
                        <span class='title'>员工总数</span>
                    </a-tooltip>
                </div>
                <div class='amount rightDivider'>
                    <a-tooltip trigger='click' :title="salaryList.titleTotalCount">
                        <div class='numbert'>
                            <span>{{salaryList.titleTotalCount}}</span> 个
                        </div>
                    </a-tooltip>
                    <a-tooltip trigger='click' title="已发送成功的工资条数" placement="bottom">
                        <span class='title'>工资条</span>
                    </a-tooltip>
                </div>
                <div class='amount'>
                    <a-tooltip trigger='click' :title="salaryList.titleTotalAmount">
                        <div class='numbert'>
                            <span>{{salaryList.titleTotalAmount}}</span> 元
                        </div>
                    </a-tooltip>
                    <a-tooltip trigger='click' title="已成功发放的工资总额" placement="bottom">
                        <span class='title'>代发总金额</span>
                    </a-tooltip>
                </div>
            </div>
        </div>

        <div class='contentPanelt'>
            <a-card class="slipContent" :bordered="false" v-for="(item,index) in salaryList.list" :key="index">
                <a-row>
                    <a-col class="slip-title">
                        <span class="year-month">{{item.title}}</span>
                    </a-col>
                    <a-col class="slip-content">
                        <div class="number-content bor-right">
                            <p class="number-title">{{item.sendCount}}人</p>
                            <p class="number-value">已发送</p>
                        </div>

                        <div class="number-content bor-right">
                            <p class="number-title">{{item.viewCount}}人</p>
                            <p class="number-value">已查看</p>
                        </div>

                        <div class="number-content bor-right">
                            <p class="number-title">{{item.confirmCount}}人</p>
                            <p class="number-value">已确认</p>
                        </div>

                        <div class="number-content">
                            <p class="number-title">{{item.feedbackCount}}人</p>
                            <p class="number-value">有反馈</p>
                        </div>
                            
                        
                    </a-col>
                    <a-col class="slip-footer">
                        <span>本次共发送{{item.totalCount}}条</span>
                        <span>总金额 {{formatNumber(item.totalAmount)}}元</span>
                    </a-col>
                </a-row>
            </a-card>

            <div v-if="salaryList.list.length===0" style="background: white; padding: 10px; margin: 0 10px;">暂无数据</div>
        </div>

        <!-- <div class='footerPanel'>
            <button class='button'>导出工资条</button>
        </div> -->

        <!--<div :class="{chooseYearPanel: true, show: isShow}">-->
            <!--<div class="yearPanel">-->
                <!--<div v-for="year in yearList" :key="year" :class="{yearItem: true, current: selectYear===year}"-->
                     <!--@click="selectYear=year,isShow=false">{{year}}</div>-->
            <!--</div>-->
        <!--</div>-->

        <div id="salaryChoose" :class="{chooseYearPanel: true, show: isShow}">
            <div class="yearPanel">
                <div v-for="year in yearList" :key="year" :class="{yearItem: true, current: selectYear===year}"
                     @click="changeYear(year)">{{year}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {postAction} from '@/api/manage';
import {getUserInfo, getCurrentCompany} from '@/utils/storage';
import {trans_money1} from '@/utils/util'
export default {
    data() {
        return {
            stylesheetName: 'default',
            salaryList: {
                titleEmployeeCount: 1,
                titleTotalCount: 0,
                titleTotalAmount: '0.00',
                list: []
            },
            arr:[{
                sendCount: 11,
                viewCount: 11,
                confirmCount: 11,
                feedbackCount: 11,
                title:'和客户介绍客户开发',
                totalCount: 33,
                totalAmount: 22,
            },
            {
                sendCount: 11,
                viewCount: 11,
                confirmCount: 11,
                feedbackCount: 11,
                title:'和客户介绍客户开发',
                totalCount: 33,
                totalAmount: 22,
            },
            {
                sendCount: 11,
                viewCount: 11,
                confirmCount: 11,
                feedbackCount: 11,
                title:'和客户介绍客户开发',
                totalCount: 33,
                totalAmount: 22,
            }
            ],
            nextPageList: [],
            counter: 1, //默认已经显示出15条数据 count等于一是让从16条开始加载
            num: 5,  // 一次显示多少条
            pageStart: 0, // 开始页数
            pageEnd: 0, // 结束页数
            listData: [], // 下拉更新数据存放数组
            downData: [],  // 上拉更多的数据存放数组
            userInfo: {},
            yearList: [],
            selectYear: new Date().getFullYear(),
            isShow: false,
            url:{
                query:'/salarysmanager/query'
            },
            currentCompany: {}
        }
    },
    methods:{
        initYearList() {
            this.yearList = [];
            let current = new Date().getFullYear() ; 
            for (var year = current; year > current - 10; year--) {
                this.yearList.push(year);
            }
        },
        changeYear(year) {
            this.selectYear = year;
            this.isShow = false;
            this.managQuery();
        },
        managQuery(){
            let params = {
                year: this.selectYear,
                companyId: this.currentCompany.companyId,
            };
            postAction(this.url.query, params).then((res) => {
                if (res.success) {
                    this.salaryList = res.data;
                }
                if(res.code===510){
                    this.$message.warning(res.message)
                }
            })
        },
        formatNumber(num) {
            let result = num;
            if (num > 10000) {
                result = trans_money1(num / 10000, 2) + '万'
            }
            return result;
        }
    },
    mounted() {
        this.initYearList();
        this.userInfo = getUserInfo() || {};
        this.currentCompany = getCurrentCompany() || {};
        this.stylesheetName = this.userInfo.stylesheetName || 'default';
        this.managQuery();
    }
}
</script>
<style lang="less" scoped>
    @import "salaryList";
    #salaryManage .chooseYear {
        top: 15px;
    }
    #salaryChoose.chooseYearPanel {
        top: 35.2px;
    }
</style>
<style lang="less">
    .numbert {
        display: flex;
        justify-content: center;
    }
    .numbert{
        font-size: 18px;
        span {
            max-width: 70%;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .contentPanelt {
        position: relative;
        overflow-y: hidden;
        padding: 15px 0;
    }
    .slipContent{
        margin: 0 15px 15px;
        padding-top: 3.2px;
        border-radius: 10px;
        .ant-card-body{
            padding:0;
        }

        .slip-title{
            padding: 10px 15px 10px 33.6px;
            // border-bottom: 1px solid #E2E1E4;
            background-repeat: no-repeat;
            background-size: 18px auto;
            background-position: 10px center;

            img{
                margin-right: 5px;
            }

            .year-month{
                font-family: 'PingFangSC-Regular';
                color:#354D63;
            }
        }
        .slip-content{
            height: 50px;
            // height:4.375rem;
            // padding: 20px 0;
            // border-bottom: 1px solid #E2E1E4;

            .number-content{
                padding: 15px;
                width: 25%;
                font-size: 14px;
                color: #8c8c8c;
                text-align: center;
                float:left;

                .number-title{
                    display: block;
                    font-size: 13px;
                    color:#354D63;
                    margin:0 0 5px;
                }

                .number-value{
                    display: block;
                    font-size: 12px;
                    color:#8FA2B4;
                    margin-bottom: 8px;
                }
            }

            .bor-right{
                border-right: 1px solid #DEE0E2;
            }
        }

        .slip-footer{
             margin:30px 15px 10px;
            // text-align: right;
            color:#8FA2B4;
            font-size:12px;
            display: flex;
            justify-content: space-between;
        }
    }
    
</style>