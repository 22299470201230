<template>
    <div class="listContainer">
        <div class="productWrapper">
            <div class="productList" v-for="item in productList" :key="item.productNo" @click="toProductDetail(item)">
                <div class="top-title">{{item.title}}</div>
                <div class="decWrapper">
                    <div class="rate">
                        <div class="value">{{item.rate}}</div>
                        <div class="title">年利率</div>
                    </div>

                    <div class="dec">
                        <div class="dec-item">
                            <i class="depositIcon decIcon"></i>
                            <div class="title">50元起存</div>
                        </div>
                        <div class="dec-item">
                            <i class="termIcon decIcon"></i>
                            <div class="title">1年到期</div>
                        </div>
                        <div class="dec-item">
                            <i class="interestIcon decIcon"></i>
                            <div class="title">当日起息</div>
                        </div>
                        <div class="dec-item">
                            <i class="riskIcon decIcon"></i>
                            <div class="title">低风险</div>
                        </div>
                    </div>
                </div>
                <div class="btnWrapper">
                    <a-button shape="round">立即购买</a-button>
                </div>
            </div>
        </div>

        <div class="list-title">为您推荐</div>

        <div class="recommendWrapper">
            <div class="left-content">
                <div class="line"></div>
                <div class="title">固定存款产品1号</div>
            </div>
            <div class="right-content">
                <div class="rate">
                    <div class="title">6.06%</div>
                    <div class="dec">年利率</div>
                </div>
                <div class="year">
                    <div class="title">5年</div>
                    <div class="dec">一万元起购</div>
                </div>
            </div>
        </div>

        <div class="recommendWrapper">
            <div class="left-content">
                <div class="line"></div>
                <div class="title">固定智能存款产品1号</div>
            </div>
            <div class="right-content">
                <div class="rate">
                    <div class="title">3.06%</div>
                    <div class="dec">年利率</div>
                </div>
                <div class="year">
                    <div class="title">无固定期限</div>
                    <div class="dec">一万元起购</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProductList',
        description: '这是产品列表',
        data() {
            return {
                productList: [
                    {productNo: '001', title: '存款产品1号', rate: '6.06%', isOpen: false},
                    {productNo: '002', title: '存款产品2号', rate: '4.05%', isOpen: true},
                ]
            }
        },

        methods: {
            // 跳转到产品详情页面
            toProductDetail(item) {
                this.$router.push({
                    name: 'ProductDetail',
                    params: item
                });
            },
        },
    }
</script>

<style lang="less" scoped>
    .depositIcon {
        background-image: url(../../assets/product/deposit.png);
    }

    .termIcon {
        background-image: url(../../assets/product/term.png);
    }

    .interestIcon {
        background-image: url(../../assets/product/interest.png);
    }

    .riskIcon {
        background-image: url(../../assets/product/risk.png);
    }

    .decIcon {
        width: 24px;
        height: 24px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .listContainer {
        padding: 12px;
        .productList {
            margin-top: 24px;
            margin-bottom: 4px;
            background: #FFFFFF;
            box-shadow: 0px 8px 0px 0px rgba(244, 198, 96, 0.43);
            border-radius: 4px;
            .top-title {
                padding-top: 12px;
                padding-bottom: 20px;
                font-size: 14.4px;
                font-weight: 700;
                text-align: center;
                color: #000000;
            }
            .decWrapper {
                display: flex;
                justify-content: space-around;
                .rate {
                    .value {
                        font-size: 19.2px;
                        font-weight: 700;
                        color: #FD5817;
                    }
                    .title {
                        font-size: 12px;
                        color: #7A7A7A;
                    }
                }

                .dec {
                    display: flex;
                    justify-content: space-around;
                    .dec-item {
                        margin: 0 8px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .title {
                        margin-top: 4px;
                        font-size: 12px;
                        color: #7A7A7A;
                    }
                }
            }
            .btnWrapper {
                padding-top: 16px;
                padding-bottom: 16px;
                text-align: center;
                .ant-btn {
                    height: 40px;
                    padding: 0 48px;
                    background: #FDB717;
                    color: #000000;
                    font-size: 14.4px;
                }
            }
        }
        .productList:first-child {
            margin-top: 0;
        }
        .list-title {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 14.4px;
            color: #000000;
        }
        .recommendWrapper {
            margin-bottom: 12px;
            padding: 12px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FFFFFF;
            border-radius: 4px;
            .left-content, .right-content {
                width: 50%;
                display: flex;
                align-items: center;
            }
            .left-content {
                .line {
                    width: 8.6px;
                    height: 40px;
                    margin-right: 12px;
                    background: #FB921D;
                    border-radius: 0 4px 4px 0;
                }
                .title {
                    color: #000000;
                }
            }
            .right-content {
                justify-content: space-around;
                border-left: 1px solid #DEDFE0;
                .rate, .year {
                    text-align: center;
                }
                .rate .dec, .year .dec {
                    font-size: 12px;
                    color: #A5A5A5;
                }
                .rate {
                    .title {
                        color: #FD5817;
                    }
                }
                .year {
                    .title {
                        color: #000000;
                    }
                }
            }
        }
    }
</style>