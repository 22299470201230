<template>
    <div class="questionContent">
        <div class="questionPanel">
            <div class="question-title">请输入你的反馈</div>
            <a-form :form="form" class="hasQuestionPanel">
                <a-form-item>
                    <a-textarea placeholder="" class="question" v-decorator="['message', {
                        rules: [{validator: this.checkValidate}, {validator: this.validateComment}]
                    }]" :maxLength='100'>
                    </a-textarea>
                </a-form-item>
                <a-button type="primary" :class="{'disabled': isFeedbacked}" :disabled="isFeedbacked" :loading='loading'
                    @click="sendAction">提交</a-button>
            </a-form>
        </div>
    </div>
</template>
<script>
    import {postAction} from "../../api/manage";
    import {getUserInfo, getCurrentCompany, getLocalStore, setLocalStore} from "@/utils/storage"

    export default {
        data() {
            return {
                stylesheetName: '',
                loading: false,
                isFeedbacked: false
            }
        },
        methods: {
            checkValidate(rule, value, callback) {
                if (value == undefined || value == '') {
                    callback('请输入您的疑问')
                } else {
                    callback();
                }
            },
            validateComment(rule, value, callback) {  //只允许输入汉字，中英文的逗号，句号和叹号。
                if (!value || /^[()（）0-9\u4e00-\u9fa5,，。.！!:：]{1,100}$/ig.test(value)) {
                    callback();
                } else {
                    callback("只允许输入汉字，数字，中英文的冒号，逗号，句号和叹号!");
                }
            },
            sendAction() {
                if (this.isFeedbacked) {
                    return false;
                }
                this.form.validateFields((err, values) => {
                    if (this.loading) {
                        return false;
                    }
                    if (!err) {
                        let userInfo = getUserInfo()
                        let currentCompany = getCurrentCompany()
                        let postData = Object.assign(this.$route.query, values, {
                            employeeId: userInfo.employeeId,
                            companyId: currentCompany.companyId,
                            companyName: currentCompany.companyName
                        });

                        if (!postData.slipBatchNo) {
                            let info = getLocalStore(hostname + 'salaryInfo' + detailNo);
                            postData.slipBatchNo = info.slipBatchNo;
                        }
                        
                        this.loading = true;
                        postAction('/feedback/addquestion', postData).then((res) => {
                            this.isFeedbacked = true;
                            this.loading = false;
                            if (res.success) {
                                this.updateCurSalaryStore({isFeedbackd: '01'});

                                this.$message.success('提交成功', 2, () => {
                                    this.$router.push({
                                        path: '/salary',
                                        query: this.$route.query
                                    })
                                });
                            } else {
                                this.$message.warning(res.message);
                            }
                        });
                    }
                });
            },
            queryBtnStatus() {
                postAction('/salarydetail/queryButtonStatus', {
                    detailNo: this.$route.query.slipDetailNo,
                    employeeId: this.$route.query.employeeId
                }).then((res) => {
                    if (res.success) {
                        this.isFeedbacked = res.data.isFeedbackd === '01';
                        this.updateCurSalaryStore({
                            isFeedbackd: res.data.isFeedbackd
                        });
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            updateCurSalaryStore(obj) {
                let detailNo = this.$route.query.slipDetailNo;
                let hostname = getLocalStore('hostname');
                let info = getLocalStore(hostname + 'salaryInfo' + detailNo);
                if (detailNo) {
                    setLocalStore(hostname + 'salaryInfo' + detailNo, Object.assign(info, obj));
                }
            },
        },
        created() {
            this.form = this.$form.createForm(this, {name: 'questionForm'});
            // this.queryBtnStatus();
        }
    }
</script>
<style lang="less" scoped>
    .questionContent {
        margin-top: 15px;
        padding: 10px 15px;
        height: 100%;
    }

    .question-title {
        margin-bottom: 15px;
    }

    .ant-btn-primary {
        float: right;
        padding: 0 32px;
        height: 40px;
        line-height: 40px;
        border: none;
    }

    .question {
        background: white;
        height: 160px;
        border: none;
    }

    .red {
        .ant-btn-primary {
            color: white;
            background: #ffba00;
            text-shadow: none;
            border: none;
            &.disabled {
                background: #d4d4d4;
            }
        }
    }
</style>