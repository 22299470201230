<template>
    <div :class="{resetPwdPanel: true, [stylesheetName]: true}">
        <header>修改工资条查询密码</header>
        <a-form class="formPanel" :form="form">
            <a-form-item>
                <a-input type="password" id="oldPassword" placeholder="旧密码" 
                    :class="{'inputClass': true, 'current': currentFocus == 'oldPassword'}" :maxLength="6" v-decorator="['oldPassword',validatorRules.oldPassword]" @focus="changeFocus">
                </a-input>
            </a-form-item>
            <a-form-item>
                <a-input type="password" id="newPassword" placeholder="新密码" 
                    :class="{'inputClass': true, 'current': currentFocus == 'newPassword'}" :maxLength="6" v-decorator="['newPassword',validatorRules.newPassword]" @focus="changeFocus">
                </a-input>
            </a-form-item>

            <a-form-item>
                <a-input type="password" id="repeatPwd" placeholder="再次确认新密码" 
                    :class="{'inputClass': true, 'current': currentFocus == 'repeatPwd'}"
                    :maxLength="6" v-decorator="['repeatPwd',validatorRules.repeatPwd]" @focus="changeFocus">
                </a-input>
            </a-form-item>
            <pass-keyboard ref="passKeyboard" :passKey.sync="pwd"></pass-keyboard>
        </a-form>

        <div style="text-align: right; margin-top: 10px;">
            <a-button class="submitBtn" @click="editPwd" :loading="loading">修改</a-button>
        </div>
    </div>
</template>
<script>
    import {setUserInfo, getUserInfo, getCurrentCompany} from '@/utils/storage';
    import {postAction} from '@/api/manage';
    import PassKeyboard from '@/components/tools/PassKeyboard'
    import crypto from 'crypto'
    export default {
        components: {PassKeyboard},
        data() {
            return {
                loading: false,
                stylesheetName: 'default',
                form: this.$form.createForm(this),
                oldPassword: '',
                newPassword: '',
                repeatPwd: '',
                currentCompany: {},
                currentFocus: '',
                pwd: '',
                validatorRules: {
                    oldPassword: {rules: [{required: true, message: '旧密码不能为空!'}]},
                    newPassword: {rules: [{required: true, message: '新密码不能为空!'}]},
                    repeatPwd: {rules: [{required: true, message: '再次输入密码不能为空!'}]}
                }
            }
        },
        watch: {
            pwd(value) {
                this.form.setFieldsValue({[this.currentFocus]: value})
            }
        },
        methods: {
            mixinPwd(pwd) {
                let md5 = crypto.createHash("md5");
                md5.update(pwd); //需要加密的密码
                return md5.digest('hex');  //password 加密完的密码
            },
            changeFocus(e) {
                document.activeElement.blur(e);
                this.currentFocus = e.target.id;
                let values = this.form.getFieldsValue()
                let currentPwd = values[this.currentFocus]
                this.pwd = currentPwd
                this.$refs.passKeyboard.keybdshow(this.pwd)
            },
            editPwd() {
                this.form.validateFields((err, values) => {
                    if (!err) {
                        // console.log(values);
                        let keys = Object.keys(values);
                        let userInfo = getUserInfo();
                        let postData = {unionid: userInfo.unionid, phone: userInfo.phone};
                        keys.forEach((key) => {
                            postData[key] = this.mixinPwd(values[key])
                        });
                        postData.employeeId = userInfo.employeeId;

                        if (this.loading) {
                            return false;
                        }
                        this.loading = true;
                        postAction('/wechatuser/updatePwd', postData).then((res) => {
                            this.loading = false;
                           if (res.success) {
                            //    console.log(res);
                               this.$message.success('修改密码成功');
                               userInfo = getUserInfo();
                                userInfo.hasChecked = false;
                                setUserInfo(userInfo);
                                this.$router.push('/personal');
                           } else {
                                this.form.resetFields();
                                this.$message.error(res.message);
                           }
                        });
                    }
                });
            },
            checkPwd(rule, value, callback) {
                if (!value || /^\d{6}$/.test(value)) {
                    if (this.repeatPwd && value && value !== this.repeatPwd) {
                        callback('输入的两次新密码不一致');
                    } else {
                        callback();
                    }
                } else {
                    callback('请输入6位数字');
                }
            },
            checkNewPwd(rule, value, callback) {
                if (!value || /^\d{6}$/.test(value)) {
                    if (this.newPassword && value && value !== this.newPassword) {
                        callback('输入的两次新密码不一致');
                    } else {
                        callback();
                    }
                } else {
                    callback('请输入6位数字');
                }
            }
        },
        mounted() {
            this.currentCompany = getCurrentCompany() || {};
        }
    }
</script>
<style lang="less">
    .ant-input {
        &:hover, &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
</style>
<style lang="less" scoped>
    .resetPwdPanel {
        height: 100%;
        padding: 15px;
        header {
            margin: 15px 0 10px;
        }
    }
    .formPanel {
        background: white;
        padding: 20px 10px 10px;
        border-radius: 10px;
        .ant-form-item {
            margin-bottom: 10px;
        }
    }
    .ant-input {
        background: none;
        border: none;
        border-bottom: 1px solid #D9E5EF;
        border-radius: 0;
    }
    .submitBtn {
        padding: 0 32px;
        height: 40px;
        line-height: 40px;
        border: none;
    }
    .getCaptcha {
        color: #1890FF;
    }

    .inputClass {
        border: 1px solid #dfdfdf;
        width: 100%;
        padding: 0 5px;
        &.current {
            border: 1px solid @primary-color;
        }
    }

    .default {
        .submitBtn {
            color: #D9E5EF;
            background: #1890FF;
        }
    }

    .red {
        .submitBtn {
            color: white;
            background: #ffba00;
        }
    }
    .gmu-media-detect{-webkit-transition: width 0.001ms; width: 0; position: absolute; top: -10000px;}
    @media screen and (width: 375px) { #gmu-media-detect0 { width: 1px; } }
</style>
