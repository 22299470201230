<template>
    <article>
        <header>代发账户审批</header>
        <section>
            <div class="item" v-for="item in salaryInfo" :key='item.title'>
                <div class="title">{{item.title}}：</div>
                <div class="value">{{info[item.name]}}</div>
            </div>
            <div class="item" v-if="info.button!=='00'">
                <div class="title">审批状态：</div>
                <div class="value">{{status[info.status]}}</div>
            </div>
        </section>
        <footer v-if="info.button === '00'">
            <a-button @click="showModal">拒绝</a-button>
            <a-button type="primary" @click="pass">通过</a-button>
        </footer>

        <a-modal title="拒绝原因" v-model="permission" @ok="refuse" :confirmLoading="loading" okText="确认" cancelText="取消">
            <a-form :form="form">
                <a-form-item>
                    <a-textarea placeholder="请输入拒绝原因" class="selectWidth" v-decorator="['reason',{
                        rules: [{ required: true, message: '请输入拒绝原因' }]
                        }]"
                    >
                    </a-textarea>
                </a-form-item>
            </a-form>
        </a-modal>
    </article>
</template>
<script>
    import {getUserInfo} from "@/utils/storage";
    import {postAction} from "@/api/manage";

    export default {
        data() {
            return {
                salaryInfo: [
                    {title: '账户绑定', name: 'auditSort'},
                    {title: '账户名称', name: 'payAccountName'},
                    {title: '账户账号', name: 'payAccount'},
                    {title: '开户行', name: 'openBank'},
                    {title: '申请人', name: 'applyerName'},
                    {title: '联系电话', name: 'applyerPhone'},
                    {title: '申请时间', name: 'applyTime'},
                    {title: '备注', name: 'remark'}
                ],
                info: {
                    status: '00'
                },
                userInfo: {},
                queryObj: {},
                status: {
                    '00': '提交申请',
                    '01': '审批通过',
                    '02': '审批拒绝',
                    '03': '审批中',
                    '04': '撤回'
                },
                permission: false,
                form: this.$form.createForm(this),
                loading: false
            }
        },
        methods: {
            pass() {
                postAction('/salary/audit/approve', {
                    companyId: this.queryObj.companyId || this.$route.query.companyId,
                    employeeId: this.queryObj.employeeId || this.$route.query.employeeId,
                    auditNo: this.queryObj.slipBatchNo || this.$route.query.slipBatchNo,
                    auditSort:2,
                    phone: this.userInfo.phone
                }).then((res) => {
                    if (res.success) {
                        this.$message.success('操作成功', 2, () => {
                            this.queryInfo();
                        });
                    } else {
                        this.$message.warning(res.message);
                    }
                });
            },
            refuse() {
                this.form.validateFields((err, values) => {
                    if (!err) {
                        this.loading = true;
                        postAction('/salary/audit/deny', {
                            companyId: this.queryObj.companyId || this.$route.query.companyId,
                            employeeId: this.queryObj.employeeId || this.$route.query.employeeId,
                            auditNo: this.queryObj.slipBatchNo || this.$route.query.slipBatchNo,
                            auditSort:2,
                            remark: values['reason'],
                            phone: this.userInfo.phone
                        }).then((res) => {
                            this.loading = false;
                            if (res.success) {
                                this.$message.success('操作成功', 2, () => {
                                    this.permission = false;
                                    this.queryInfo();
                                });
                            } else {
                                this.$message.warning(res.message);
                            }
                        });
                    }
                })
            },
            showModal() {
                this.permission = true;
                this.form.resetFields();
            },
            queryInfo() {
                postAction('/queryPayAcctAuditInfo', {
                    auditNo: this.queryObj.slipBatchNo || this.$route.query.slipBatchNo,
                    unionId: this.userInfo.unionid
                }).then((res) => {
                    if (res.success) {
                        this.info = res.data;
                        this.info.auditSort = '账户绑定审批'
                    } else {
                        this.$message.warning(res.message);
                    }
                })
            }
        },
        mounted() {
            if (!this.$route.query.companyId || !this.$route.query.batchNo || !this.$route.query.employeeId) {
                let queryString = decodeURI(window.location.href.split('?')[1].replace(/%3F/g, '&').replace(/%3D/g, '='));
                queryString.split('&').forEach((str) => {
                    this.queryObj[str.split('=')[0]] = str.split('=')[1];
                });
            }

            this.userInfo = getUserInfo();
            this.queryInfo();
        }
    }
</script>
<style lang="less" scoped>
    article {
        width: 100%;
        height: 100%;

        header {
            line-height: 45px;
            padding-left: 15px;
            background: white;
        }

        section {
            margin: 15px;
            max-height: calc(100% - 125px);
            overflow-y: scroll;
            .item {
                display: flex;
                /*justify-content: space-between;*/
                background: white;
                line-height: 40px;
                margin-bottom: 1px;
                padding: 0 10px;

                .title {
                    display: inline-block;
                    width: 80px;
                    font-size: 12px;
                    color: #666666;
                    text-align: right;
                }

                .value {
                    width: 80%;
                    display: inline-block;
                    padding-left: 10px;
                }
            }
        }

        footer {
            width: 100%;
            position: absolute;
            bottom: 10px;

            button {
                width: 46%;
                margin: 0 2%;
                line-height: 40px;
                height: 40px;
            }
        }
    }
</style>