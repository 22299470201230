<template>
  <div>
    <van-pull-refresh
      v-model="isRefresh"
      @refresh="onRefresh"
    >
      <template #pulling>
        <div class="refresh-text">下拉刷新</div>
      </template>
      <template #loosing>
        <div class="refresh-text">释放刷新</div>
      </template>
      <template #loading>
        <div class="refresh-text">加载中...</div>
      </template>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        @load="getList"
      >
      <a-row v-if="this.securityList.length === 0 && commonDeatail == {}" style="background: white; padding: 10px; margin: 9.6px 9.6px;">暂无数据</a-row>
      <div v-else>
        <div  v-for="(item, index) in securityList" :key="index" 
          class="social-item"
        >
          <div class="item-header">
            <div class="item--left">
              <img :src="require(`../../assets/socialSecurity/${item.imgName}`)" alt="" />
              {{item.socialSecurityType}}
            </div>
            <div style="color:#9ba5aa;font-size:14.4px">{{item.socialBillTime}}</div>
          </div>
          <div class="item-line"></div>
          <a-row
            type="flex"
            align="middle"
            justify="space-between"
            class="item-foot"
          >
            <a-col :flex="1">
              <a-row type="flex" align="middle" justify="center"  class="tax-item__col">
                <span>{{item.socialSecurityBase}}</span>
                <span class="tax-item__col-text">缴纳基数/比例</span>
              </a-row>
            </a-col>
            <a-col :flex="1">
              <a-row type="flex" align="middle" justify="center"  class="tax-item__col">
                <span>{{item.socialCompanyRatio}}</span>
                <span class="tax-item__col-text">比例(单位)</span>
              </a-row>
            </a-col>
            <a-col :flex="1">
              <a-row type="flex" align="middle" justify="center"  class="tax-item__col">
                <span>{{item.socialCompanyMoney}}元</span>
                <span class="tax-item__col-text">金额(单位)</span>
              </a-row>
            </a-col>
            <a-col :flex="1">
              <a-row type="flex" align="middle" justify="center"  class="tax-item__col">
                <span>{{item.socialPersonRatio}}</span>
                <span class="tax-item__col-text">比例(个人)</span>
              </a-row>
            </a-col>
            <a-col :flex="1">
              <a-row type="flex" align="middle" justify="center"  class="tax-item__col">
                <span>{{item.socialPersonMoney}}元</span>
                <span class="tax-item__col-text">金额(个人)</span>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <!-- 公积金 -->
        <div class="social-item" v-if="commonDeatail != {}">
          <div class="item-header">
            <div class="item--left">
              <img :src="require(`../../assets/socialSecurity/gongjijin.png`)" alt="" />
              {{commonDeatail.socialSecurityType}}
            </div>
            <div style="color:#9ba5aa;font-size:14.4px">{{securityList.length > 0 ? securityList[0].socialBillTime : ''}}</div>
          </div>
          <div class="item-line"></div>
          <a-row
            type="flex"
            align="middle"
            justify="space-between"
            class="item-foot"
          >
            <a-col :flex="1">
              <a-row type="flex" align="middle" justify="center"  class="tax-item__col">
                <span>{{commonDeatail.commonFundBase}}</span>
                <span class="tax-item__col-text">缴纳基数/比例</span>
              </a-row>
            </a-col>
            <a-col :flex="1">
              <a-row type="flex" align="middle" justify="center"  class="tax-item__col">
                <span>{{commonDeatail.commonCompanyRatio}}</span>
                <span class="tax-item__col-text">比例(单位)</span>
              </a-row>
            </a-col>
            <a-col :flex="1">
              <a-row type="flex" align="middle" justify="center"  class="tax-item__col">
                <span>{{commonDeatail.commonCompanyMoney}}元</span>
                <span class="tax-item__col-text">金额(单位)</span>
              </a-row>
            </a-col>
            <a-col :flex="1">
              <a-row type="flex" align="middle" justify="center"  class="tax-item__col">
                <span>{{commonDeatail.commonPersonRatio}}</span>
                <span class="tax-item__col-text">比例(个人)</span>
              </a-row>
            </a-col>
            <a-col :flex="1">
              <a-row type="flex" align="middle" justify="center"  class="tax-item__col">
                <span>{{commonDeatail.commonPersonMoney}}元</span>
                <span class="tax-item__col-text">金额(个人)</span>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </div>

      </van-list>
    </van-pull-refresh>

  </div>
</template>

<script>
  import {getUserInfo, setUserInfo, getCurrentCompany} from '@/utils/storage';
  import {postAction} from '@/api/manage';

export default {
  data() {
    return {
      isRefresh: false,
      loading: false,
      finished: true,
      list: [], // 数据列表
      total: 0, // 数据总数
      employeeId: '',
      time: "",
      commonDeatail:{},
      securityList: [],
      listQuery: {
        page: 1,
        num: 10
      } // 请求分页参数
    }
  },
  created() {
    document.title= "社保缴费明细";
    this.time = this.$route.query.time;
    this.employeeId = this.$route.query.employeeId;
  },
  mounted() {
    this.getList();
  },
  methods: {
    onRefresh() {
      this.isRefresh = true
      this.getList()
    },
    getList() {
      let info = getUserInfo() || {};
      let employeeId = info.employeeId
      let params = {
        time: this.time,
        employeeId: this.employeeId
      };
      postAction('/security/detail', params).then((res) => {
        this.isRefresh = false;
        this.commonDeatail = res.data.commonDeatail
        if (res.success) {
          if(res.data.socialList.length > 0) {
            this.securityList = res.data.socialList.map(item=>{
              item.imgName = this.changeList(item.socialSecurityType);
              return item
            });
          }
        }
        if(res.code===510){
            this.$message.warning(res.message)
        }
      })
    },
    changeList(val) {
      switch (val) {
        case "养老保险":
          return 'yanglao.png'
          break;
        case "医疗保险":
          return 'yiliao.png'
          break;
        case "生育保险":
          return 'shengyu.png'
          break;
        case "工伤保险":
          return 'gongshang.png'
          break;
        case "失业保险":
          return 'shiye.png'
          break;
        default:
          return "zidingyi.png"
          break;
      }
    }
  }
}
</script>
<style lang="less" scope>
@import '../../assets/less/theme.less';

.refresh-text {
  font-size: 9.6px;
  text-align: center;
  line-height: 25.6px;
}
.query-btn {
  color: @theme-color;
  padding: 12.8px 9.6px;
}
.social-item {
  background: #fff;
  border-radius: 4px;
  margin: 9.6px 9.6px 0;
  &__col {
    flex-direction: column;
    font-size: 16px;
    color: #354b59;
  }
  .item-line {
    height: 1px;
    border-bottom: rgba(0, 0, 0, 0.3) 0.5px solid;
  }
  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18.08px;
    // border-bottom: 1px solid #354b59;
    padding: 9.6px 8px;
    .item--left {
      display: flex;
      align-items: center;
      font-weight: 500;
    }
    img {
      margin-right: 5px;
      width: 18px;
      height: 18px;
    }
  }
  .item-foot {
    padding: 9.6px 8px;
  }
  .programme {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #f57a00;
    img {
      width: 15px;
      height: 15px;
      margin-right: 2px;
    }
  }
}
.tax-item {
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 9.6px 20px;
  margin: 0 9.6px 9.6px;

  &__col {
    flex-direction: column;
    font-size: 16px;
    color: #354b59;

    &:nth-child(2) {
      position: absolute;
      top: 9.6px;
      left: 50%;
      transform: translateX(-50%);
    }

    &-text {
      font-size: 9.6px;
      color: #9ba5aa;
    }
  }
}
</style>