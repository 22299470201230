<template>
  <div>
    <a-row type="flex" justify="space-between" align="middle">
      <div class="query-btn">
        <!-- <i></i> -->
          <!-- <router-link :to="{ name: 'TaxDetail' }">
             <span>政策查询</span>
          </router-link> -->
      </div>
      <div class="chooseYear" @click="isShow=true">
        <span>{{selectYear}}年</span>
        <img src='../../assets/calendar2.png' alt="" class="calendarIcon">
      </div>
    </a-row>
    <van-pull-refresh
      v-model="isRefresh"
      @refresh="onRefresh"
    >
      <template #pulling>
        <div class="refresh-text">下拉刷新</div>
      </template>
      <template #loosing>
        <div class="refresh-text">释放刷新</div>
      </template>
      <template #loading>
        <div class="refresh-text">加载中...</div>
      </template>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        @load="getList"
      >
        <a-row v-if="this.taxList.length === 0" style="background: white; padding: 10px; margin: 0 10px;">暂无数据</a-row>
        <a-row
          v-else
          type="flex"
          align="middle"
          justify="space-between"
          v-for="(item, index) in taxList"
          :key="index"
          class="tax-item"
          @click.native="$router.push({
            name: 'TaxRecordDetail',
            params: { recordId: index },
            query: {time: item.taxIncomeDate, employeeId: employeeId },
          })"
        >
          <a-row type="flex" align="middle" justify="center" class="tax-item__col">
            <span>{{item.taxIncomeDate}}</span>
            <span class="tax-item__col-text">缴费日期</span>
          </a-row>
          <a-row type="flex" align="middle" justify="center" class="tax-item__col">
            <span>{{item.employeeIncome||'0.00'}}</span>
            <span class="tax-item__col-text">本月收入</span>
          </a-row>
          <a-row type="flex" align="middle" justify="center" class="tax-item__col">
            <span>{{item.totalActualPay}}</span>
            <span class="tax-item__col-text">本月预扣预缴税额</span>
          </a-row>
        </a-row>
      </van-list>
    </van-pull-refresh>

    <div id="salaryChoose" :class="{chooseYearPanel: true, show: isShow}">
      <div class="yearPanel">
          <div v-for="year in yearList" :key="year" :class="{yearItem: true, current: selectYear===year}"
            @click="changeYear(year)">{{year}}
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getUserInfo, setUserInfo, getCurrentCompany} from '@/utils/storage';
  import {postAction} from '@/api/manage';

export default {
  data() {
    return {
      isRefresh: false,
      loading: false,
      finished: true,
      list: [], // 数据列表
      total: 0, // 数据总数
      employeeId: '',
      taxList: [],
      yearList: [],
      selectYear: new Date().getFullYear(),
      isShow: false,
      listQuery: {
        page: 1,
        num: 10
      } // 请求分页参数
    }
  },
  created() {
    document.title= "个税缴费记录";
    let info = getUserInfo() || {};
    this.employeeId = info.employeeId;
  },
  mounted() {
    this.initYearList();
    this.getList();
  },
  methods: {
    changeYear(year) {
      this.selectYear = year;
      this.isShow = false;
      this.getList();
    },
    initYearList() {
      this.yearList = [];
      let current = new Date().getFullYear() ; 
      for (var year = current; year > current - 10; year--) {
          this.yearList.push(year);
      }
    },
    onRefresh() {
      this.isRefresh = true
      this.getList()
    },
    getList() {
      let info = getUserInfo() || {};
      let employeeId = info.employeeId
      let params = {
        year: this.selectYear,
        companyId: this.employeeId,
      };
      postAction('/personal/query', params).then((res) => {
        this.isRefresh = false;
        if (res.success) {
            this.taxList = res.data;
        }
        if(res.code===510){
            this.$message.warning(res.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .chooseYear {
      top: 15px;
  }
  .chooseYear {
  //  position: absolute;
  //  top: 0;
    margin-right: 9.6px;
    display: flex;
    align-items: center;
    right: 18px;
  //  font-size: 24px;
    color: #1990FF;
    span {
      margin-right: 5px;
    }

    img {
      width: 12.8px;
      vertical-align: text-top;
    }
  }

  .chooseYearPanel {
    position: absolute;
    //bottom: -320px; */
    top: 64px;
    right: 10px;
    width: 80px;
    text-align: center;
    background: white;
    transition: all 0.5s;
    opacity: 0;
    border: 1px solid #dfdfdf;
    height: 0;
    overflow: hidden;

    &.show {
      opacity: 1;
      height: 160px;
      overflow: auto;
    }
  }

  .yearItem {
    line-height: 38.4px;

    &.current {
      color: white;
    }
  }
  .yearItem.current {
    background: #ffba00;
  }
  .yearPanel {
    overflow-y: auto;
    padding: 10px 0;
    -webkit-overflow-scrolling: touch;
  }

</style>
<style lang="less" scope>
@import '../../assets/less/theme.less';

.refresh-text {
  font-size: 9.6px;
  text-align: center;
  line-height: 25.6px;
}
.query-btn {
  color: @theme-color;
  padding: 12.8px 9.6px;
}
.tax-item {
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 9.6px 20px;
  margin: 0 9.6px 9.6px;

  &__col {
    flex-direction: column;
    font-size: 16px;
    color: #354b59;

    &:nth-child(2) {
      position: absolute;
      top: 9.6px;
      left: 50%;
      transform: translateX(-50%);
    }

    &-text {
      font-size: 9.6px;
      color: #9ba5aa;
    }
  }
}
</style>