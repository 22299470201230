<template>
    <div>
        <div class="container">
            <div class="text">请输入绑定的手机号获取的验证码进行身份确认。</div>

            <div class="verifyWrapper">
                <a-form :form="form" class="verifyForm">
                    <div class="formLeft">
                        <div>验证码</div>
                        <a-form-item>
                            <a-input
                                placeholder="请输入验证码"
                                :maxLength="6"
                                v-model="verificationCode"
                            />
                        </a-form-item>
                    </div>
                    <div class="formRight" @click="getCaptcha">{{count === 60 ? '获取验证码' : count + 's重新发送'}}</div>
                </a-form>
            </div>
        </div>

        <div class="btnWrapper">
            <a-button :disabled="!isAble" @click="openAccount">下一步</a-button>
        </div>

        <password ref="password" title="请设置支付密码" :body-style="pwdBodyStyle" :path-name="pathName"></password>

    </div>
</template>

<script>

    import password from "./components/password";

    export default {
        name: 'VerifySMS',
        description: '这是身份验证页面',
        components: {
            password,
        },
        data() {
            return {
                form: this.$form.createForm(this),
                verificationCode: undefined,
                waiting: false,
                timer: null,
                count: 60,
                pwdBodyStyle: {
                    padding: '0',
                    textAlign: 'center',
                    background: '#f0f2f5',
                },
            }
        },

        // 计算 isAble 的值来控制按钮的状态
        computed: {
            isAble() {
                return this.validateCode()
            },
            pathName() {
                // console.log('this.$route.params.name', this.$route.params.name)
                return this.$route.params.name
            },
        },

        methods: {
            // 获取验证码
            getCaptcha() {
                if (!this.timer) {
                    if (this.waiting) {
                        return false;
                    }
                    this.waiting = true;
                    this.timer = setInterval(() => {
                        if (this.count >= 1) {
                            this.count--;
                        } else {
                            clearInterval(this.timer);
                            this.timer = null;
                            this.count = 60;
                            this.waiting = false;
                        }
                    }, 1000);
                }
            },

            // 校验验证码
            validateCode() {
                if( this.verificationCode && /^\d{6}$/.test(this.verificationCode)) {
                    return true
                } else {
                    return false
                }
            },

            // 开户
            openAccount() {
                this.form.validateFields((err, values) => {
                    if(!err) {
                        if(this.$route.params.name == 'OpenAccount-AddBankCard') { // 如果是由开户页面然后新增银行卡页面跳转过来，则返回到开户页面
                            this.$router.push({
                                name: 'OpenAccount',
                                params: this.$route.params
                            })
                        } else if (this.$route.params.name == 'BankCardManage') {
                            this.$router.push({
                                name: 'BankCardManage',
                                params: this.$route.params
                            })
                        } else if(this.$route.params.name == 'OpenAccount-VerifyPwd') {
                            this.$refs.password.showPwd = true
                        }
                    }
                })
            },
        },
    }
</script>

<style lang="less" scoped>

    @import "less/product.less";

    .container {
        .text {
            margin: 8px 0 16px 12px;
            font-size: 12px;
            color: #CAA224;
        }

        .verifyWrapper {
            padding: 8px;
            background: #FFFFFF;
            border-radius: 4px;
            .verifyForm {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .formLeft {
                    width: 70%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .ant-form-item {
                        margin-top: 0;
                        margin-bottom: 0;
                        .ant-input {
                            text-align: right;
                            border: none;
                        }
                        .ant-input:focus {
                            box-shadow: none;
                        }
                        /deep/ .ant-form-explain {
                            position: absolute;
                            top: 64px;
                        }

                    }
                }
                .formRight {
                    padding: 4px 0 4px 20px;
                    border-left: 1px solid #D7DCE4;
                    color: #FFBA00;
                }
            }
        }
    }
</style>
