<template>
    <div>
        {{companyInfo}}
    </div>
</template>
<script>
    import {postAction} from '@/api/manage';
    export default {
        data() {
            return {
                employeeId: '',
                companyInfo: {}
            }
        },
        methods: {
            queryCompanyInfo() {
                if (this.employeeId) {
                    postAction('/EmployeeQuery', {employeeId: this.employeeId}, {
                        'contentType': 'application/x-www-form-urlencoded'
                    }).then((res) => {
                        if (res.success) {
                            this.companyInfo = res.data;
                        } else {
                            this.$message.warning(res.message);

                        }
                    });
                }
            }
        },
        mounted() {
            this.employeeId = this.$route.query.employeeId;
            this.queryCompanyInfo();
        }
    }
</script>
<style>

</style>