<template>
  <div class="container">
    <!-- <h3 class="title">累计输入与扣除信息</h3> -->
    <div class="cell-hander">
      <cell class="personName" :title="securityPersonList.personName" :val="securityPersonList.companyName" />
      <cell title="参保城市:" :val="securityPersonList.cityName" />
      <cell title="社保方案名称:" :val="socialSecurityName" />
      <cell title="公积金方案名称:" :val="commonFundMes.commonFundName" />
    </div>
    <h4>社保/公积金</h4>
    <a-row v-if="this.socialList.length === 0 && commonFundMes == {} " style="background: white; padding: 10px; margin: 20px 10px;">暂无数据</a-row>
    <div v-else>
      <div class="cell-box" v-for="(item, index) in socialList" :key="index">
        <div class="box-title">
          <img :src="require(`../../assets/socialSecurity/${item.imgName}`)" alt="">
          <div>{{item.socialSecurityType}}</div>
        </div>
        <div class="cell-node">
          <cell title="缴纳起始月:" :val="item.socialSecuritySmonth" />
          <cell title="基数类型:" :val="item.socialBaseType" />
          <cell title="单位缴纳比例:" :val="item.socialCompanyRatio"  />
          <cell title="个人缴纳比例:" :val="item.socialPersonRatio"  />
          <cell title="缴纳基数/比例:" :val="item.socialSecurityBase" :border="false" />
        </div>
      </div>

      <!-- 公积金 -->
      <div class="cell-box" v-if="commonFundMes != {}" >
        <div class="box-title">
          <img :src="require(`../../assets/socialSecurity/gongjijin.png`)" alt="">
          <div>{{commonFundMes.socialSecurityType}}</div>
        </div>
        <div class="cell-node">
          <cell title="缴纳起始月:" :val="commonFundMes.commonFundSmonth" />
          <cell title="基数类型:" :val="commonFundMes.commonBaseType" />
          <cell title="单位缴纳比例:" :val="commonFundMes.commonCompanyRatio" />
          <cell title="个人缴纳比例:" :val="commonFundMes.commonPersonRatio" />
          <cell title="缴纳基数/比例:" :val="commonFundMes.commonFundBase" :border="false" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Cell from './components/Cell'
import {getUserInfo, setUserInfo, getCurrentCompany} from '@/utils/storage';
import {postAction} from '@/api/manage';
export default {
  components: {
    Cell
  },
  props: {
    recordId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      time: '',
      employeeId: '',
      securityPersonList: {},  // 参保人/城市/公司
      socialList:[],     // 社保
      commonFundMes: {}, // 公积金
      socialSecurityName: '',  // 社保方案名称
    }
  },
  created() {
    document.title= "社保方案查询";
  },
  mounted() {
    this.time = this.$route.query.time;
    this.employeeId = this.$route.query.employeeId;
    this.getData();
  },
  methods: {
    getData() {
      let params = {
        time: this.time,
        employeeId: this.employeeId
      };
      postAction('/security/plan', params).then((res) => {
        if (res.success) {
          this.securityPersonList = res.data.securityPerson;
          this.commonFundMes = res.data.commonFundMes;
          if(res.data.socialList.length > 0) {
            this.socialSecurityName = res.data.socialList[0].socialSecurityName;
            this.socialList = res.data.socialList.map(item=>{
              item.imgName = this.changeList(item.socialSecurityType);
              return item
            });
          }
        }
        if(res.code===510){
          this.$message.warning(res.message)
        }
      })
    },
    changeList(val) {
      switch (val) {
        case "养老保险":
          return 'yanglao.png'
          break;
        case "医疗保险":
          return 'yiliao.png'
          break;
        case "生育保险":
          return 'shengyu.png'
          break;
        case "工伤保险":
          return 'gongshang.png'
          break;
        case "失业保险":
          return 'shiye.png'
          break;
        default:
          return "zidingyi.png"
          break;
      }
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  background: #f5f5f5;
  padding: 9.6px;
  h4 {
    margin-bottom: -10px;
  }
  ::v-deep.personName {
    .label {
      color: #000;
      font-size: 16px;
    }
  }
  .cell-hander {
    margin: 9.6px 0;
  }
  .cell-box {
    margin-top: 15px;
    .cell-node {
      background: #fbfafa; 
      padding-left: 32px;     
    }
  }
  .box-title {
    display: flex;
    
    align-items: center;
    background: #fff;
    padding: 20px 16px;
    font-size: 16px;
    border-bottom: 1px solid #d8dde5;
    img {
      width: 19.2px;
      height: 19.2px;
      margin-right: 5px;
    }
  }
}
</style>