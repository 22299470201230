<template>
    <a-modal
        v-model="showPwd"
        :footer="null"
        :closable="false"
        :bodyStyle="bodyStyle"
    >
        <div class="checkPwdPanel">
            <div style="padding-top: 8px">{{title}}</div>
            <div>{{text}}</div>
            <div class="pwdPanel">
                <div class="pwdList">
                    <span>{{queryPwd[0]}}</span><span>{{queryPwd[1]}}</span><span>{{queryPwd[2]}}</span>
                    <span>{{queryPwd[3]}}</span><span>{{queryPwd[4]}}</span><span>{{queryPwd[5]}}</span>
                </div>
                <a-input :value="pwd" class="pwdInput" type="password" @click="keybdshow"></a-input><!--id="checkwd" @click="keybdshow($event)" -->
            </div>
        </div>

        <a-button v-if="isConfirm" :disabled="this.pwd.length < 6" @click="confirmPwd" class="completeBtn">完成</a-button>

        <div class="keyboard" v-show="keyShow" :style="keyStyle">
            <a-row class="keyword">
                <a-col :span="8" @click="inputNum('1')">1</a-col>
                <a-col :span="8" @click="inputNum('2')">2</a-col>
                <a-col :span="8" @click="inputNum('3')">3</a-col>
            </a-row>
            <a-row>
                <a-col :span="8" @click="inputNum('4')">4</a-col>
                <a-col :span="8" @click="inputNum('5')">5</a-col>
                <a-col :span="8" @click="inputNum('6')">6</a-col>
            </a-row>
            <a-row>
                <a-col :span="8" @click="inputNum('7')">7</a-col>
                <a-col :span="8" @click="inputNum('8')">8</a-col>
                <a-col :span="8" @click="inputNum('9')">9</a-col>
            </a-row>
            <a-row>
                <a-col :span="8" style="background:none;border:none"></a-col>
                <a-col :span="8" @click="inputNum('0')">0</a-col>
                <a-col :span="8" style="background:none;border:none;color:#FFBA00" @click="delNum">X</a-col>
            </a-row>
        </div>
    </a-modal>
</template>
<script>

    export default {
        props: {
            title: {
                type: String,
                default: '标题',
            },
            text: {
                type: String,
                default: '',
            },
            bodyStyle: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            keyStyle: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            pathName: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                showPwd: false,
                pwd: '',
                keyShow: true,
                type: '',
                passwordArr: [],
                isConfirm: false,
            }
        },
        computed: {
            queryPwd() {
                let string = '';
                if (this.pwd) {
                    this.pwd.split('').forEach((item) => {
                        string += '•';
                    });
                }
                return string;
            },
        },
        methods: {

            show(type) {
                this.showPwd = true
                this.pwd = ''
                this.keyShow = true
                this.type = type
            },

            //点击调用键盘
            inputNum(num) {
                if (this.pwd.length < 6) {
                    this.pwd = this.pwd + num;
                }

                if (this.pwd) {
                    if (this.pwd.length >= 6) {
                        let value = this.pwd;
                        this.pwd = value.slice(0, 6);
                        if(!this.isConfirm) {
                            this.sendAction(this.pathName, this.type);
                        } else {
                            this.keyShow = false; //隐藏键盘
                        }
                    }

                }
            },

            delNum: function () {
                this.pwd = this.pwd.substring(0, this.pwd.length - 1);
            },

            keybdshow(e) {
                document.activeElement.blur();
                // this.keyShow = true;
            },

            sendAction(pathName, type) {
                if(type != 'modify') {
                    if(pathName == 'ProductBuy') { // 产品购买成功后跳转交易记录页面
                        this.$router.push({
                            path: '/product/paySuccess'
                        })
                    } else if(pathName == 'OpenAccount-VerifyPwd') { // 开户设置密码成功后跳转开户页面
                        this.$router.push({
                            path: '/product/successAccount'
                        })
                    } else if(pathName == 'ProductRedeem') {
                        this.$router.push({
                            path: '/product/confirmRedeem'
                        })
                    }
                    this.isConfirm = false
                    this.showPwd = false
                } else {
                    // console.log('执行了几次')
                    this.isConfirm = true
                    this.passwordArr.push(this.pwd)
                    this.title = '设置支付密码'
                    this.text = '请设置微信支付密码，用于支付验证'
                    this.pwd = ''
                }
            },

            confirmPwd() {
                // console.log('this.passwordArr', this.passwordArr)
                if(this.passwordArr.length === 2 && this.passwordArr[0] === this.passwordArr[1]) {
                    this.showPwd = false
                } else {
                    this.$message.warning('')
                }
            },
        },
    }
</script>
<style lang="less" scoped>
    .pwdList {
        display: flex;
        justify-content: center;
        z-index: 1;
        span {
            display: inline-block;
            width: 48px;
            height: 48px;
            line-height: 48px;
            background: white;
            margin: 15px 5px 0;
        }
    }

    .pwdInput {
        width: calc(288px + 50px);
        height: 48px;
        line-height: 48px;
        border: none;
        background: red;
        position: relative;
        top: 48px;
        opacity: 0;
        font-size: 12px;
    }

    .keyboard {
        width: 100%;
        bottom: 0;
        .ant-col-8 {
            height: 80px;
            line-height: 80px;
            background: #fff;
            font-size: 20px;
            color: #4C677F;
            border-bottom: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;
        }

        .ant-col-8:focus, .ant-col-8:active {
            background: #FFBA00;
            color: #fff;
            transition: all 0.2s;
        }
    }

    .ant-btn {
        height: auto;
        padding: 16px 0;
        width: 100%;
        font-size: 16px;
        color: #FFFFFF;
        background: #FFBA00;
        border: none;
        border-radius: 0;
    }

    .ant-btn[disabled] {
        color: #FFFFFF;
        background: #A5A5A5;
    }
</style>

<style>
    @import "../less/step.less";

    .ant-modal-content {
        height: 100%;
    }
</style>