<template>
    <article>
        <div class="big_box">
            <div class="headerPanel">
                <div class="chooseYear" @click="isShow=true">
                    <img src='../../assets/calendar.png' alt="" class="calendarIcon">
                    <span>{{selectYear}}年</span>
                </div>
                <div class='amountPanel'>
                    <div class='amount rightDivider'>
                        <a-tooltip trigger='click' :title="infoData.sumCount | formatNull">
                            <div class='numbert'>
                                <span>{{infoData.sumCount | formatNull}}</span> 条
                            </div>
                        </a-tooltip>
                        <span>单据总数</span>
                    </div>
                    <div class='amount rightDivider leftDivider'>
                        <a-tooltip trigger='click' :title="infoData.auditCount | formatNull">
                            <div class='numbert'>
                                <span>{{infoData.auditCount | formatNull}}</span> 条
                            </div>
                        </a-tooltip>
                        <span>已审批</span>
                    </div>
                    <div class='amount leftDivider'>
                        <a-tooltip trigger='click' :title="infoData.noAuditCount | formatNull">
                            <div class='numbert'>
                                <span>{{infoData.noAuditCount | formatNull}}</span> 条
                            </div>
                        </a-tooltip>
                       <span>未审批</span>
                    </div>
                </div>
            </div>  

            <div class="roleListInfo">
                <div class="roleListBox" v-for="(item,index) in infoData.auditList" :key=index>
                    <div class="roleListTop">
                        <div>{{item.year}}年{{item.month}}月份工资条</div>
                        <div>
                            <span>状态：</span>
                            <!-- <span :class="{'c3':item.taskStatus[0] == '02','c2':item.taskStatus[0] == '05','c1':item.taskStatus[0] != '05'&&item.taskStatus[0] != '02' }">{{item.status}}</span> -->
                            <span 
                            :class="{
                                'c3':item.status == '审批失败',
                                'c2':item.status == '审批通过',
                                'c1':item.status == '审批中' }">{{item.status}}</span>
                        </div>
                    </div>
                    <div class="roleInfoContent">
                        <div class="roleInfoContent_box">
                          <div class="roleInfo_data">
                             <div>{{item.totalAmount}}元</div> 
                             <span>发放总金额</span> 
                             <span class="riDivider"></span>
                          </div>
                          <div class="roleInfo_data">
                             <div>{{item.totalCount}}笔</div> 
                             <span>发放总笔数</span> 
                             <span class="riDivider"></span>
                          </div>
                          <div class="roleInfo_data">
                             <div>{{item.applyerName}}</div> 
                             <span>提交人</span> 
                          </div>
                        </div>
                        <div class="listCont" @click="jump(item.companyId,item.auditNo,item.applyer)">详情</div>
                    </div>
                    <div class="roleTime">提交时间：{{item.applyTime}}</div>
                </div>
            </div>
            
        </div>

        <div id="salaryChoose" :class="{chooseYearPanel: true, show: isShow}">
            <div class="yearPanel">
                <div v-for="year in yearList" :key="year" :class="{yearItem: true, current: selectYear===year}"
                     @click="changeYear(year)">{{year}}
                </div>
            </div>
        </div>
    </article>
</template>
<script>
    import {postAction} from "@/api/manage";
    export default {
        data() {
            return {
              selectYear:new Date().getFullYear(),
              yearList: [],
              isShow:false,
              shType:0,
              infoData:[],
            }
        },
        methods: {
           initYearList() {
                this.yearList = [];
                let current = new Date().getFullYear() ; 
                for (var year = current; year > current - 10; year--) {
                    this.yearList.push(year);
                }
           },
           changeYear(year) {
               this.selectYear = year;
               this.isShow = false;
               this.listData();
           },
           jump(cId,auditNo,aid){
               this.$router.push({
                   path:'/approveInfo',
                   query:{
                       companyId:cId,
                       slipBatchNo:auditNo,
                       employeeId:aid,
                   }
               })
           },
           listData(){
               postAction('/salary/audit/query',{year:this.selectYear}).then((res) => {
                //    console.log(res,'/salary/audit/quer')
                    if (res.success) {
                        this.infoData = res.data
                        if(this.infoData.auditList){
                         this.infoData.auditList.forEach((item)=>{
                             if(item.month.substring(0,1) == '0'){
                                item.month = item.month.substring(1,2)
                             }
                         })
                        }
                       
                    } else {
                        this.$message.warning(res.message);
                    }
                });
           }
        },
        filters:{
            formatNull:function(val){
                if(val==null){
                    return 0;
                }else{
                    return val;
                }
            }
        },
        mounted() {
            // let test = 123123123
            // console.log(test.toLocaleString(),'????????????????????????');
            this.listData();
            this.initYearList();
        }
    }
</script>
<style  lang="less" scoped>
  .big_box{
      width: 100%;
      box-sizing: border-box;
      padding:10px;
      .headerPanel{
          width: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          padding: 16px 0;
          background-image: url('../../assets/approve/list_bg.png');
          border-radius: 4.8px;
      }
      .amountPanel {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 24px 0 8px;
      }
      .amount {
        width: 50%;
        text-align: center;
        color: #fff;
      }
      .rightDivider {
        border-right: 1px solid #fff;
      }
      .leftDivider{
          border-left:1px solid #fff;
          margin-left: 2px;
      }
      .roleListInfo{
          margin-top: 10px;
          .roleListBox{
              width:100%;
              box-sizing: border-box;
              padding: 10px 15px;
              background-color:#fff;
              margin-bottom: 10px;
              border-radius: 4.8px;
              .roleListTop{
                  width: 100%;
                  display: flex;
                  font-size: 12px;
                  color:#8e8e92;
                  justify-content: space-between;
                  .c1{
                      color:#53abef;
                  }
                  .c2{
                      color:#42c99e;
                  }
                  .c3{
                      color:red;
                  }
              }
          }
          .roleInfoContent{
              width: 100%;
              position: relative;
              .roleInfoContent_box{
                  width: 90%;
                  display:flex;
                  justify-content: center;
                  margin: 24px 0;
                  font-size:12px;
                  .roleInfo_data{
                       width: 50%;
                       text-align: center;
                       position: relative;
                       span{
                           color:#8e8e92;
                       }
                       .riDivider{
                         width: 1px;
                         position: absolute;
                         height: 80%;
                         top: 50%;
                         right: 0;
                         transform: translate(0,-50%);
                         background-color: #8e8e92;
                       }
                  }
              }
              .listCont{
                  width:40px;
                  height: 24px;
                  line-height: 24px;
                  text-align: center;
                  background-color: #f57a00;
                  font-size: 12px;
                  position: absolute;
                  top: 50%;
                  right: -15px;
                  transform: translate(0,-50%);
                  color: #fff;
                  border-radius: 4.8px 0 0 4.8px;
              }
              
          }
          .roleTime{
              font-size:12px;
              color:#8e8e92;
              text-align: right;
          }
      }
  }
  .chooseYear {
    position: absolute;
    top: 15px;
    right: 18px;
    font-size: 12px;
    color:#fff;
  
    span {
      margin-left: 5px;
    }
  
    img {
      width: 12.8px;
      vertical-align: text-top;
    }
  }
  .chooseYearPanel {
    position: absolute;
    top: 32px;
    right: 10px;
    width: 80px;
    text-align: center;
    background: white;
    transition: all 0.5s;
    opacity: 0;
    border: 1px solid #dfdfdf;
    height: 0;
    overflow: hidden;
  
    &.show {
      opacity: 1;
      height: 160px;
      overflow: auto;
    }
  }
</style>