<template>
  <div class="container">
    <h3 class="title">累计收入与扣除信息</h3>
    <div class="cell-box">
      <cell title="累计收入:" :val="caculationData.totalIncome" suffix="元" />
      <cell title="累计免税收入:" :val="caculationData.totalTaxFree" suffix="元" />
      <cell title="累计减除费用:" :val="caculationData.totalSubMoney" suffix="元" />
      <cell title="累计专项扣除:" :val="caculationData.totalSpecialMoney" suffix="元" />
      <cell title="累计专项附加扣除:" :val="caculationData.totalAddMoney" suffix="元" />
      <cell title="累计依法确定的其他扣除:" :val="caculationData.totalOtherMoney" suffix="元" />
      <!-- <cell title="累计应纳税所得额:" :val="caculationData.totalPayTax" val-color="#f57a00" :border="false" suffix="元" /> -->
      <cell title="累计应纳税所得额:" :val="caculationData.totalPayTax" val-color="#f57a00" :border="false" suffix="元" />
    </div>
    <h3 class="title">税款计算</h3>
    <div class="cell-box">
      <cell title="累计应缴纳所得额:" :val="caculationData.totalPayTax" suffix="元" />
      <cell title="税率/预扣率:" :val="caculationData.taxRate" />
      <cell title="速算扣除数:" :val="caculationData.taxQuickDeduction" suffix="元" />
      <cell title="累计应纳税额:" :val="caculationData.totalPayMoney" suffix="元" />
      <cell title="累计已预扣预缴税额:" :val="caculationData.totalBeforePay" suffix="元" />
      <cell title="累计减免税额:" :val="caculationData.totalFreeMoney" suffix="元" />
      <cell title="本期应预扣预缴税额:" :labelSpan="10" :val="caculationData.totalActualPay" suffix="元" val-color="#f57a00" :border="false" />
    </div>
  </div>
</template>

<script>
import Cell from './components/Cell'
import {getUserInfo, setUserInfo, getCurrentCompany} from '@/utils/storage';
import {postAction} from '@/api/manage';
export default {
  components: {
    Cell
  },
  props: {
    recordId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      time: '',
      employeeId: '',
      caculationData: {},
    }
  },
  created() {
    document.title= "税款计算";
  },
  mounted() {
    this.time = this.$route.query.time;
    this.employeeId = this.$route.query.employeeId;
    this.getData();
  },
  methods: {
    getData() {
      let params = {
        time: this.time,
        companyId: this.employeeId
      };
      postAction('/personal/calculate', params).then((res) => {
        if (res.success) {
            this.caculationData = res.data;
        }
        if(res.code===510){
            this.$message.warning(res.message)
        }
      })
    },
  },
}
</script>