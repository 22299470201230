<template>
  <div style="background: #fff">
    <a-row :class="{ cell: true, border: border }" type="flex" align="middle" justify="space-between">
      <a-col class="label" :span="labelSpan">{{ title }}</a-col>
      <a-col class="val" :span="24 - labelSpan" :style="{ color: valColor }">{{ val }}{{ suffix }}</a-col>
    </a-row>
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    val: {
      type: [String, Number],
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true
    },
    labelSpan: {
      type: Number,
      default: 8
    },
    valColor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scope>
.cell {
  background: #fff;
  padding: 20px 16px;
  &.border {
    border-bottom: 1px solid #d8dde5;
  }
  .label {
    color: #9daebe;
    font-size: 12.8px;
  }
  .val {
    font-size: 16px;
    line-height: 24px;
    text-align: right;
  }
}
</style>