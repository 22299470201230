<template>
    <div :class="{loginPanel: true, [stylesheetName]: true}">
        <a-carousel :autoplay="true">
            <div class="img" v-if="bannerList.length == 0"></div>
            <div v-for="(item,index) in bannerList" :key=index>
               <img :src="item.pictureUrl" alt="" class="adImg" @touchstart="touchstart" @touchmove="touchmove" @touchend="touchend(item)">
            </div>
        </a-carousel>
        <a-form class="formPanel" :form="form">
            <a-form-item>
                <a-input placeholder="请输入手机号" class="selectWidth" :maxLength=11 
                    v-decorator="['phone',{
                        rules: [{ required: true, message: '请输入手机号' },
                                { required: false, validator: (rule, value) => {
                                    if (value) {
                                        return /^1[3456789]\d{9}$/.test(value);
                                    } else {
                                        return true;
                                    }
                                },
                                message: '请输入正确的手机号'}]
                        }]"
                         @change="changePhone">
                </a-input>
            </a-form-item>
            <!-- <a-form-item>
                <a-input placeholder="请输入身份证号" class="selectWidth" :maxLength=18 v-decorator="[ 'idCard', idCardRules]"
                    @change="changeidCard">
                </a-input>
            </a-form-item> -->
            <a-form-item v-bind="formItemLayout">
                <a-row :gutter="8">
                    <a-col :span="16">
                        <a-input placeholder="验证码" :maxLength=6 
                                 v-decorator="['verificationCode', {
                                     rules: [{ required: true, message: '请输入验证码' },
                                     { required: false, validator: (rule, value) => {
                                        if (value) {
                                            return /^\d{6}$/.test(value);
                                        } else {
                                            return true;
                                        }
                                    },
                                    message: '验证码应为六位数字'}] }]"/>
                    </a-col>
                    <a-col :span="8" class="getCaptcha" @click="getCaptcha">{{count === 60 ? '获取验证码' : count +
                        's后重新发送'}}
                    </a-col>
                </a-row>
            </a-form-item>
            <a-spin class="wxLoading" v-show="wxLoading"></a-spin>
            <a-button class="submitBtn" type="primary" :loading="loading" :disabled="loading" @click="bindUser">{{loading ? '登录中' : '登录'}}</a-button>
        </a-form>
    </div>
</template>
<script>
    import {setLocalStore, getLocalStore , setUserInfo, getUserInfo, setCurrentCompany, setAccessToken} from '@/utils/storage';
    import {postAction} from '@/api/manage';

    export default {
        data() {
            return {
                waiting: false,
                stylesheetName: '',
                userInfo: {},
                labelCol: {span: 6},
                wrapperCol: {span: 18},
                form: null,
                wxLoading: false,
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 8},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 16},
                    },
                },
                timer: null,
                count: 60,
                phone: '',
                loading: false,
                bannerList:[],
                hasClick: false
            }
        },
        activated() {
            this.hasClick = false
        },
        methods: {
            bindUser() {
                this.form.validateFields((err, values) => {
                    if (!err) {
                        if (this.loading) {
                            return false;
                        }
                        if(this.count === 60){
                            this.$message.warning("请先获取验证码");
                            return
                        }
                        this.loading = true;

                        let postData = Object.assign({
                            unionid: this.userInfo.unionid,
                            mpOpenid: this.userInfo.mpOpenid
                        }, this.userInfo, values);
                        
                        postAction('/employee/bind', postData, {
                            'contentType': 'application/x-www-form-urlencoded'
                        }).then((response) => {
                            this.loading = false;
                            if (response.success) {
                                let respData = response.data;
                                let result = respData.resp;
                                setAccessToken(respData.token)
                                setLocalStore('bindData', JSON.stringify(result));
                                this.userInfo.bindStatus = '01';

                                Object.keys(result).forEach((key) => {
                                    if (typeof result[key] === 'string' || !result[key]) {
                                        this.userInfo[key] = result[key];
                                    }
                                });

                                this.userInfo.infoList = result.infoList || [];
                                let currentCompany = this.userInfo.infoList.find((item) => item.loginStatus === '01') || {};
                                this.userInfo.employeeId = currentCompany.employeeId;
                                setUserInfo(this.userInfo);
                                setCurrentCompany(currentCompany);

                                // 根据企业的配置，如果开启密码查询，引导用户去设置查询密码
                                if (currentCompany.passwordSwitch === '01') {
                                    this.setQueryPwd();
                                } else {
                                    this.$router.push({name: 'index'});
                                }
                            } else {
                                this.$message.warning(response.message);
                            }
                        });
                    }
                });
            },
            //模拟点击事件开始
            touchstart(){
                this.startTime = Date.now();
            },
            touchmove(){
                this.isMove = true;
            },
            touchend(img){
                if (!img.linkUrl) {
                    return false;
                }
                if (Date.now() - this.startTime < 150 && !this.isMove && !this.hasClick) {
                    let params = {
                        operationType:'0',
                        pictureIds:[img.id],
                        adName: '电子工资条登录页',
                        adId: img.adId
                    }
                    this.hasClick = true
                    postAction('/advertisement/setPictureFlow',params).then((res)=>{
                        if(res.success){
                            this.isMove = false;
                            window.location.href = img.linkUrl + '?timeStamp=' + (Date.now() + Math.random())
                        }else{
                            this.$message.error(res.message)
                        }
                    })
                }
            },
            changePhone(e) {
                this.phone = e.target.value;
            },
            getCaptcha() {
                this.form.validateFields(['phone'], {force: true}, (err, values) => {
                    if (!err) {
                        if (!this.timer) {
                            // 发送获取验证码请求
                            if (!this.phone) {
                                this.$message.warning('请输入手机号');
                                return false;
                            }
                            if (this.waiting) {
                                return false;
                            }
                            this.waiting = true;
                            postAction('/wechatuser/addVerificationCode', {
                                mpOpenid: this.userInfo.mpOpenid,
                                phone: this.phone,
                                bankId: this.userInfo.bankId
                            }, {
                                contentType: 'application/x-www-form-urlencoded'
                            }).then((res) => {   
                                if(res.success){
                                    this.$message.success(res.message)
                                    this.timer = setInterval(() => {
                                    if (this.count >= 1) {
                                        this.count--;
                                    } else {
                                        clearInterval(this.timer);
                                        this.timer = null;
                                        this.count = 60;
                                        this.waiting = false;
                                    }
                                    }, 1000);
                                }else{
                                    this.$message.error(res.message)
                                     this.waiting = false;
                                }
                            });
                        }
                    }
                })
            },
            setQueryPwd() {
                this.$router.push({
                    path: '/checkPwd',
                    query: {
                        method: 'set'
                    }
                });
            },
            async queryBannerInfo() {
                // type: 00：企业类型 01：薪资区间 02：全部企业
                // site: 企业操作台登录页（00）、企业操作台工作台（01）、电子工资条登录页（02）、电子工资条首页（顶部）（03）、电子工资条首页（底部）（04）
                // choose: 类型范围，type=02时不用传
                // 有图片返回defaultPicture，图片地址以“|"分隔；userPicture自定义图片，同上；defaultLinkUrl对应默认图片链接地址；userLinkUrl对应自定义图片链接地址；
                await postAction('/advertisement/getAdverts',{site: '02'}).then(res => {
                    // let obj = res.data;
                    if(res.success){
                        if(res.data){
                            let list = res.data
                            if (list.length) {
                                list.forEach((item,index)=>{
                                    list[index].pictureUrl = item.pictureUrl ? "/ngimg/"+item.pictureUrl : ''
                                })
                                this.bannerList = list
                                this.showImg(this.bannerList,'电子工资条登录页')
                            }
                        }
                        
                    }else{
                        this.$message.error(res.message)
                    }
                    // console.log(this.bannerList)
                })
            },
            //首页展示流水
            showImg(imgs,adName){
                let imgsId = []
                imgs.forEach((item,index)=>{
                    imgsId.push(item.id)
                })
                let params = {
                    operationType:1,
                    pictureIds:imgsId,
                    adId:imgs[0].adId,
                    adName:adName
                }
                postAction('/advertisement/setPictureFlow',params).then((res)=>{
                    if(res.success){
                    }else{
                        this.$message.warning(res.message)
                    }
                })
            },
        },
        created() {
            this.form = this.$form.createForm(this, {name: 'login_form'});
        },
        mounted() {
            this.userInfo = getUserInfo() || {};
            this.stylesheetName = this.userInfo.stylesheetName || 'red';
            this.queryBannerInfo();
        }
    }
</script>
<style lang="less" scoped>
    .loginPanel {
        // padding: 672px 0 0;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        /deep/ .slick-dots{
            top:288px;
        }
    }

    .formPanel {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding: 48px 15px 0;
        background: white;
        width:100%;
        position: absolute;
        top: 304px;
        left: 0;
    }

    .ant-input {
        background: none;
        border: none;
        border-bottom: 1px solid #D9E5EF;
        border-radius: 0;
    }

    .submitBtn {
        float: right;
        padding: 0 32px;
        height: 40px;
        line-height: 40px;
        border: none;
        &.disabled {
            color: rgba(0,0,0,.25);
            background-color: #f5f5f5;
            border-color: #d9d9d9;
        }
    }

    .getCaptcha {
        text-align: center;
    }

    .loginPanel {
        &.default {
            background-image: url(../../assets/img-login.jpg);
            .submitBtn {
                color: #D9E5EF;
                background: #1890FF;
            }
            .getCaptcha {
                color: #1890FF;
            }
        }
        &.red {
            // background-image: url(../../assets/img-login.png);
            .submitBtn {
                color: white;
                background: #f57a00;
            }
            .getCaptcha {
                color: #f57a00;
            }
        }
    }
    .img {
        width: 100%;
        height: 208px;
        background-image: url(../../assets/home/banner.png);
        background-size: cover;
        background-repeat: no-repeat;
    }
    .adImg{
        width: 100%;
        height: 336px;
    }
</style>