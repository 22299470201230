<template>
    <div id="userLayout" class="user-layout-wrapper">
        <div class="login">
            
            <route-view></route-view>

            <div class="footer">
                <div class="copyright">
                    Copyright &copy; 广东省农村信用社联合社出品
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import RouteView from "@/components/layouts/RouteView"

  export default {
      name: "UserLayout",
      components: { RouteView },
      data () {
        return {}
      },
      mounted () {
        document.body.classList.add('userLayout')
      },
      beforeDestroy () {
        document.body.classList.remove('userLayout')
      },
  }
</script>

<style lang="less" scoped>
    #userLayout.user-layout-wrapper {
        height: 100%;
        .login{
            background: #0EA4FD;
            padding: 110px 0;
              .footer{
                    margin-top:70px;
                    .copyright{
                        font-size: 14px;
                        color: #fff;
                        text-align: center;
                    }
              }
            }
      }
</style>