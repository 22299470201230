<template>
    <div>
        <div class="successContainer">
            <div class="successWrapper">
                <div class="head">
                    <i class="logoIcon"></i>
                    <div class="bank">盛京银行</div>
                </div>

                <div class="title">开户成功</div>
                <div class="dec">您已开通盛京银行II类账户</div>
                <div class="title">{{format('6224006610056229')}}</div>
            </div>

            <a-divider>您的专享权益</a-divider>

            <div class="itemWrapper">
                <div class="item-content">
                    <i class="itemIcon quickIcon"></i>
                    <div class="item-title">放款还款秒到账</div>
                </div>
                <div class="item-content">
                    <i class="itemIcon verbIcon"></i>
                    <div class="item-title">任意金额买理财</div>
                </div>
                <div class="item-content">
                    <i class="itemIcon moneyIcon"></i>
                    <div class="item-title">随心还款无限额</div>
                </div>
            </div>
        </div>

        <div class="btnWrapper" @click="toBuyProduct">返回</div>
    </div>
</template>

<script>

    export default {
        name: 'SuccessAccount',
        description: '这是开户成功页面',
        data() {
            return {

            }
        },

        methods: {
            // 将银行卡号每4位用空格隔开
            format(value) {
                return value.replace(/(.{4})/g, "$1 ")
            },

            // 跳转至购买页面
            toBuyProduct() {
                this.$router.push({
                    path: '/product/buy'
                })
            },
        }
    }
</script>

<style lang="less" scoped>
    .successContainer {
        margin: 8px;
        padding: 12px;
        background: #FFFFFF;
        .successWrapper {
            padding: 16px;
            background: linear-gradient(11deg, #FE5438 0%, #FEA664 98%);
            border-radius: 12px;
            .head {
                display: flex;
                align-items: center;
                .logoIcon {
                    width: 20px;
                    height: 20px;
                    background-image: url(../../assets/product/sj.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                .bank {
                    font-size: 12.8px;
                    color: #FFFFFF;
                }
            }
            .title {
                margin-top: 24px;
                text-align: center;
                font-size: 20px;
                color: #FFFFFF;
            }
            .dec {
                text-align: center;
                font-size: 14.4px;
                color: #E2E1E4;
            }
        }
        .ant-divider::before, .ant-divider::after {
            border-top: 1px solid #000000;
        }
        .itemWrapper {
            .item-content {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 32px;
                .item-title {
                    margin-left: 8px;
                    color: #797979;
                }
                .quickIcon {
                    background-image: url(../../assets/product/quick.png);
                }
                .verbIcon {
                    background-image: url(../../assets/product/verb.png);
                }
                .moneyIcon {
                    background-image: url(../../assets/product/money.png);
                }
                .itemIcon {
                    width: 24px;
                    height: 24px;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
            .item-content:first-child {
                margin-top: 24px;
            }
            .item-content:last-child {
                margin-bottom: 12px;
            }
        }
    }

    .btnWrapper {
        margin: 48px 8px 0;
        padding: 12px 0;
        text-align: center;
        color: #FFFFFF;
        background: #FFBA00;
        border-radius: 4px;
    }
</style>