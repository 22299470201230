<template>
    <div>
        <div class="container">
            <div class="dataWrapper">
                <div class="data-content">
                    <div class="rate">
                        <div class="value">6.06%</div>
                        <div class="title">年利率</div>
                    </div>
                    <div class="date">
                        <div class="value">一年</div>
                        <div class="title">投资期限</div>
                    </div>
                </div>

                <div class="dec-content">
                    <div class="dec-item">
                        <i class="depositIcon decIcon icon"></i>
                        <div class="title">50元起存</div>
                    </div>
                    <div class="dec-item">
                        <i class="interestIcon decIcon icon"></i>
                        <div class="title">当日起息</div>
                    </div>
                    <div class="dec-item">
                        <i class="riskIcon decIcon icon"></i>
                        <div class="title">低风险</div>
                    </div>
                </div>
            </div>

            <wrapper title="产品亮点">
                <div slot="content">
                    <div class="dec-content">
                        <div class="title">本息保障</div>
                        <div class="dec">银行存款产品，50万内本息受国家存款保险制度保障</div>
                    </div>
                    <div class="dec-content">
                        <div class="title">门款低，无手续费</div>
                        <div class="dec">低门槛起购，转入、支取没有任何费用</div>
                    </div>
                    <div class="dec-content">
                        <div class="title">当日起息</div>
                        <div class="dec">从当前日期开始计算利息</div>
                    </div>
                </div>
            </wrapper>

            <wrapper title="交易规则">
                <div slot="content">
                    <div class="trade-item trade">
                        <div class="title">购买时间</div>
                        <div class="rule">每日0:00-24:00</div>
                    </div>
                    <div class="trade-item trade">
                        <div class="title">购买费率</div>
                        <div class="rule">购买费率为0</div>
                    </div>
                    <div class="trade-item trade">
                        <div class="title">赎回时间</div>
                        <div class="rule">每日0:00-24:00</div>
                    </div>
                    <div class="trade-item trade">
                        <div class="title">赎回费率</div>
                        <div class="rule">赎回费率为0</div>
                    </div>
                    <div class="trade-item">
                        <div class="title">到账时间</div>
                        <div class="rule">赎回后1-3日到账</div>
                    </div>
                </div>
            </wrapper>

            <wrapper title="计息规则">
                <div slot="content">
                    <div class="interest-item interest">1）起息：该产品成功存入当天开始计息，支取日当天不计算利息</div>
                    <div class="interest-item interest">2）提前支取：存入满1天后可提前支取，支取时按活期存款利率计算利息，与本金一起发放</div>
                    <div class="interest-item">3）到期：存入后满3年产品到期，按4.54%计算利息，自动还本付息。</div>
                </div>
            </wrapper>

            <div class="introduceWrapper">
                <div class="introduce-item" v-for="item in routerList" :key="item.routerNo">
                    <router-link :to="item.url">
                        <div class="title">{{item.title}}</div>
                        <a-icon type="right" />
                    </router-link>
                    <a-divider v-if="item.routerNo != routerList.length" />
                </div>

            </div>
        </div>

        <div class="btnContainer">
            <div class="redeemBtn btn" @click="redeem">赎回</div>
            <div class="buyBtn btn" @click="buy">购买</div>
        </div>
    </div>
</template>

<script>

    import wrapper from "./components/wrapper";

    export default {
        name: 'ProductDetail',
        description: '产品详情页面',
        components: {
            wrapper,
        },
        data() {
            return {
                routerList: [
                    { routerNo: '1', title: '产品说明书', url: '/product/introduce' },
                    { routerNo: '2', title: '相关协议', url: '/product/agreement' },
                    { routerNo: '3', title: '常见问题', url: '/product/FAQ' }
                ],
                detail: {},
            }
        },

        created() {
            this.detail = this.$route.params
        },

        methods: {
            // 购买
            buy() {
                if(!this.detail.isOpen) { // 未开户,跳转开户页面进行开户
                    this.$router.push({
                        path: '/product/openAccount',
                    });
                } else {
                    this.$router.push({
                        path: '/product/buy',
                    });
                }
            },

            // 赎回
            redeem() {
                this.$router.push({
                    path: '/product/redeem',
                });
            },
        },
    }
</script>

<style lang="less" scoped>

    @import "less/product.less";

    .container {
        .dataWrapper {
            padding: 24px 64px 8px 64px;
            background: linear-gradient(0deg, #F16101 0%, #FC991A 100%);
            border-radius: 4px;

            .data-content, .dec-content {
                display: flex;
                justify-content: space-between;
            }

            .data-content {
                .rate, .date {
                    color: #FFFFFF;
                    text-align: center;
                }
                .rate .value, .date .value {
                    font-size: 24px;
                    font-weight: 700;
                }
                .rate .title, .date .title {
                    font-size: 12px;
                }
            }

            .dec-content {
                margin-top: 16px;
                .dec-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .depositIcon {
                        background-image: url(../../assets/product/deposite_white.png);
                    }

                    .interestIcon {
                        background-image: url(../../assets/product/money_white.png);
                    }

                    .riskIcon {
                        background-image: url(../../assets/product/risk_white.png);
                    }
                    .decIcon {
                        width: 24px;
                        height: 24px;
                    }
                    .title {
                        margin-top: 4px;
                        font-size: 12px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .detailWrapper {
            .contentWrapper {
                .dec-content {
                    margin-bottom: 8px;
                    .title {
                        font-size: 13.6px;
                        color: #000000;
                    }
                    .dec {
                        font-size: 12px;
                        color: #A5A5A5;
                    }
                }
            }
            .trade-item {
                display: flex;
                justify-content: space-between;
                .title {
                    font-size: 13.6px;
                    color: #A5A5A5;
                }
                .rule {
                    font-size: 13.6px;
                    color: #000000;
                }
            }
            .trade {
                margin-bottom: 12px;
            }
            .interest-item {
                font-size: 12px;
                color: #A5A5A5;
            }
            .interest {
                margin-bottom: 8px;
            }
        }

        .introduceWrapper {
            margin-top: 12px;
            margin-bottom: 48px;
            padding: 16px 12px;
            background: #FFFFFF;
            border-radius: 4px;
            .introduce-item {
                a {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    .title {
                        font-size: 14.4px;
                        color: #000000;
                    }
                    i {
                        font-size: 12.8px;
                        color: #A5A5A5;
                    }
                }
            }
            .ant-divider {
                margin: 12px 0;
            }
        }
    }

    .btnContainer {
        .btn {
            width: 50%;
            padding: 12px 0;
            text-align: center;
            color: #FFFFFF;
        }
        .redeemBtn {
            background: #FF7200;
        }
        .buyBtn {
            background: #FFBA00;
        }
    }
</style>