<template>
  <div>
    <div id="pc">
      <div class="pc-top">
        <div style="line-height: 1.5rem">
          <img src="../../assets/pc新闻中心/web.png" alt="" />
        </div>
      </div>
      <!-- <menutable></menutable> -->
      <div class="breadmenu" style="display: flex;background-color: #111421;">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="background-color: #111421;">
          <el-breadcrumb-item style="font-size: 0.12rem;" :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 0.1rem;" :to="{ path: '/newcenter' }">新闻中心</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <div style="line-height: 0.35rem">
            <span>企业新闻</span>
            <span>行业资讯</span>
            <span>媒体报道</span>
          </div> -->
      </div>
      <div class="bussBox">
        <div style="width: 60%; margin: 0 auto">
          <div style="display: flex">
            <div style="width: 100%;">
              <div style=" padding: 15px">
                <div style="color: rgb(251, 251, 251); font-size: 20px">
                  {{ contain.title }}
                </div>
                <div style="color: rgb(251, 251, 251); font-size: 12px;margin: 5px 0px 20px;">
                  <span>发布时间:{{ contain.created }}</span>
                </div>
                <div v-if="contain.thumbnail" style="width: 100%;height: 100%;margin-bottom: 20px;">
                  <img :src="contain.thumbnail" alt="" style="width: 100%;height: 100%;">
                </div>
                <div style="border-bottom: 0.01333rem solid rgb(211, 188, 147);">
                  <div style="font-size: 18px; color: rgb(251, 251, 251); overflow: auto" v-html="contain.content">
                    <!-- 石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化
                      石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化
                      石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化
                      石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化
                      石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化
                      石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化
                      石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化
                      石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化
                      石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化
                      石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化
                      石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化石家庄市鹿泉区：数字赋能城市基层治理现代化 -->
                  </div>
                </div>
                <div>
                  <div
                    style="text-align: center; margin: 20px auto;display: flex;justify-content: space-between;align-items: center;">
                    <div style="display: inline-block">
                      <button style="
                                      border: none;
                                      color: rgb(211, 188, 147);
                                      background: transparent;
                                    " :disabled="last" @click="gonext">
                        <div v-if="next == true" style="color: rgb(211, 188, 147);">已经是第一篇了</div>
                        <div v-else>
                          <span> <a style="color: rgb(211, 188, 147);">上一篇：</a> </span>
                          <span>
                            <a style="color: rgb(211, 188, 147);">{{
                              nextone.articleTitle
                            }}</a></span>
                        </div>
                      </button>
                    </div>
                    <!-- <div style="display: inline-block; margin: 150px 55px">
              <i class="el-icon-full-screen"></i>
            </div> -->
                    <div style="display: inline-block; width: 5%"></div>
                    <div style="display: inline-block">
                      <button style="
                                      border: none;
                                      background: transparent;
                                    " @click="golast" :disabled="next">
                        <div v-if="last == true" style="color: rgb(211, 188, 147);">已经是最后一篇了</div>
                        <div v-else>
                          <span> <a style="color: rgb(211, 188, 147);">下一篇：</a> </span>
                          <span>
                            <a style="color: rgb(211, 188, 147);">{{
                              lastone.articleTitle
                            }}</a></span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="width: 30%; margin-left: 25px; text-align: left">
              <div>
                <div style="
                      width: 3px;
                      height: 13px;
                      background-color: #e6b150;
                      display: inline-block;
                      margin-right: 5px;
                    "></div>
                热点新闻
              </div> -->
            <!-- <div
                style="background-color: #fff; margin: 10px 0"
                v-for="(item, index) in 2"
                :key="index"
              >
                <img
                  src="../../assets/pc新闻中心/图层 8.png"
                  style="
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    vertical-align: Baseline;
                  "
                />
                <div
                  style="
                    width: 3px;
                    height: 13px;
                    background-color: #fff;
                    display: inline-block;
                  "
                ></div>
                各个房间啊高房价卡给发几款
              </div> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div id="mobiel">
      <div class="mobielhead">
        <div style="line-height: 2rem">
          <img src="../../assets/pc新闻中心/app.png" alt="" style="width: 3rem" />
        </div>
      </div>
      <div style="" class="mobiel-contain">
        <div class="mobiel-conmessage">
          <div>
            <div style="border-bottom: 1px solid #c0c4cc">
              <div>
                <!-- <span style="width: 50%; font-size: 25px; font-weight: 500">
                    腹黑上的佛i
                  </span> -->
                <div style="font-size: 24px; font-weight: 450">
                  {{ contain.title }}
                </div>
                <div style="font-size: 13px; color: #c0c4cc">
                  <span>发布时间:{{ contain.created }}</span>
                </div>
              </div>
              <div class="content_div" style="font-size: 15px; margin: 10px 0" v-html="contain.content">
                <!-- {{ contain.modified }} -->
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 50px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { postAction } from "@/api/manage";
import { MessageBox } from "element-ui";
import menutable from "@/components/menu/menutable";
export default {
  components: { menutable },
  props: ["pcprogrem"],
  data() {
    return {
      query: [],
      contain: {},
      nextone: {},
      lastone: {},
      last: false,
      next: false,
    };
  },
  methods: {
    apply() {
  
      MessageBox.confirm("hr#hebeiqianhe.com", {
        center: true,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    golast() {
      postAction(
        "/api/article/detail",
        {
          id: this.lastone.articleId,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        // console.log(res, "后台详情");
        this.contain = res.detail;
        if (res.detail.nextOne == undefined) {
          this.nextone = {};
          // this.lastone = res.detail.lastOne;
          // this.last = false;
          this.next = true;
        } else {
          this.nextone = res.detail.nextOne;
          this.next = false;
        }
        if (res.detail.lastOne == undefined) {
          this.lastone = {};
          // this.nextone = res.detail.nextOne;
          this.last = true;
          // this.next = false
        } else {
          this.lastone = res.detail.lastOne;
          this.last = false;
        }
        // console.log(res.detail.lastOne, "4545");
      });
    },
    gonext() {
      postAction(
        "/api/article/detail",
        {
          id: this.nextone.articleId,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        // console.log(res, "后台详情");
        this.contain = res.detail;
        if (res.detail.nextOne == undefined) {
          this.nextone = {};
          // this.lastone = res.detail.lastOne;
          // this.last = false;
          this.next = true;
        } else {
          this.nextone = res.detail.nextOne;
          this.next = false;
        }
        if (res.detail.lastOne == undefined) {
          this.lastone = {};
          // this.nextone = res.detail.lastOne;
          this.last = true;
          // this.next = false
        } else {
          this.lastone = res.detail.lastOne;
          this.last = false;
        }
      });
    },
    getmessage() {
      postAction(
        "/api/article/detail",
        {
          id: this.query.id,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        console.log(res);
        this.contain = res.detail;
        this.last = false;
        this.next = false;
        // this.lastone = res.detail.lastOne;
        // this.nextone = res.detail.nextOne;
        if (res.detail.nextOne == undefined) {
          this.nextone = {};
          // this.lastone = res.detail.lastOne;
          // this.last = false;
          this.next = true;
        } else {
          this.nextone = res.detail.nextOne;
          this.next = false;
        }
        if (res.detail.lastOne == undefined) {
          this.lastone = {};
          // this.nextone = res.detail.nextOne;
          this.last = true;
          // this.next = false
        } else {
          this.lastone = res.detail.lastOne;
          this.last = false;
        }
      });
    },
  },
  filters: {},
  mounted() {
    this.query = this.$route.query;
    // console.log(this.query, "传参");
    this.getmessage();
  },
};
</script>
<style lang="less" scoped>
#pc {
  display: none;
}

.bussBox {
  background-image: url('~@/assets/images/back.png');
  cursor: default;
    padding: 0.06667rem 0px;
    background-position: 50% center;
    border-top: 0.01333rem solid rgb(211, 188, 147);
}

#mobiel {
  display: none;
}

@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: block;
  }

  #mobiel {
    display: none;
  }
}

/deep/ .el-pagination--small span:not([class*="suffix"]) {
  font-size: 5px;
  font-weight: 300;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(211, 188, 147);
}

/deep/ .el-pagination.is-background.el-pagination--small .el-pager li {
  margin: 0 0.04rem;
  min-width: 0.29333rem;
  font-size: 5px;
}

.el-pagination {
  text-align: center;
  font-weight: 300;
  display: inline-block;
}

/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #d9bb95;
}

/deep/.el-breadcrumb__inner.is-link {
  color: #d9bb95;
}

.el-breadcrumb {
  font-size: 0.05rem;
  line-height: 0.35rem;
  background-color: #fff;
  height: 0.35rem;
  padding-left: 2.8rem;
}

.pc-top {
  background-image: url(../../assets/招贤纳士/图层\ 14.png);
  width: 100%;
  height: 1.5rem;
  text-align: center;
}

.mobiel-contain {
  background-color: #f5f5f5;
  padding: 10px 0;
  height: 5.9rem;
  overflow: auto;

  .mobiel-conmessage {
    width: 95%;
    margin: 10px auto;
    height: auto;
    background-color: #fff;
    padding: 0.2rem;
  }
}

.mobielhead {
  background-image: url(../../assets/招贤纳士/图层\ 10.png);
  height: 2rem;
  text-align: center;
}
</style>