<template>
  <div>
    <div id="pc" >
      <div class="pc-top">
        <div style="line-height:1.5rem">
            <img src="../../assets/招贤纳士/组 1.png" alt="" />
        </div>
      </div>
      <menutable></menutable>
      <div class="details" style="width: 100%; padding: 50px 0;">
        <div style="width: 60%; margin: 0 auto;">
          <div style="display: flex">
            <div style="width:80%;">
              <div style="border-bottom: 1px solid #d9bb95">
                <div style="display: flex; justify-content: space-between;">
                  <span style="width: 50%; font-size: 25px; font-weight: 900;">
                    {{contain.title }}
                  </span>
                  <!--<div>-->
                  <!--  <span>-->
                  <!--      <img-->
                  <!--    src="../../assets/招贤纳士/图层 15.png"-->
                  <!--    alt=""-->
                  <!--    style="width: 20px"-->
                  <!--  />-->
                  <!--  </span>-->
                  <!--  <span>-->
                  <!--      分享-->
                  <!--  </span></div>-->
                </div>
                <div style="font-size: 20px; margin: 10px 0">
                  {{ contain.created }}
                </div>
              </div>
                <div
                  style="font-size: 18px; color: rgb(255, 250, 243);margin:20px 0; overflow: auto"
                  v-html="contain.content"
                >
                </div>
            </div>
            <div style="margin-left: 0.5rem">
              <div>
                <el-button
                  style=" width: 200px;
                    height: 40px;
                    padding: 0;
                    border-radius: 0;
                    background-color: rgb(211, 188, 147);
                    font-size: 14px;
                    color: #fff;
                  "
                  @click="apply"
                  >申请岗位</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="mobiel">
      <div class="mobielhead">
        <div style="line-height: 2rem">
          <img src="../../assets/招贤纳士/组 5.png" alt="" />
        </div>
      </div>
      <div style="" class="mobiel-contain">
        <div class="mobiel-conmessage">
            <div>
              <div style="border-bottom: 1px solid #c0c4cc">
                <div >
                  <span style="width: 50%; font-size: 25px; font-weight: 500"
                    >{{ contain.title }}</span
                  >
                </div>
                <div style="font-size: 15px; margin: 10px 0">
                 {{ contain.created }}
                </div>
              </div>
              <div>
                <h1 style="margin: 25px 0 20px 0">工作职责</h1>
                <div>
                  <!-- <span>{{ item.key }}、</span> -->
                  <span>
                    {{ contain.highlightContent }}
                    
                  </span>
                </div>
                <h1 style="margin: 25px 0 20px 0">工作要求</h1>
                <div >
                  <!-- <span>{{ item.key }}、</span> -->
                  <span>
                    {{ contain.summary }}
                  </span>
                </div>
              </div>
            </div>
            
        </div>
        <div style="width: 100%; height: 100px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { postAction } from "@/api/manage";
import { MessageBox } from "element-ui";
import menutable from "@/components/menu/menutable";
export default {
  components: { menutable },
  props:['pcprogrem'],
  data() {
    return {
      query:[],
      contain:{},
      
    };
  },
  methods: {
    apply() {
      // this.$alert('business#hebeiqianhe.com', '', {
      //   confirmButtonText: '',
      //   callback: action => {
      //     this.$message({
      //       type: 'info',
      //       message: `action: ${ action }`
      //     });
      //   }
      // });
      MessageBox.confirm("hr#hebeiqianhe.com", {
        center: true,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
    getmessage(){
      postAction('/api/article/detail',{
        id:this.query.id,
      },{'Content-Type':'application/x-www-form-urlencoded'}).then((res)=>{
        // console.log(res,'后台详情')
        this.contain = res.detail
        // this.pcprogrem = res.page.list
        // this.totalpage =res.page.totalPage
      })
    }
  },
  filters: {},
  mounted() {
    this.query = this.$route.query
    // console.log(this.query,'传参')
    this.getmessage()
    // console.log(this.pcprogrem,'fuchuan')
    // this.listData();
    // this.initYearList();
  },
};
</script>
<style lang="less" scoped>
 #pc {
  // display: none;
   .details{
     background: url("~@/assets/联系我们-切图/img01.png") repeat-y 0/100%;
     color: white;
     border-top: 1px solid #d9bb95
   }
}
#mobiel {
  display: none;
}
@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: block;
  }

  #mobiel {
    display: none;
  }
}
.pc-top{
    background-image: url(../../assets/招贤纳士/图层\ 14.png);
    width:100%;
    height: 1.5rem;
    text-align: center;
}
.mobiel-contain{
    background-color: #f5f5f5;
  padding: 10px 0;
  .mobiel-conmessage {
    width: 95%;
    margin: 10px auto;
    height: auto;
    background-color: #fff;
    padding: 0.2rem;
  }
}
.mobielhead {
  background-image: url(../../assets/招贤纳士/图层\ 10.png);
  height: 2rem;
  text-align: center;
}
</style>