var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {loginPanel: true}, _obj[_vm.stylesheetName] = true, _obj )},[_c('a-carousel',{attrs:{"autoplay":true}},[(_vm.bannerList.length == 0)?_c('div',{staticClass:"img"}):_vm._e(),_vm._l((_vm.bannerList),function(item,index){return _c('div',{key:index},[_c('img',{staticClass:"adImg",attrs:{"src":item.pictureUrl,"alt":""},on:{"touchstart":_vm.touchstart,"touchmove":_vm.touchmove,"touchend":function($event){return _vm.touchend(item)}}})])})],2),_c('a-form',{staticClass:"formPanel",attrs:{"form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone',{
                    rules: [{ required: true, message: '请输入手机号' },
                            { required: false, validator: function (rule, value) {
                                if (value) {
                                    return /^1[3456789]\d{9}$/.test(value);
                                } else {
                                    return true;
                                }
                            },
                            message: '请输入正确的手机号'}]
                    }]),expression:"['phone',{\n                    rules: [{ required: true, message: '请输入手机号' },\n                            { required: false, validator: (rule, value) => {\n                                if (value) {\n                                    return /^1[3456789]\\d{9}$/.test(value);\n                                } else {\n                                    return true;\n                                }\n                            },\n                            message: '请输入正确的手机号'}]\n                    }]"}],staticClass:"selectWidth",attrs:{"placeholder":"请输入手机号","maxLength":11},on:{"change":_vm.changePhone}})],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayout,false),[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":16}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['verificationCode', {
                                 rules: [{ required: true, message: '请输入验证码' },
                                 { required: false, validator: function (rule, value) {
                                    if (value) {
                                        return /^\d{6}$/.test(value);
                                    } else {
                                        return true;
                                    }
                                },
                                message: '验证码应为六位数字'}] }]),expression:"['verificationCode', {\n                                 rules: [{ required: true, message: '请输入验证码' },\n                                 { required: false, validator: (rule, value) => {\n                                    if (value) {\n                                        return /^\\d{6}$/.test(value);\n                                    } else {\n                                        return true;\n                                    }\n                                },\n                                message: '验证码应为六位数字'}] }]"}],attrs:{"placeholder":"验证码","maxLength":6}})],1),_c('a-col',{staticClass:"getCaptcha",attrs:{"span":8},on:{"click":_vm.getCaptcha}},[_vm._v(_vm._s(_vm.count === 60 ? '获取验证码' : _vm.count + 's后重新发送')+" ")])],1)],1),_c('a-spin',{directives:[{name:"show",rawName:"v-show",value:(_vm.wxLoading),expression:"wxLoading"}],staticClass:"wxLoading"}),_c('a-button',{staticClass:"submitBtn",attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.bindUser}},[_vm._v(_vm._s(_vm.loading ? '登录中' : '登录'))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }