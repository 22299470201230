<template>
  <div>
    <div id="pc">
      <div class="pc-top">
        <div style="line-height: 1.5rem">
          <img src="../../assets/招贤纳士/组 1.png" alt="" />
        </div>
      </div>
      <menutable></menutable>
      <div class="contain">
        <div
          class="conmessage"
          v-for="(item, index) in param"
          :key="index"
          @click="godetail(item)"
        >
          <div
            :style="{ color: item.istrue == true ? '#E6B150' : '#000000' }"
            style="font-size: 20px"
          >
            <!-- <button style="background-color:#ffffff;border:none">
            {{ item.name }}
          </button>  -->
            <a style="color: #000000">
              {{ item.name }}
            </a>
          </div>
          <div style="margin-top: 10px; font-size: 16px">{{ item.date }}</div>
          <div style="position: absolute; right: 0.4rem; top: 0.4rem">
            <div>
              <img
                src="../../assets/招贤纳士/图层 15.png"
                alt=""
                style="width: 20px"
              />
            </div>
            <div style="margin-top: 5px; font-size: 10px">分享</div>
          </div>
          <div style="width: 90%; margin-top: 15px; font-size: 14px">
            {{ item.message }}
          </div>
        </div>
        <div style="margin: 20px auto; background-color: #f5f5f5">
          <div
            style="
              margin: auto;
              width: 55%;
              display: flex;
              justify-content: space-around;
            "
          >
            <div>
              <!-- <el-button
                :disabled="currentPage === 1"
                class="first-pager"
                @click="toFirstPage"
                >首页</el-button
              > -->
            </div>
            <el-pagination
              ref="pagination"
              background
              layout="prev, pager, next"
              :total="1000"
              next-text="下一页"
              prev-text="上一页"
              small="true"
            >
            </el-pagination>
            <div style="height: 51px">
              <!-- <el-button class="last-pager" @click="toLastPage">末页</el-button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="mobiel" style="height: 110%">
      <div class="mobielhead">
        <div style="line-height: 2rem">
          <img src="../../assets/招贤纳士/组 5.png" alt="" />
        </div>
      </div>
      <div class="mobiel-contain">
        <div
          class="mobiel-conmessage"
          v-for="(item, index) in param"
          :key="index"
          @click="godetail(item)"
        >
          <div
            :style="{ color: item.istrue == true ? '#E6B150' : '#000000' }"
            style="font-size: 20px"
          >
            {{ item.name }}
          </div>
          <div style="margin-top: 10px; font-size: 16px">{{ item.date }}</div>
          <div
            style="width: 99%; margin-top: 15px; font-size: 14px"
            class="messagedetail"
          >
            {{ item.message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { postAction } from "@/api/manage";
import menutable from "@/components/menu/menutable";
export default {
  components: { menutable },
  data() {
    return {
      param: [
        {
          name: "高级Linux运维工程师",
          date: "2022年04月-18日",
          istrue: false,
          message:
            "1、负责公司服务器基础环境的部署、配置、日常巡检、维护、故障的应急响应和问题处理；2、负责公司kvm虚的人个人隔热拟化平台的管理工作，基础能否落实的客户覅和覅拉黑我还分为给你色结付款的绯闻离妃hi我构和会给",
        },
        {
          name: "高级Linux运维工程师",
          date: "2022年04月-18日",
          istrue: false,
          message:
            "1、负责公司服务器基础环境的部署、配置、日常巡检、维护、故障的应急响应和问题处理；2、负责公司kvm虚的人个人隔热拟化平台的管理工作，基础能否落实的客户覅和覅拉黑我还分为给你色结付款的绯闻离妃hi我构和会给",
        },
        {
          name: "高级Linux运维工程师",
          date: "2022年04月-18日",
          istrue: false,
          message:
            "1、负责公司服务器基础环境的部署、配置、日常巡检、维护、故障的应急响应和问题处理；2、负责公司kvm虚拟sgegwe化平台的管理工作，基础能否落实的客户覅和覅拉黑我还分为给你色结付款的绯闻离妃hi我构和会给",
        },
      ],
    };
  },
  methods: {
    godetail(val) {
      val.istrue = !val.istrue;
      let query = {};
      query = val;
      this.$router.push({
        path: "/recruit/detail",
        query: query,
      });
    },
    jumpFirstPage() {},
    jumpLastPage() {
      let font = this.$refs.pagination;
      let cpage = Math.ceil(font.total / font.pageSize);
      font.handleCurrentChange(cpage);
    },
  },
  filters: {
    formatNull: function (val) {
      if (val == null) {
        return 0;
      } else {
        return val;
      }
    },
  },
  mounted() {
    // this.listData();
    // this.initYearList();
  },
};
</script>
<style  lang="less" scoped>
#pc {
  display: none;
}
#mobiel {
  display: none;
}
@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: block;
  }

  #mobiel {
    display: none;
  }
}
.pc-top {
  background-image: url(../../assets/招贤纳士/图层\ 14.png);
  width: 100%;
  height: 1.5rem;
  text-align: center;
}
.messagedetail {
  display: -webkit-box !important;
  overflow: hidden;

  word-break: break-all;
  text-overflow: ellipsis;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.mobielhead {
  background-image: url(../../assets/招贤纳士/图层\ 10.png);
  height: 2rem;
  text-align: center;
}
.first-pager {
  cursor: pointer;
  padding: 0;
  width: 0.25rem;
  font-size: 5px;
  height: 0.2rem;
  margin-right: -0.5rem;
  border-radius: 0;
}
.last-pager {
  cursor: pointer;
  padding: 0;
  width: 0.25rem;
  font-size: 5px;
  height: 0.2rem;
  margin-left: -0.5rem;
  border-radius: 0;
}
/deep/ .el-pagination--small span:not([class*="suffix"]) {
  font-size: 5px;
  font-weight: 300;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e19017;
}
/deep/ .el-pagination.is-background.el-pagination--small .el-pager li {
  margin: 0 0.04rem;
  min-width: 0.29333rem;
  font-size: 5px;
}

.el-pagination {
  text-align: center;
  font-weight: 300;
  display: inline-block;
}

.mobiel-contain {
  background-color: #f5f5f5;
  padding: 10px 0;
  .mobiel-conmessage {
    width: 95%;
    margin: 10px auto;
    height: auto;
    background-color: #fff;
    padding: 0.2rem;
  }
}
.contain {
  //height: 600px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  padding-bottom: 3px;
  .conmessage {
    width: 60%;
    margin: 10px auto;
    height: auto;
    background-color: #fff;
    padding: 0.2rem;
    position: relative;
  }
}
</style>