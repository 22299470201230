<template>
    <div>
        <div style="position: relative;padding:128px 0 64px;background: #FFFFFF">
            <div class="successContainer">
                <div class="success_bg"></div>
            </div>

            <div class="title">支付成功</div>
        </div>

        <div class="btnContainer">
            <div class="redeemBtn btn" @click="back">返回</div>
            <div class="buyBtn btn" @click="toTradeDetail">交易详情</div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'PaySuccess',
        description: '支付成功页面',

        methods: {
            // 返回
            back() {
                this.$router.push({
                    path: '/holdingassets/TransHistory'
                })
            },

            // 跳转到交易详情页面
            toTradeDetail() {
                this.$router.push({
                    path: '/holdingassets/TransDetail'
                })
            },
        },
    }
</script>

<style lang="less" scoped>
    .successContainer {
        width: 80%;
        margin: auto;
        .success_bg {
            width: 100%;
            height: 96px;
            background-image: url(../../assets/product/success.png);
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .title {
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 24px;
        color: #FFBA00;
        font-weight: 700;
    }

    .btnContainer {
        width: 100%;
        display: flex;
        position: fixed;
        bottom: 0;
        .btn {
            width: 50%;
            padding: 12px 0;
            text-align: center;
            color: #FFFFFF;
        }
        .redeemBtn {
            background: #FF7200;
        }
        .buyBtn {
            background: #FFBA00;
        }
    }
</style>