<template>
    <article>
        <header>代发审批</header>
        <section class="contentPage">
            <div class="item" v-for="item in salaryInfo" :key='item.title'>
                <div class="title">{{item.title}}：</div>
                <div class="value">{{info[item.name]}}</div>
            </div>
            <div class="item" v-if="info.button!=='00'">
                <div class="title">审批状态：</div>
                <div class="value">{{status[info.status]}}</div>
            </div>
            <div class="tist" v-if="info.button == '02'">请到企业工作台插入UKEY进行资金发放确认</div> 
        </section>
        <footer v-if="info.button=='00'">
            <a-button @click="showModal" :disabled="isAble">拒绝</a-button>
            <a-button type="primary" @click="pass" :loading='passing' :disabled="isAble">通过</a-button>
        </footer>

        <a-modal title="拒绝原因" v-model="permission" @ok="refuse" :confirmLoading="loading" okText="确认" cancelText="取消">
            <a-form :form="form">
                <a-form-item>
                    <a-textarea placeholder="请输入拒绝原因" class="selectWidth" v-decorator="['reason',{
                        rules: [{ required: true, message: '请输入拒绝原因' }, {max: 50, message: '字数不能超过50'}]
                        }]"
                    >
                    </a-textarea>
                </a-form-item>
            </a-form>
        </a-modal>
    </article>
</template>
<script>
    import {getUserInfo} from "@/utils/storage";
    import {postAction} from "@/api/manage";

    export default {
        data() {
            return {
                salaryInfo: [
                    {title: '所属月份', name: 'month'},
                    {title: '发放类型', name: 'type'},
                    {title: '发放笔数', name: 'totalCount'},
                    {title: '发放金额', name: 'totalAmount'},
                    {title: '代发账户', name: 'payAccount'},
                    {title: '账户名称', name: 'payAccountName'},
                    {title: '申请人', name: 'applyerName'},
                    {title: '联系电话', name: 'applyerPhone'},
                    {title: '申请时间', name: 'applyTime'},
                    {title: '发薪说明', name: 'description'},
                    {title: '备注', name: 'remark'}
                ],
                info: {},
                userInfo: {},
                companyId: '',
                employeeId: '',
                queryObj: {},
                //00提交申请 01审批通过 02审批拒绝 03审批中 04撤回
                status: {
                    '00': '提交申请',
                    '01': '审批通过',
                    '02': '审批拒绝',
                    '03': '审批中',
                    '04': '撤回'
                },
                permission: false,
                form: this.$form.createForm(this),
                loading: false,
                passing: false,
                isAble: false,
            }
        },
        methods: {
            pass() {
                if (this.passing) {
                    return false;
                }
                // console.log(this.passing,'this.passing')
                this.passing = true;
                this.isAble = true;
                postAction('/salary/audit/approve', {
                    auditNo: this.queryObj.slipBatchNo || this.$route.query.slipBatchNo,
                    companyId: this.companyId,
                    employeeId: this.employeeId ,
                    auditSort: 1,
                    phone: this.userInfo.phone
                }).then((res) => {
                    this.isAble = true;
                    this.passing = false;
                    if (res.success) {
                        this.$message.success('操作成功', 2, () => {
                            this.queryInfo();
                        });
                    } else {
                        this.$message.warning(res.message);
                    }
                });
            },
            refuse() {
                this.form.validateFields((err, values) => {
                    if (!err) {
                        this.loading = true;
                        postAction('/salary/audit/deny', {
                            companyId: this.companyId,
                            employeeId: this.employeeId,
                            auditNo: this.queryObj.slipBatchNo || this.$route.query.slipBatchNo,
                            auditSort: 1,
                            phone: this.userInfo.phone,
                            remark: values.reason
                        }).then((res) => {
                            if (res.success) {
                                this.$message.success('操作成功', 2, () => {
                                    this.permission = false;
                                    this.queryInfo();
                                });
                            } else {
                                this.$message.warning(res.message);
                            }
                        }).finally(() => {
                            this.loading = false
                        });
                    }
                });
            },
            showModal() {
                this.permission = true;
                this.form.resetFields();
            },
            getTokenWXUrl() { // 微信的链接跳转
                var post = {}
                let searchStr = window.location.href.split('?')[1]
                if (searchStr) {
                    searchStr = searchStr.replace(/&amp;/g, '&')
                        .replace(/%2F/g, '/')
                        .replace(/%3F/g, '&')
                        .replace(/%3D/g, '=')
                        .replace(/%20/g, '+')
                    const urlStr = decodeURIComponent(searchStr);
                    let search = urlStr.indexOf('?') > -1 ? urlStr.split('?')[1] : urlStr;
                    if(search){
                        search.split('&').forEach((item) =>{
                            post[item.substring(0,item.indexOf("="))] = item.substring(item.indexOf("=")+1)
                        })
                    }
                }
                return post;
            },
            queryInfo() {
                postAction('/salary/audit/info', {
                    companyId: this.queryObj.companyId,
                    employeeId: this.queryObj.employeeId,
                    auditNo: this.queryObj.slipBatchNo || this.$route.query.slipBatchNo,
                    auditSort: 1
                }).then((res) => {
                    if (res.success) {
                        this.info = res.data;
                        this.info.auditSort = '资金发放审批';
                    } else {
                        this.$message.warning(res.message);
                    }
                })
            }
        },
        mounted() {
            // this.queryObj = to.query.redirect ? this.getTokenWXUrl() : to.query
            this.queryObj = this.$route.query
            this.userInfo = getUserInfo();
            this.companyId = this.queryObj.companyId;
            this.employeeId = this.queryObj.employeeId;

            this.queryInfo();
        }
    }
</script>
<style lang="less" scoped>
    article {
        width: 100%;
        height: 100%;

        header {
            line-height: 45px;
            padding-left: 15px;
            background: white;
        }

        .contentPage {
            margin: 15px;
            max-height: calc(100% - 125px);
            overflow-y: scroll;
            .item {
                display: flex;
                /*justify-content: space-between;*/
                background: white;
                line-height: 40px;
                margin-bottom: 1px;
                padding: 0 10px;

                .title {
                    display: inline-block;
                    width: 80px;
                    font-size: 14px;
                    color: #666666;
                    text-align: right;
                }

                .value {
                    width: 80%;
                    display: inline-block;
                    padding-left: 10px;
                }
            }

            .tist{
                margin-top: 10px;
                font-size: 12px;
                text-align: center;
                color: #666;
            }
        }

        footer {
            width: 100%;

            button {
                width: 46%;
                margin: 0 2%;
                line-height: 40px;
                height: 40px;
            }
        }
    }
</style>