<template>
  <div class="wrap">
    <div v-if="currentNum=='01'">
        <h3 style="text-align:center;" class="topTitle">
        国家税务总局关于进一步简便优化部分纳税人个人所得税预扣预缴方法的公告
        </h3>
        <p class="secTitle">国家税务总局公告2020年第19号</p>
        <div class="contentInfo">
                <p>为进一步支持稳就业、保就业、促消费，助力构建新发展格局，按照《中华人民共和国个人所得税法》及其实施条例有关规定，现就进一步简便优化部分纳税人个人所得税预扣预缴方法有关事项公告如下：</p>
                <p>一、对上一完整纳税年度内每月均在同一单位预扣预缴工资、薪金所得个人所得税且全年工资、薪金收入不超过6万元的居民个人，扣缴义务人在预扣预缴本年度工资、薪金所得个人所得税时，累计减除费用自1月份起直接按照全年6万元计算扣除。即，在纳税人累计收入不超过6万元的月份，暂不预扣预缴个人所得税；在其累计收入超过6万元的当月及年内后续月份，再预扣预缴个人所得税。</p>
                <p>一、对上一完整纳税年度内每月均在同一单位预扣预缴工资、薪金所得个人所得税且全年工资、薪金收入不超过6万元的居民个人，扣缴义务人在预扣预缴本年度工资、薪金所得个人所得税时，累计减除费用自1月份起直接按照全年6万元计算扣除。即，在纳税人累计收入不超过6万元的月份，暂不预扣预缴个人所得税；在其累计收入超过6万元的当月及年内后续月份，再预扣预缴个人所得税。</p>
                <p>扣缴义务人应当按规定办理全员全额扣缴申报，并在《个人所得税扣缴申报表》相应纳税人的备注栏注明“上年各月均有申报且全年收入不超过6万元”字样。</p>
                <p>二、对按照累计预扣法预扣预缴劳务报酬所得个人所得税的居民个人，扣缴义务人比照上述规定执行。</p>
        </div>
        <p class="text-b-l">本公告自2021年1月1日起施行。</p>
        <p class="text-b-l">特此公告。</p>
        
        <p class="text-b-r">国家税务总局</p>
        <p class="text-b-r">2020年12月4日</p>
    </div>
    <div v-if="currentNum=='02'">
         <h3 style="text-align:center;" class="topTitle">
          国家税务总局关于完善调整部分纳税人个人所得税预扣预缴方法的公告
        </h3>
        <p class="secTitle">国家税务总局公告2020年第13号</p>
        <div class="contentInfo">
                <p>为进一步支持稳就业、保就业，减轻当年新入职人员个人所得税预扣预缴阶段的税收负担，现就完善调整年度中间首次取得工资、薪金所得等人员有关个人所得税预扣预缴方法事项公告如下：</p>
                <p>一、对一个纳税年度内首次取得工资、薪金所得的居民个人，扣缴义务人在预扣预缴个人所得税时，可按照5000元/月乘以纳税人当年截至本月月份数计算累计减除费用。</p>
                <p>二、正在接受全日制学历教育的学生因实习取得劳务报酬所得的，扣缴义务人预扣预缴个人所得税时，可按照《国家税务总局关于发布〈个人所得税扣缴申报管理办法（试行）〉的公告》（2018年第61号）规定的累计预扣法计算并预扣预缴税款。</p>
                <p>三、符合本公告规定并可按上述条款预扣预缴个人所得税的纳税人，应当及时向扣缴义务人申明并如实提供相关佐证资料或承诺书，并对相关资料及承诺书的真实性、准确性、完整性负责。相关资料或承诺书，纳税人及扣缴义务人需留存备查。</p>
                <p>四、本公告所称首次取得工资、薪金所得的居民个人，是指自纳税年度首月起至新入职时，未取得工资、薪金所得或者未按照累计预扣法预扣预缴过连续性劳务报酬所得个人所得税的居民个人。</p>
        </div>
        <p class="text-b-l">本公告自2020年7月1日起施行。</p>
        <p class="text-b-l">特此公告。</p>
        
        <p class="text-b-r">国家税务总局</p>
        <p class="text-b-r">2020年7月28日</p>
    </div>
  </div>
</template>

<script>
  import { getUserInfo, setUserInfo, getCurrentCompany } from "@/utils/storage";
  import { postAction } from "@/api/manage";

  export default {
    data() {
      return {
        isRefresh: false,
        loading: false,
        finished: true,
        list: [], // 数据列表
        total: 0, // 数据总数
        employeeId: "",
        taxList: [],
        yearList: [],
        selectYear: new Date().getFullYear(),
        isShow: false,
        listQuery: {
          page: 1,
          num: 10
        }, // 请求分页参数
        currentNum:'',
      };
    },
    created() {
      this.currentNum = this.$route.query.num;
    },
    mounted() {

    },
    methods: {

    }
  };
</script>
<style lang="less" scoped>
  .wrap{
    padding: 17px;
  }
  .topTitle {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    color: #333;
    border-bottom: 1px solid #666;
    font-size: 18px;
  }
  .secTitle{
    font-weight:bold;
    text-align: center;
    font-size:16px;
    margin-top:10px;
    color: rgb(226, 21, 21);
}
.text-b-r{
    text-align: right;
    font-size: 14px;
    font-weight: bold;
}
.text-b-l{
    text-align: left;
    font-size: 14px;
    text-indent: 2em;
}
.contentInfo p{
    text-indent: 2em;
     font-size: 14px;
}

  .chooseYear {
    top: 15px;
  }
  .chooseYear {
    //  position: absolute;
    //  top: 0;
    margin-right: 9.6px;
    display: flex;
    align-items: center;
    right: 18px;
    //  font-size: 24px;
    color: #f57a00;
    span {
      margin-right: 5px;
    }

    img {
      width: 12.8px;
      vertical-align: text-top;
    }
  }

  .chooseYearPanel {
    position: absolute;
    //bottom: -320px; */
    top: 64px;
    right: 10px;
    width: 80px;
    text-align: center;
    background: white;
    transition: all 0.5s;
    opacity: 0;
    border: 1px solid #dfdfdf;
    height: 0;
    overflow: hidden;

    &.show {
      opacity: 1;
      height: 160px;
      overflow: auto;
    }
  }

  .yearItem {
    line-height: 38.4px;

    &.current {
      color: white;
    }
  }
  .yearItem.current {
    background: #ffba00;
  }
  .yearPanel {
    overflow-y: auto;
    padding: 10px 0;
    -webkit-overflow-scrolling: touch;
  }
</style>
<style lang="less" scope>
  @import "../../assets/less/theme.less";

  .refresh-text {
    font-size: 9.6px;
    text-align: center;
    line-height: 25.6px;
  }
  .query-btn {
    color: @theme-color;
    padding: 12.8px 9.6px;
  }
  .tax-item {
    position: relative;
    background: #fff;
    border-radius: 4px;
    padding: 9.6px 20px;
    margin: 0 9.6px 9.6px;

    &__col {
      flex-direction: column;
      font-size: 16px;
      color: #354b59;

      &:nth-child(2) {
        position: absolute;
        top: 9.6px;
        left: 50%;
        transform: translateX(-50%);
      }

      &-text {
        font-size: 9.6px;
        color: #9ba5aa;
      }
    }
  }
</style>