<template>
    <div class="detailWrapper">
        <div class="header">
            <div class="line"></div>
            <div class="title">{{title}}</div>
        </div>

        <a-divider></a-divider>

        <div class="contentWrapper">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Wrapper',
        props: {
            title: {
                type: String,
                default: '标题',
                required: true,
            }
        }
    }
</script>

<style lang="less" scoped>
    .detailWrapper {
        margin-top: 12px;
        padding: 16px 12px 8px 12px;
        background: #FFFFFF;
        border-radius: 4px;
        .header {
            display: flex;
            align-items: center;
            .line {
                width: 4px;
                height: 15.2px;
                margin-right: 4.8px;
                background: #FFBA00;
                border-radius: 5px;
            }
            .title {
                color: #000000;
            }
        }
        .ant-divider {
            margin: 16px 0;
        }
        .contentWrapper {
            padding-left: 8px;
        }
    }
</style>