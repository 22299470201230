<template>
  <div>
    <div id="pc">
      <div class="pc-top">
        <div style="line-height: 1.5rem">
          <img src="../../assets/产品中心/022018024248_0组 1.png" alt="" />
        </div>
      </div>
      <div class="breadmenu">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item style="font-size: 0.12rem;" :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 0.1rem;" :to="{ path: '/products' }">产品中心</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="productsBox">
        <div style="width: 80%; margin: auto">
          <div class="service">
            <div v-for="(item, index) in pcprogrem" :key="index" class="serviceson" @click="godetail(item)">
              <!-- <a href="">
              <img :src="item.url" alt=""  style="width: 100%;
                  height: 100%;
                  max-width: 100%;
                  max-height: 100%;">
            </a> -->
              <a>
                <div style="text-align: center; font-size: 20px; color: rgb(251, 251, 251);">
                  {{ item.title }}
                </div>
                <div style="font-size: 16px;height: 45px;  color: rgb(251, 251, 251); margin: 20px 0;"
                  class="messagedetail">
                  {{ item.highlightContent }}...
                </div>
                <div style="text-align: center">
                  <img :src="item.showImage" alt="" style="width:auto;height:auto;max-width:100%;max-height:100%" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div style="margin: 20px auto">
          <div style="
                      margin: auto;
                      width: 55%;
                      display: flex;
                      justify-content: space-around;
                    ">
            <div>
              <!-- <el-button
                :disabled="currentPage === 1"
                class="first-pager"
                @click="toFirstPage"
                >首页</el-button
              > -->
            </div>
            <el-pagination ref="pagination" background layout="prev, pager, next" :current-page="currentPage"
              :page-count="totalpage" @current-change="changecurrent" next-text="下一页" prev-text="上一页" :small="true">
            </el-pagination>
            <div style="height: 51px">
              <!-- <el-button class="last-pager" @click="toLastPage">末页</el-button> -->
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <div id="mobiel" style="background-color:#111421">
      <div class="mobielhead">
        <div style="line-height: 2rem">
          <img src="../../assets/产品中心移动端/022018043826_0产品.png" alt="" style="width: 4rem" />
        </div>
      </div>
      <div class="mobiel-contain" style="overflow:auto">
        <div class="mobiel-case">
          <div v-for="(item, index) in moprogrem" :key="index" class="mobiel-con" @click="godetail(item)">
            <div style="text-align: center; color: #d3bc93; font-size: 20px">
              <span>{{ item.highlightTitle }}</span>
            </div>
            <div style="color: #d3bc93;margin: 10px 0;">
              {{ item.highlightContent }}
            </div>
            <div style="text-align: center">
              <img :src="item.showImage" alt="" style="
                          width: 100%;
                          height: 100%;
                          max-width: 100%;
                          max-height: 100%;
                        " />
            </div>
          </div>
          <div style="text-align:center;color:#d3bc93" @click="getmore" v-if="mototal < mototals">更多</div>
          <div style="width: 100%; height: 10px"></div>
        </div>
      </div>
      <div style="width: 100%; height: 100px"></div>
    </div>
  </div>
</template>
<script>
import { getUserInfo, setUserInfo, getCurrentCompany } from "@/utils/storage";
import { postAction } from "@/api/manage";
// import img0 from "../../assets/pc服务案例/图层 14.png";
// import img0 from "../../assets/pc服务案例/图层 15.png";
// import img0 from "../../assets/pc服务案例/图层 16.png";
// import img0 from "../../assets/pc服务案例/图层 17.png";
// import img0 from "../../assets/pc服务案例/图层 18.png";
// import img0 from "../../assets/pc服务案例/图层 19.png";
export default {
  data() {
    return {
      moprogrem: [],
      mocurrentPage: 1,
      mototal: 0,
      mototals: null,
      mopagesize: 3,
      currentPage: 1,
      pageSize: 6,
      pcprogrem: [],
      totalpage: null,
      userInfo: {
        setting: [],
      },
      bindData: "",
    };
  },
  created() { },
  activated() { },
  methods: {
    godetail(val) {
      window.open(`/products/detail?id=${val.id}&slug=${val.siteId}`)
      // this.$router.push({
      //   path: "/products/detail",
      //   query: {
      //     id: val.id,
      //     slug: val.siteId
      //   }
      // });
    },
    getlist() {
      postAction('/api/article/paginate', {
        categoryId: 4,
        pageNumber: this.currentPage,
        pageSize: this.pageSize
      }, { 'Content-Type': 'application/x-www-form-urlencoded' }).then((res) => {
        // console.log(res,'后台返回')
        this.pcprogrem = res.page.list
        this.totalpage = res.page.totalPage
      })
    },
    changecurrent(val) {
      this.currentPage = val
      this.getlist()
    },
    getmore() {
      this.mocurrentPage++
      this.getmobiellist()
    },
    getmobiellist() {
      postAction('/api/article/paginate', {
        categoryId: 12,
        pageNumber: this.mocurrentPage,
        pageSize: this.mopagesize
      }, { 'Content-Type': 'application/x-www-form-urlencoded' }).then((res) => {
        // console.log(res.page.list.length,'changdu')
        this.mototal += res.page.list.length
        this.mototals = res.page.totalRow
        // console.log(res,'返回')
        res.page.list.forEach(item => {
          this.moprogrem.push(item)
        });

        //this.totalpage =res.page.totalPage
      })
    },
  },
  mounted() {
    this.getlist()
    this.getmobiellist()
    // 判断pc和移动
    // let str = window.navigator.userAgent;
    // if (str.toLowerCase().indexOf("mobile") == -1) {
    //   console.log(document.getElementById("pc"), "pc777777777777");
    //   document.getElementById("pc1").style.display = "block";
    //   document.getElementById("mobiel1").style.display = "none";
    // } else {
    //   document.getElementById("mobiel1").style.display = "block";
    //   document.getElementById("pc1").style.display = "none";
    // }
  },
  destroyed() { },
};
</script>

<style lang="less" scoped>
#pc {
  display: none;
}

#mobiel {
  display: none;
}

@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: block;
  }

  #mobiel {
    display: none;
  }
}

.mobiel-con {
  border: 1px solid #d3bc93;
  margin: 0.4rem 0;
  padding: 25px;
}

.mobiel-case {
  width: 80%;
  margin: auto;
}

.mobielhead {
  background-image: url(../../assets/招贤纳士/图层\ 10.png);
  height: 2rem;
  text-align: center;
}

/deep/ .el-pagination--small span:not([class*="suffix"]) {
  font-size: 5px;
  font-weight: 300;
  background-color: rgb(24, 29, 47);
  color: rgb(211, 188, 147);
}

/deep/ .el-pagination.is-background.el-pagination--small .btn-prev {
  color: #d3bc93;
  background-color: #111421;
}

/deep/ .el-pagination.is-background.el-pagination--small .btn-next {
  color: #d3bc93;
  background-color: #111421;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(211, 188, 147);
  color: white;
}

/deep/ .el-pagination.is-background.el-pagination--small .el-pager li {
  margin: 0 0.04rem;
  min-width: 0.29333rem;
  font-size: 7px;
  background-color: rgb(24, 29, 47);
  color: rgb(211, 188, 147);
}

.messagedetail {
  display: -webkit-box !important;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.el-pagination {
  text-align: center;
  font-weight: 300;
  display: inline-block;
}

.serviceson {
  // padding: 0.1rem;
  width: 30%;
  border: 0.01333rem solid #d3bc93;
  // border: 1px dashed #d3bc93;
  margin: 0 2% 2% 1%;
  padding: 10px;
}

.serviceson img:hover {
  transform: scale(1.07);

}

.serviceson img {
  transition: all .3s ease .1s;
}

// .serviceson:nth-child(2n){
//   margin:0 10px 10px 10px;
// }
// .serviceson:nth-child(2n+1){
//   margin:0 10px 10px 10px;
// }
.service {
  width: 86.6%;
  display: flex;

  flex-wrap: wrap;
  margin: auto;
}

.productsBox {
  width: 100%;
  background-image: url('~@/assets/images/back.png');
  padding: 0.26667rem 0px;
  background-position: 50% center;
  border-top: 0.01333rem solid rgb(211, 188, 147);
}

.productson {
  width: 25%;
  // height: auto;
  border: 1px dashed #d3bc93;
  margin: 10px;
}

.product {
  display: flex;
  margin: 0.23rem auto;
  flex-wrap: wrap;
  width: 62%;
  //margin-top: 0.1rem;
}

.pc-top {
  background-image: url(../../assets/招贤纳士/图层\ 14.png);
  width: 100%;
  height: 1.5rem;
  background-color: ;
  text-align: center;
}

/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #d9bb95;
}

/deep/.el-breadcrumb__inner.is-link {
  color: #d9bb95;
}

.el-breadcrumb {
  font-size: 0.05rem;
  line-height: 0.35rem;
  background-color: #111421;
  height: 0.35rem;
  padding-left: 2.8rem;
  color: #fff;
}
</style>
  
<style>
::-webkit-scrollbar {
  display: none;
}
</style>