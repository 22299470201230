<template>
  <div>
    <div id="pc" style="position: relative">
      <div class="pc-top">
        <div style="line-height: 1rem; margin: auto;text-align: center">
          <img src="../../assets/pc公司荣誉/风雨兼程 一路向前.png" alt="" style="width: 380px" />
        </div>
      </div>
      <div style="width: 100%; background-color: #000000; padding: 5px">
        <div style="margin-top: -0.5rem; height: 1rem; z-index: 1000">
          <div style="
              width: 1000px;
              line-height: 1rem;
              margin: auto;
              background-color: #111421;
              text-align: center;
              border-radius: 25px;
            ">
            <img src="../../assets/pc公司荣誉/组 2.png" alt="" />
          </div>
        </div>
        <div style="width: 57%; margin: 20px auto">
          <div style="
              text-align: center;
              font-size: 25px;
              color: #d3bc93;
              font-weight: 600;
            ">
            公司获得多项诚信认证
          </div>
          <div style="display: flex; flex-wrap: wrap; margin-top: 15px">
            <div v-for="item in pcprogremone" :key="item.articleId" style="width: 33%">
              <!-- <el-image
                :src="item.showImage"
                alt=""
                style="width: auto; height: auto"
                :preview-src-list="sincerityImage"
                v-lazy
              /> -->
              <div v-html="item.content" @click="addImg(item)" style="cursor: pointer;"></div>
              <!-- section 公司获得多项诚信认证对话框 -->
              <el-dialog :visible.sync="dialogTableVisible" @close="handleDialogClose">
                <el-image style="width: 100%; height: 100%" :src="sincerityImage">
                </el-image>
              </el-dialog>
            </div>
          </div>

          <div style="
              text-align: center;
              font-size: 25px;
              color: #d3bc93;
              font-weight: 600;
            ">
            公司获得多项资质认证
          </div>
          <!-- transversePlate verticalList -->
          <div style="display: flex; flex-wrap: wrap; margin-top: 15px">
            <div v-for="item in transversePlate" :key="item.articleId" style="width: 33%">
              <div v-html="item.content" @click="addImg(item)" style="cursor: pointer;"></div>
              <el-dialog :visible.sync="dialogTableVisible" @close="handleDialogClose">
                <el-image style="width: 100%; height: 100%" :src="sincerityImage">
                </el-image>
              </el-dialog>
            </div>
            <div v-for="item in verticalList" :key="item.articleId" style="width: 20%">
              <!-- <el-image
                :src="item.showImage"
                alt=""
                style="width: auto; height: auto"
                :preview-src-list="img3"
              /> -->
              <div v-html="item.content" @click="addImg(item)" style="cursor: pointer;"></div>
              <!-- 对话框 -->
              <el-dialog :visible.sync="dialogTableVisible">
                <el-image style="width: 100%; height: 100%" :src="monographImage">
                </el-image>
              </el-dialog>
            </div>
          </div>


          <div style="
              text-align: center;
              margin-top: 50px;
              font-size: 25px;
              color: #d3bc93;
              font-weight: 600;
            ">
            公司注重自主研发并取得多项软件著作权
          </div>
          <div style="
              display: flex;
              flex-wrap: wrap;
              margin-top: 15px;
              margin-bottom: 30px;
            ">
            <div v-for="item in pcprogremtwo" :key="item.articleId" style="width: 20%">
              <!-- <el-image
                :src="item.showImage"
                alt=""
                style="width: auto; height: auto"
                :preview-src-list="img3"
              /> -->
              <div v-html="item.content" @click="addImg(item)" style="cursor: pointer;"></div>
              <!-- 对话框 -->
              <el-dialog :visible.sync="dialogTableVisible">
                <el-image style="width: 100%; height: 100%" :src="monographImage">
                </el-image>
              </el-dialog>
            </div>
          </div>
          <div style="
              text-align: center;
              margin-top: 50px;
              font-size: 25px;
              color: #d3bc93;
              font-weight: 600;
            ">
            公司取得多项发明专利
          </div>
          <div style="
              display: flex;
              flex-wrap: wrap;
              margin-top: 15px;
              margin-bottom: 30px;
            ">
            <div v-for="item in pcprogremthree" :key="item.articleId" style="width: 20%">
              <div v-html="item.content" @click="addImg(item)" style="cursor: pointer;"></div>
              <!-- 对话框 -->
              <el-dialog :visible.sync="dialogTableVisible">
                <el-image style="width: 100%; height: 100%" :src="patentImage">
                </el-image>
              </el-dialog>
              <!-- <el-image
               :src="item.showImage"
               alt=""
               style="width: auto; height: auto"
               :preview-src-list="patentImage"
             /> -->
            </div>
            <!-- <img v-image-preview :src="tp" /> -->
          </div>
        </div>
      </div>
    </div>
    <div id="mobiel">
      <div class="mobielhead">
        <div style="line-height: 2rem; width: 85%; margin: auto">
          <img src="../../assets/移动端公司荣誉/风雨兼程 一路向前.png" alt="" style="width: 400px" />
        </div>
      </div>
      <div class="mobiel-contain">
        <div style="margin-top: -0.5rem; height: 1rem">
          <div style="
              width: 85%;
              line-height: 1rem;
              margin: auto;
              background-color: #fff;
              text-align: center;
              border-radius: 25px;
            ">
            <img src="../../assets/移动端公司荣誉/组 5.png" alt="" style="width: 295px" />
          </div>
        </div>
        <div style="width: 85%; margin: auto; margin: 20px auto">
          <div style="">
            <div v-for="(item, index) in pcprogrem" :key="index" style="margin-top: 20px">
              <img :src="item" style="width: 100%" />
              <div style="
                  text-align: center;
                  font-size: 26px;
                  color: #666666;
                  margin-top: 10px;
                ">
                {{ item.val }}
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 150px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { postAction } from "@/api/manage";

export default {
  data() {
    return {
      loading: null,
      dialogTableVisible: false,
      tp: "",
      tpjq: [],
      tp1: "",
      tpjq1: [],
      tp2: "",
      tpjq2: [],
      sincerityImage: [],//诚信认证
      monographImage: [],//软件著作权
      patentImage: [], //专利
      currentPage: 1,
      pageSize: 20,
      pcprogremone: [],
      pcprogremtwo: [],
      pcprogremthree: [],
      verticalList:[],
      transversePlate:[],
      imageurl: null,
      pcprogrem: [],
      img3: [],
      img4: [],
      img2: [],
    };
  },
  methods: {
    addImg(item) {
      this.loading = this.$loading({
        lock: true,
        text: '加载图片中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)',
      });
      this.dialogTableVisible = true
      this.patentImage = item.showImage
      this.monographImage = item.showImage
      this.sincerityImage = item.showImage
      console.log(item, 'item')
    },
    async getlist() {
      await postAction(
        "/api/article/paginate",
        {
          categoryId: 19,
          pageNumber: this.currentPage,
          pageSize: this.pageSize,
        },
        { "Content-Type": "application/x-www-form-urlencoded" },
      ).then((res) => {
        this.pcprogremone = res.page.list;
        res.page.list.forEach((item) => {
          this.tp1 = item.content
          let start = this.tp1.indexOf('img src="')
          let end = this.tp1.indexOf('</figure>');
          this.tpjq1 = "http://192.168.0.21:8050" + this.tp1.substring(start + 9, end - 3)
          console.log(this.tpjq1, 'tpjq1')
          this.sincerityImage.push(this.tpjq1)
          // this.img2.push(item.showImage);
        });
        console.log(this.sincerityImage, '123213123')
      });
      await postAction(
        "/api/article/paginate",
        {
          categoryId: 20,
          pageNumber: this.currentPage,
          pageSize: this.pageSize,
        },
        { "Content-Type": "application/x-www-form-urlencoded" },
      ).then((res) => {
        this.pcprogremtwo = res.page.list;
      });
      await postAction(
        "/api/article/paginate",
        {
          categoryId: 23,
          pageNumber: this.currentPage,
          pageSize: this.pageSize,
        },
        { "Content-Type": "application/x-www-form-urlencoded" },
      ).then((res) => {
        this.pcprogremthree = res.page.list;
      });

      await postAction(
        "/api/article/paginate",
        {
          categoryId: 24,
          pageNumber: this.currentPage,
          pageSize: this.pageSize,
        },
        { "Content-Type": "application/x-www-form-urlencoded" },
      ).then((res) => {
        this.transversePlate = res.page.list;
      });
      await postAction(
        "/api/article/paginate",
        {
          categoryId: 25,
          pageNumber: this.currentPage,
          pageSize: this.pageSize,
        },
        { "Content-Type": "application/x-www-form-urlencoded" },
      ).then((res) => {
        this.verticalList = res.page.list;
      });
    },
    handleDialogClose() {
      this.loading.close();
    },
  },
  mounted() {
    this.getlist();
    if (process.env.NODE_ENV === "production") {
      this.imageurl = "http://127.0.0.1:8080/";
    }
    if (process.env.NODE_ENV === "development") {
      this.imageurl = "192.168.0.11:8080/";
    }
  },
  destroyed() {
  },
};
</script>
<style lang = "less" scoped>
/deep/ .el-dialog__headerbtn {
  font-size: 0.15rem !important;
}

/deep/ .el-dialog {
  position: relative;
  margin: 0 auto;
  background: #FFF;
  border-radius: 0.02667rem;
  // -webkit-box-shadow: 0 0.01333rem 0.04rem rgb(0 0 0 / 30%);
  // box-shadow: 0 0.01333rem 0.04rem rgb(0 0 0 / 30%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 50%;
  margin-top: 10px !important;
}

/deep/ .el-dialog__body {
  padding: 0.2rem 0.2rem !important;
}

#pc {
  display: none;
}

#mobiel {
  display: none;
}

@media screen and (max-width: 768px) {
  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  #pc {
    display: block;
  }

  #mobiel {
    display: none;
  }
}

.mobiel-contain {
  width: 100%;
  background-color: #e9e9e9;
  padding: 5px;
}

.mobielhead {
  background-image: url('../../assets/招贤纳士/图层 10.png');
  height: 2rem;
  text-align: left;
}

.el-breadcrumb {
  font-size: 0.05rem;
  line-height: 0.35rem;
  background-color: #fff;
  height: 0.35rem;
  padding-left: 2.8rem;
}

.pc-top {
  background-image: url('../../assets/招贤纳士/图层 14.png');
  width: 100%;
  height: 1.5rem;
  z-index: 2;
}
</style>

<style></style>