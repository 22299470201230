<template>
  <div>
    <div id="pc">
      <div class="headpng">
        <div style="line-height: 1.5rem">
          <img src="../../assets/联系我们-切图/组 3.png" alt="" />
        </div>
      </div>
      <div class="contain">
        <!-- <menutable></menutable> -->
        <div class="breadmenu">
          <el-breadcrumb separator-class="el-icon-arrow-right" style="padding-left: 2.8rem">
            <el-breadcrumb-item style="font-size: 0.12rem;" :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item style="font-size: 0.1rem;">联系我们</el-breadcrumb-item>
            <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item>
      <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
          </el-breadcrumb>
        </div>

        <div style="border-top: 1px solid #e0e0e0">
          <div style="margin: 0px auto;padding: 50px 0;width: 1140px">
            <!--<div style="width: 1140px; height: 600px; overflow: hidden">-->
              <!--<iframe src="//player.bilibili.com/player.html?aid=482120406&bvid=BV1HT411U7qk&cid=1012032826&page=1"-->
              <!--  scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"-->
              <!--  style="width: 1140px; height: 610px">-->
              <!--</iframe>-->
            <!--</div>-->
            <div class="contactup" style="margin-top: 3px">
              <div class="topleft">
                <div style="margin: 0.4rem auto; text-align: center">
                  <div style="">
                    <img src="../../assets/联系我们-切图/电话咨询-电话.png" alt="" />
                  </div>
                  <div class="text" style="margin: 0.03rem 0">联系电话</div>
                  <div class="text">0311-8783 8366</div>
                </div>
              </div>
              <div class="topright">
                <div style="margin: 0.4rem auto; text-align: center">
                  <div>
                    <img src="../../assets/联系我们-切图/商务合作.png" alt="" />
                  </div>
                  <div class="text" style="margin: 0.03rem 0">联系手机</div>
                  <div class="text">18631166858</div>
                </div>
              </div>
            </div>
            <div class="contactdown">
              公司地址: 河北省石家庄市裕华区裕华东路133号方北大厦A座
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="mobiel">
      <div class="mobielhead">
        <div style="line-height: 2rem">
          <img src="../../assets/app联系我们切图/app联系我们.png" alt="" style="width: 3rem" />
        </div>
      </div>
      <div style="width: 85%; margin: auto">
        <div style="width: 100%; height: 200px; overflow: hidden">
          <iframe src="//player.bilibili.com/player.html?aid=482120406&bvid=BV1HT411U7qk&cid=1012032826&page=1"
            scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"
            style="width: 100%; height: 200px">
          </iframe>
        </div>
        <div style="margin: 50px auto; width: 100%; height: 500px">
          <div class="mobiel-contactup">
            <div class="mobiel-topleft">
              <div style="margin: 0.4rem auto; text-align: center">
                <div style="">
                  <img src="../../assets/app联系我们切图/电话咨询-电话.png" alt="" />
                </div>
                <div class="mobiel-text" style="margin: 0.03rem 0">
                  联系电话
                </div>
                <div class="text">0311-8783 8366</div>
              </div>
            </div>
            <div class="mobiel-topright">
              <div style="margin: 0.4rem auto; text-align: center">
                <div>
                  <img src="../../assets/app联系我们切图/商务合作.png" alt="" />
                </div>
                <div class="mobiel-text" style="margin: 0.03rem 0">
                  联系手机
                </div>
                <div class="mobiel-text">18631166858</div>
              </div>
            </div>
          </div>
          <div class="mobiel-contactdown">
            公司地址: 河北省石家庄市裕华区裕华东路133号方北大厦A座
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserInfo, setUserInfo, getCurrentCompany } from "@/utils/storage";
import { postAction } from "@/api/manage";
import menutable from "@/components/menu/menutable";
export default {
  components: { menutable },
  data() {
    return {
      userInfo: {
        setting: [],
      },
      bindData: "",
      bannerList: [
        {
          url: require("../../assets/website/shou1.jpg"),
          // url:"../../assets/website/shou1.jpg"
        },
        {
          url: require("../../assets/website/shou2.jpg"),
          // url:"../../assets/website/shou2.jpg"
        },
      ],
    };
  },
  created() { },
  activated() { },
  methods: {},
  mounted() { },
  destroyed() { },
};
</script>
<style lang="less" scoped>
#pc {
  display: none;
}

#mobiel {
  display: none;
}

@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: block;
    background-image: none;
  }

  #mobiel {
    display: none;
  }
}

#mobiel {
  margin-top: -0.15rem;
}

.el-breadcrumb {
  font-size: 0.05rem;
  line-height: 0.35rem;
  height: 0.35rem;
}

.mobielhead {
  background-image: url(../../assets/app联系我们切图/图层\ 2.png);
  height: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.headpng {
  background-image: url(../../assets/联系我们-切图/图层\ 14.png);
  text-align: center;
  height: 1.5rem;
  vertical-align: middle;
}

.mobiel-text {
  font-size: 5px;
  color: #fff;
}

.text {
  // font-size: 9px;
  // color: #fff;
}

.mobiel-topleft {
  background-image: url(../../assets/app联系我们切图/组\ 9.png);
  width: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 0.019rem;
}

.topleft {
  background-image: url(../../assets/联系我们-切图/矩形\ 21.png);
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0.019rem;
}

.mobiel-topright {
  background-image: url(../../assets/联系我们-切图/矩形\ 21\ 拷贝.png);
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.topright {
  background-image: url(../../assets/联系我们-切图/矩形\ 21\ 拷贝.png);
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.mobiel-contactup {
  width: 100%;
  height: 100px;
  display: inline-flex;
  margin-bottom: 0.01rem;
}

.contactup {
  width: 100%;
  height: 100px;
  display: inline-flex;
  margin-bottom: 0.01rem;
  font-size: 0.17333rem;
  color: #fdf4e6;
}

.mobiel-contactdown {
  background-image: url(../../assets/app联系我们切图/组\ 10.png);
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 100px;
  font-size: 10px;
  color: #fff;
}

.contactdown {
  background-image: url(../../assets/联系我们-切图/矩形\ 21\ 拷贝\ 3.png);
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  line-height: 100px;
  font-size: 11px;
  color: #fdf4e6;
  
}

.contain {
  width: 100%;
  // background-color: #fff;
  height: auto;
  background: url("~@/assets/联系我们-切图/img01.png") no-repeat 0/100%;
  .breadmenu{
    ::v-deep .el-breadcrumb__inner.is-link{
      color: #d9bb95;
    }
    ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner,{
      color: #d9bb95;
    }
  }
}

#pc1 {
  display: none;
}

#mobiel1 {
  display: none;
}

@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc1 {
    display: none;
  }

  #mobiel1 {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc1 {
    display: block;
  }

  #mobiel1 {
    display: none;
  }
}
</style>