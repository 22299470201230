<template>
    <van-popup v-model="pop_visible" position="bottom" :style="{ height: '30%' }">
        <van-picker
        :title="title"
        show-toolbar
        :columns="columns"
        :default-index="columns.length >10 ? 9 : 0"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
        />
    </van-popup>
</template>

<script>

export default {
    name:'Picker',
    data() {
        return {
            columns:[],
            title:'',
            pop_visible:false,
            num:''
        };
    },
    methods: {
        show(data){
            this.pop_visible = true
            this.columns = data
        },
        onConfirm(value) { //完成时
            this.pop_visible = false
            this.$emit('getchang', value)
        },
        onChange(picker, value) {//上下滑动
            this.pop_visible = false
            this.$emit('getchang', value)
        },
        onCancel() {
            this.pop_visible = false
        },
    },
}
</script>

<style>

</style>