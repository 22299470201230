<template>
    <div>
        <div class="detailContainer">
            <div class="dataWrapper">
                <div class="data-content">
                    <div class="rate">
                        <div class="value">1.02</div>
                        <div class="title">昨日收益(元)</div>
                    </div>
                    <div class="date">
                        <div class="value">1,700.02</div>
                        <div class="title">持有总资产(元)</div>
                    </div>
                    <div class="date">
                        <div class="value">10.02</div>
                        <div class="title">累计收益(元)</div>
                    </div>
                </div>

            </div>

            <div>
                <div class="detailWrapper" @click="transDetail">
                    <div class="item-left">
                        <p>固定存款产品1号</p>
                        <p>年利率 <span style="color:#FD5817">4.08%</span></p>
                    </div>
                    <div style="border-right:1px solid #ddd;height:35px;margin-top:4.8px;"></div>
                    <div class="item-right">
                        <p>1,000.00</p>
                        <p>2020-12-31 到期</p>
                    </div>
                </div>
            </div>

            <div>
                <div class="detailWrapper" @click="transDetail">
                    <div class="item-left">
                        <p>固定存款产品1号</p>
                        <p>年利率 <span style="color:#FD5817">4.08%</span></p>
                    </div>
                    <div style="border-right:1px solid #ddd;height:35px;margin-top:4.8px;"></div>
                    <div class="item-right">
                        <p>1,000.00</p>
                        <p>2020-12-31 到期</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="btnContainer">
            <div class="buyBtn btn" @click="transHistory">交易记录</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HoldDetail',
        description: '持有资产z',
        data() {
            return {
                detail: {},
            }
        },

        created() {
            this.detail = this.$route.params
        },

        methods: {
            transDetail(){
                this.$router.push({
                    path: '/holdingassets/TransDetail',
                });
            },
            transHistory(){
                this.$router.push({
                    path: '/holdingassets/TransHistory',
                });
            }
        },
    }
</script>

<style lang="less" scoped>
    .decIcon {
        width: 24px;
        height: 24px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .detailContainer {
        padding: 12px 8px;
        .dataWrapper {
            padding: 24px 48px 24px 32px;
            background: linear-gradient(0deg, #F16101 0%, #FC991A 100%);
            border-radius: 4px;

            .data-content, .dec-content {
                display: flex;
                justify-content: space-between;
            }

            .data-content {
                .rate, .date {
                    color: #FFFFFF;
                    text-align: center;
                }
                .rate .value, .date .value {
                    font-size: 24px;
                    font-weight: 700;
                }
                .rate .title, .date .title {
                    font-size: 12px;
                }
            }

            .dec-content {
                margin-top: 16px;
                .dec-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .title {
                        margin-top: 4px;
                        font-size: 12px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .detailWrapper {
            margin-top: 12px;
            padding: 14.4px 12px;
            background: #FFFFFF;
            border-radius: 4px;
            display: flex;
                >div{
                    justify-content: space-between;
                }
                .item-left{
                    width:49%;
                    height:40px;
                    text-align:left;
                    //border-right:1px solid #ddd;
                }
                .item-right{
                    width:50%;
                    text-align:right;
                }
                .item-left, .item-right{
                    p:nth-child(1){
                        margin-bottom:4.8px;
                        font-size:14.4px;
                    }
                    p:nth-child(2){
                        margin-bottom:0;
                        font-size:11.2px;
                        color:#959595;
                    }
                }
        }

        .introduceWrapper {
            margin-top: 12px;
            margin-bottom: 48px;
            padding: 16px 12px;
            background: #FFFFFF;
            border-radius: 4px;
            .introduce-item {
                a {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    .title {
                        font-size: 14.4px;
                        color: #000000;
                    }
                    i {
                        font-size: 12.8px;
                        color: #A5A5A5;
                    }
                }
            }
            .ant-divider {
                margin: 12px 0;
            }
        }
    }

    .btnContainer {
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        .btn {
            width: 100%;
            padding: 12px 0;
            text-align: center;
            color: #FFFFFF;
        }
        .redeemBtn {
            background: #FF7200;
        }
        .buyBtn {
            background: #FFBA00;
        }
    }
</style>