<template>
    <a-layout>
            <global-header></global-header>
            <page-content></page-content>
            <global-footer></global-footer>
    </a-layout>
</template>

<script>
import GlobalHeader from '../layouts/GlobalHeader'
import PageContent from './PageContent'
import GlobalFooter from '../layouts/GlobalFooter'
export default {
    components:{
        GlobalHeader,
        PageContent,
        GlobalFooter
    }
}
</script>

<style lang="less">

      .ant-form-item {
          display: flex;
          margin-bottom: 0px;
          margin-right: 0;

          .ant-form-item-control-wrapper {
              flex: 1 1;
              display: inline-block;
              vertical-align: middle;
          }

          > .ant-form-item-label {
            /*line-height: 32px;*/
            /*padding-right: 8px;*/
            /*width: auto;*/
          }
          .ant-form-item-control {
              height: 32px;
              line-height: 32px;
          }
      }
    
</style>